import { Stripe } from '@stripe/stripe-js'
import { AxiosError } from 'axios'
import { Dayjs } from 'dayjs'
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useAuthContext } from '../provider/authProvider'
import { API, Callbacks, Dispatchable, Emojis, LOCAL_STORAGE_KEYS } from '../utils'
import { logoutFromOkta } from '../utils/helper'
import { useLocalStorage } from './useLocalStorage'

const dayjs = require('dayjs')

type CreateStripeAccountBody = {
  token: string
}

type CreateProfileNameResponse = {
  email: string
  name: string
}

type ContactUsType = {
  firstName: string
  lastName: string
  message: string
  email: string
}

export type Giver = {
  amount: string
  createdAt: string
  movementTypeId: string
  donator: {
    email: string
    firstName: string
    lastName: string
    emoji: Emojis
  } | null
}

export type Transactions = {
  amount: string
  id: string
  createdAt: string
  movementTypeId: string
  order: {
    contentfulProductName: string
    id: string
    contentfulMerchantName: string
  }
}

export enum FACTOR_TYPE {
  SMS = 'sms',
}

export enum FACTOR_STATUS {
  NOT_SETUP = 'NOT_SETUP',
  PENDING_ACTIVATION = 'PENDING_ACTIVATION',
  ENROLLED = 'ENROLLED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  EXPIRED = 'EXPIRED',
}

export interface Factor {
  created: string
  factorType: FACTOR_TYPE
  id: string
  lastUpdated: string
  profile: { phoneNumber: string } //'+1617123123'
  provider: string
  status: FACTOR_STATUS
  vendorName: string
  _links: Array<Record<string, { href: string }>>
}

export type UserData = {
  email: string
  firstName: string
  lastName: string
  ageRange: string
  profileName: string
  oktaId: string
  id: string
  telephone?: string
  referralSource?: string
  stripeAccountId: string
  active: boolean
  createdAt: string
  balance: number
  role: {
    id: string
    name: string
  }
  biography: string
  location: string
  displayName: string
  profilePictureURL: string
}

export type GetGiversResponse = {
  movements: Giver[]
  total: number
}

export type GetTransactionsResponse = {
  movements: Transactions[]
  total: number
}

export type DonatorResponse = {
  firstName?: string
  lastName?: string
  email?: string
  emoji?: string
}

const ITEMS_PER_PAGE = 10

type UseUserType = {
  id: string
  firstName: string
  lastName: string
  email: string
  ageRange: string
  password: string
  active: boolean
  createdAt: string
  setId: Dispatchable<string>
  setFirstName: Dispatchable<string>
  setLastName: Dispatchable<string>
  setEmail: Dispatchable<string>
  setAgeRange: Dispatchable<string>
  setTelephone: Dispatchable<string>
  setPassword: Dispatchable<string>
  setActive: Dispatchable<boolean>
  setCreatedAt: Dispatchable<string>
  createUser: (callbacks?: Callbacks) => void
  SSN: string
  setSSN: Dispatchable<string>
  date: Dayjs | null
  setDate: Dispatchable<Dayjs | null>
  createStripeAccount: (stripe: Stripe | null, callbacks?: Callbacks) => Promise<void>
  resetPassword: (callbacks?: Callbacks) => Promise<unknown>
  createProfileName: (callbacks?: Callbacks) => void
  profileName: string
  resendCode: (callbacks?: Callbacks) => void
  saveProfile: (
    profile: Pick<UserData, 'firstName' | 'lastName' | 'email' | 'ageRange'>,
    callbacks?: Callbacks
  ) => Promise<unknown>
  disableAccount: (callbacks?: Callbacks) => Promise<unknown>
  seeMoreGivers: () => void
  recentGivers: Giver[]
  getRecentGivers: (page: number, callbacks?: Callbacks) => Promise<GetGiversResponse | undefined>
  giverPage: number
  canGetMoreGivers: boolean
  setRecentGivers: Dispatchable<Giver[]>
  setTotalRecentGiver: Dispatchable<number>
  seeMoreTransactions: () => void
  recentTransactions: Transactions[]
  getRecentTransactions: (
    page: number,
    callbacks?: Callbacks
  ) => Promise<GetTransactionsResponse | undefined>
  getDonationByTransactionId: (id: string) => Promise<DonatorResponse | undefined>
  transactionPage: number
  canGetMoreTransactions: boolean
  setRecentTransactions: Dispatchable<Transactions[]>
  setTotalRecentTransactions: Dispatchable<number>
  recentTransactionsLoader: boolean
  setRecentTransactionsLoader: Dispatchable<boolean>
  setPathname: Dispatchable<string>
  pathname: string
  recentGiverLoader: boolean
  setRecentGiverLoader: Dispatchable<boolean>
  profileSettingsLoader: boolean
  updateUserData: (response: UserData) => void
  changePassword: (
    oldPassword: string,
    newPassword: string,
    callbacks?: Callbacks
  ) => Promise<unknown>
  contactUs: (contact: ContactUsType, callbacks?: Callbacks) => void
  recoveryAnswer: string
  recoveryQuestion: string
  setRecoveryAnswer: Dispatchable<string>
  setRecoveryQuestion: Dispatchable<string>
  balance: number
  displayName: string
  setDisplayName: Dispatchable<string>
  location: string
  setLocation: Dispatchable<string>
  biography: string
  setBiography: Dispatchable<string>
  profileImageURL: string
  setProfileImageURL: Dispatchable<string>
  getProfile: (callbacks?: Callbacks) => Promise<void>
  activateAccount: (token: string, password: string, callbacks?: Callbacks) => Promise<void>
  telephone: string
  setFactors: Dispatch<SetStateAction<Factor[] | undefined>>
  factors: Factor[] | undefined
}

const UserContext = createContext<UseUserType | undefined>(undefined)

interface UserProviderProps {
  children: ReactNode
}

const UserProvider = ({ children }: UserProviderProps) => {
  const [id, setId] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [ageRange, setAgeRange] = useState('18-34')
  const [telephone, setTelephone] = useState('')
  const [password, setPassword] = useState('')
  const [recoveryQuestion, setRecoveryQuestion] = useState('')
  const [recoveryAnswer, setRecoveryAnswer] = useState('')
  const [profileName, setProfileName] = useState('')
  const [active, setActive] = useState(true)
  const [createdAt, setCreatedAt] = useState('')
  const [SSN, setSSN] = useState('')
  const [date, setDate] = useState<Dayjs | null>(dayjs())
  const [recentGivers, setRecentGivers] = useState<Giver[]>([])
  const [recentTransactions, setRecentTransactions] = useState<Transactions[]>([])
  const [totalRecentTransaction, setTotalRecentTransactions] = useState(0)
  const [totalRecentGiver, setTotalRecentGiver] = useState(0)
  const [transactionPage, setTransactionPage] = useState(1)
  const [giverPage, setGiverPage] = useState(1)
  const [pathname, setPathname] = useState('')
  const [recentGiverLoader, setRecentGiverLoader] = useState(false)
  const [recentTransactionsLoader, setRecentTransactionsLoader] = useState(false)
  const [profileSettingsLoader, setProfileSettingsLoader] = useState(false)
  const [balance, setBalance] = useState(0)
  const [displayName, setDisplayName] = useState('')
  const [location, setLocation] = useState('')
  const [biography, setBiography] = useState('')
  const [profileImageURL, setProfileImageURL] = useState('')
  const [factors, setFactors] = useState<Array<Factor>>()
  const [lsReferralSource] = useLocalStorage(LOCAL_STORAGE_KEYS.REFERRAL_SOURCE, '')

  const { doPost, doGet, doPut, doDelete } = useAuthContext()

  useEffect(() => {
    window._mfq = window._mfq || []
    window._mfq.push(['setVariable', 'email', email])
  }, [email])

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    if (id) {
      window.dataLayer.push({ user_id: id })
    }
  }, [id])

  const createStripeAccount = useCallback(
    async (stripe: Stripe | null, callbacks?: Callbacks) => {
      if (stripe && date) {
        const result = await stripe.createToken('account', {
          business_type: 'individual',
          individual: {
            first_name: firstName,
            last_name: lastName,
            email: email,
            ssn_last_4: SSN,
            dob: {
              day: date.date(),
              month: date.month() + 1,
              year: date.year(),
            },
          },
          tos_shown_and_accepted: true,
        })
        if (result.token != null) {
          doPost<CreateStripeAccountBody>(
            API.CREATE_STRIPE_ACCOUNT,
            {
              token: result.token.id,
            },
            callbacks
          )
        }
      }
    },
    [firstName, lastName, email, date, SSN, doPost]
  )

  const createProfileName = useCallback(
    (callbacks?: Callbacks) =>
      doPost<CreateProfileNameResponse>(API.CREATE_PROFILE_NAME, { email }, callbacks).then(
        response => !!response && setProfileName(response.name)
      ),
    [email, doPost]
  )

  const createUser = useCallback(
    async (callbacks?: Callbacks) =>
      doPost(
        API.SIGN_UP,
        {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          email,
          ageRange,
          telephone,
          recoveryQuestion,
          recoveryAnswer,
          referralSource: lsReferralSource,
        },
        callbacks
      ),
    [
      firstName,
      lastName,
      email,
      ageRange,
      recoveryAnswer,
      recoveryQuestion,
      doPost,
      telephone,
      lsReferralSource,
    ]
  )

  const resetPassword = useCallback(
    (callbacks?: Callbacks) => doPost(API.FORGOT_PASSWORD, { email }, callbacks),
    [email, doPost]
  )

  const getRecentGivers = useCallback(
    (page: number, callbacks?: Callbacks) =>
      doGet<GetGiversResponse>(API.GET_RECENT_GIVERS(page), callbacks),
    [doGet]
  )

  const getRecentTransactions = useCallback(
    (page: number, callbacks?: Callbacks) =>
      doGet<GetTransactionsResponse>(API.GET_RECENT_TRANSACTIONS(page), callbacks),
    [doGet]
  )

  const getDonationByTransactionId = useCallback(
    (id: string, callbacks?: Callbacks) =>
      doGet<DonatorResponse>(API.GET_DONATIONS_BY_TRANSACTION_ID(id), callbacks),
    [doGet]
  )

  const resendCode = useCallback(
    (callbacks?: Callbacks) => doPost(API.RESEND_CODE, undefined, callbacks),
    [doPost]
  )

  const updateUserData = (response: UserData) => {
    setEmail(response.email)
    setFirstName(response.firstName)
    setLastName(response.lastName)
    setAgeRange(response.ageRange)
    setTelephone(response.telephone || '')
    setProfileName(response.profileName)
    setActive(response.active)
    setCreatedAt(response.createdAt)
    setBalance(response.balance)
    setDisplayName(response.displayName || '')
    setLocation(response.location || '')
    setBiography(response.biography || '')
    setProfileImageURL(response.profilePictureURL || '')
    setId(response.id)
  }

  const getProfile = useCallback(
    (callbacks?: Callbacks) =>
      doGet<UserData>(API.GET_USER_PROFILE, callbacks).then(response => {
        if (response) updateUserData(response)
      }),
    [doGet]
  )

  const saveProfile = useCallback(
    async (
      yourInfo: Pick<UserData, 'firstName' | 'lastName' | 'email' | 'ageRange'>,
      callbacks?: Callbacks
    ) => {
      setProfileSettingsLoader(true)
      doPut<UserData>(API.UPDATE_USER_PROFILE, yourInfo, callbacks)
        .then(response => {
          if (!!response) {
            updateUserData(response)
          }
        })
        .finally(() => setProfileSettingsLoader(false))
    },
    [doPut]
  )

  const disableAccount = useCallback(
    async (callbacks?: Callbacks) =>
      doDelete<UserData>(API.DELETE_USER_PROFILE_NEXT, callbacks).then(response => {
        !!response && setActive(response.active)
        logoutFromOkta(window.location.origin)
      }),
    [doDelete]
  )

  const canGetMoreGivers = useMemo(() => {
    return totalRecentGiver - giverPage * ITEMS_PER_PAGE >= 1
  }, [totalRecentGiver, giverPage])

  const seeMoreGivers = useCallback(() => {
    if (canGetMoreGivers) {
      getRecentGivers(giverPage + 1)
        .then(response => {
          if (!!response) {
            setRecentGivers(actualGivers =>
              actualGivers.concat(
                response.movements.filter(
                  donator =>
                    !actualGivers.some(actualGiver => actualGiver.createdAt === donator.createdAt)
                )
              )
            )
            setGiverPage(giverPage + 1)
            setTotalRecentGiver(response.total)
          }
        })
        .catch((err: AxiosError) => {
          console.log(err)
        })
        .finally(() => {
          setRecentGiverLoader(false)
        })
    }
  }, [setGiverPage, giverPage, canGetMoreGivers, getRecentGivers])

  const canGetMoreTransactions = useMemo(() => {
    return totalRecentTransaction - transactionPage * ITEMS_PER_PAGE >= 1
  }, [totalRecentTransaction, transactionPage])

  const seeMoreTransactions = useCallback(() => {
    if (canGetMoreTransactions) {
      getRecentTransactions(transactionPage + 1)
        .then(response => {
          if (!!response) {
            setRecentTransactions(actualTransactions =>
              actualTransactions.concat(
                response.movements.filter(
                  transaction =>
                    !actualTransactions.some(
                      actualTransaction => actualTransaction.id === transaction.id
                    )
                )
              )
            )
            setTransactionPage(transactionPage + 1)
            setTotalRecentTransactions(response.total)
          }
        })
        .catch((err: AxiosError) => {
          console.log(err)
        })
        .finally(() => {
          setRecentTransactionsLoader(false)
        })
    }
  }, [setTransactionPage, transactionPage, canGetMoreTransactions, getRecentTransactions])

  const changePassword = useCallback(
    (oldPassword: string, newPassword: string, callbacks?: Callbacks) =>
      doPut(API.CHANGE_PASSWORD, { oldPassword, newPassword }, callbacks),
    [doPut]
  )

  const contactUs = useCallback(
    (contact: ContactUsType, callbacks?: Callbacks) =>
      doPost(API.CONTACT_US, contact, callbacks).then(_response => {
        if (callbacks?.success) callbacks.success()
      }),
    [doPost]
  )

  const activateAccount = useCallback(
    (token: string, password: string, callbacks?: Callbacks) =>
      doPost(API.USER_ACCOUNT_ACTIVATION, { token, password })
        .then(_response => {
          if (callbacks?.success) callbacks.success()
        })
        .catch(_error => {
          if (callbacks?.error) callbacks.error(String(_error))
        }),
    [doPost]
  )

  const value = {
    id,
    setId,
    email,
    firstName,
    lastName,
    ageRange,
    password,
    active,
    createdAt,
    setEmail,
    setFirstName,
    setLastName,
    setAgeRange,
    setPassword,
    setActive,
    setCreatedAt,
    createUser,
    date,
    setDate,
    SSN,
    setSSN,
    createStripeAccount,
    resetPassword,
    createProfileName,
    profileName,
    resendCode,
    saveProfile,
    disableAccount,
    recentGivers,
    seeMoreGivers,
    getRecentGivers,
    giverPage,
    canGetMoreGivers,
    setRecentGivers,
    setTotalRecentGiver,
    setPathname,
    pathname,
    recentGiverLoader,
    setRecentGiverLoader,
    profileSettingsLoader,
    changePassword,
    updateUserData,
    contactUs,
    recoveryQuestion,
    recoveryAnswer,
    setRecoveryAnswer,
    setRecoveryQuestion,
    balance,
    displayName,
    setDisplayName,
    location,
    setLocation,
    biography,
    setBiography,
    profileImageURL,
    setProfileImageURL,
    getProfile,
    seeMoreTransactions,
    recentTransactions,
    getRecentTransactions,
    getDonationByTransactionId,
    transactionPage,
    canGetMoreTransactions,
    setRecentTransactions,
    setTotalRecentTransactions,
    recentTransactionsLoader,
    setRecentTransactionsLoader,
    activateAccount,
    telephone,
    setTelephone,
    factors,
    setFactors,
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export default UserProvider

export const useUser = () => {
  const ctx = useContext(UserContext)
  if (!ctx) {
    throw new Error('You are using User out of context.')
  }
  return ctx
}
