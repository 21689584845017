/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @next/next/inline-script-id */
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { SessionProvider as NextProvider } from 'next-auth/react'
import { DefaultSeo } from 'next-seo'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { IntlProvider } from 'react-intl'
import { DEFAULT_REFERRAL_SOURCE, PAGE_TYPES, PageProps } from 'src/utils'
import { ThemeProvider as StyledThemeProvider, createGlobalStyle } from 'styled-components'
import i18n from '../i18n'
import SEO from '../next-seo.config'
import {
  AdminProvider,
  BioProvider,
  ContentDashboardProvider,
  ContentGiverProvider,
  ContentMarketplaceProductsProvider,
  MemberProvider,
  ProfileFormProvider,
} from '../src/hooks'
import { StripeProvider } from '../src/provider'
import '../styles/globals.css'
import { useTheme } from '../theme/theme'

import { datadogRum } from '@datadog/browser-rum'
import { Session } from 'next-auth'
import dynamic from 'next/dynamic'
import { ParsedUrlQuery, parse } from 'querystring'
import { useEffect } from 'react'
import Layout from '~/components/ui/layout'
import { ContentBannerProvider } from '~/hooks/useContentBanner'
import { ContentFAQProvider } from '~/hooks/useContentFAQ'
import { IPage } from '~/interfaces/page'
import { LOCAL_STORAGE_KEYS } from '~/utils'
import { useLocalStorage } from '../src/hooks'
import ContentRegistrationProvider from '../src/hooks/useContentRegistration'
import { MerchantProvider } from '../src/hooks/useMerchants'
import { StaticPagesProvider } from '../src/hooks/useStaticPages'
import UserProvider from '../src/hooks/useUser'
import AuthProvider from '../src/provider/authProvider'

if (process.env.NODE_ENV === 'production') {
  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DD_RUM_APPLICATION_ID as string,
    clientToken: process.env.NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN as string,
    site: process.env.NEXT_PUBLIC_DD_RUM_SITE as string,
    service: process.env.NEXT_PUBLIC_DD_RUM_SERVICE as string,
    env: process.env.NEXT_PUBLIC_DD_RUM_ENVIRONMENT as string,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.NEXT_PUBLIC_DD_RUM_VERSION as string,
    sampleRate: 100,
    trackInteractions: false,
    defaultPrivacyLevel: 'mask-user-input',
  })
}

const GlobalStyle = createGlobalStyle`
    @font-face {
    font-family: 'LyonDisplay-Regular';
    src: url('/assets/fonts/LyonDisplay-Regular-Web.woff') format('woff');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: 'Effra-Regular';
    src: url('/assets/fonts/Effra_W_Rg.woff2') format('woff2');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }
`

const ResolutionProvider = dynamic(() => import('../src/hooks/useResolution'), {
  ssr: false,
})

interface MyAppProps extends AppProps {
  pageProps: PageProps<{}> & {
    session: Session
    page: IPage
    contentType?: string
  }
}

function _app({ Component, pageProps }: MyAppProps) {
  const theme = useTheme()
  const { asPath } = useRouter()
  const queryParams = parse(asPath.split('?')[1])
  const { contentType, page: { primaryTopic = '' } = {} } = pageProps
  const [externalReferralSource, setExternalReferralSource] = useLocalStorage(
    LOCAL_STORAGE_KEYS.REFERRAL_SOURCE,
    ''
  )

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    if (contentType && Object.values(PAGE_TYPES).includes(contentType)) {
      window.dataLayer.push({ content_group: primaryTopic })
    } else {
      window.dataLayer.push({ content_group: 'other' })
    }
  }, [asPath, contentType, primaryTopic])

  const getUtmSource = (query: ParsedUrlQuery): string => {
    const { utm_source } = query
    const referer = document.referrer
    if (typeof utm_source === 'string') return utm_source
    return referer || DEFAULT_REFERRAL_SOURCE
  }

  useEffect(() => {
    const source = getUtmSource(queryParams)
    const { utm_campaign } = queryParams

    const completeReferralSrc = utm_campaign ? `${source}/${utm_campaign}` : source
    if (externalReferralSource && completeReferralSrc === DEFAULT_REFERRAL_SOURCE) return
    setExternalReferralSource(completeReferralSrc)
  }, [externalReferralSource, queryParams])

  return (
    <>
      <DefaultSeo {...SEO} />
      <Script id="google-tag-manager" strategy="afterInteractive" nonce={process.env.nonce}>
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-MS9VGQS');
        `}
      </Script>
      <IntlProvider messages={i18n.en} locale="en" defaultLocale="en">
        <ThemeProvider theme={theme}>
          <NextProvider session={pageProps.session}>
            <AuthProvider>
              <UserProvider>
                <ResolutionProvider>
                  <StripeProvider>
                    <ContentGiverProvider>
                      <ContentDashboardProvider>
                        <ContentRegistrationProvider>
                          <MemberProvider>
                            <BioProvider>
                              <ProfileFormProvider>
                                <AdminProvider>
                                  <ContentMarketplaceProductsProvider>
                                    <StaticPagesProvider>
                                      <MerchantProvider>
                                        <ContentFAQProvider>
                                          <ContentBannerProvider>
                                            <CssBaseline />
                                            <StyledThemeProvider theme={theme}>
                                              <GlobalStyle />
                                              <Layout>
                                                <Component {...pageProps} />
                                              </Layout>
                                            </StyledThemeProvider>
                                          </ContentBannerProvider>
                                        </ContentFAQProvider>
                                      </MerchantProvider>
                                    </StaticPagesProvider>
                                  </ContentMarketplaceProductsProvider>
                                </AdminProvider>
                              </ProfileFormProvider>
                            </BioProvider>
                          </MemberProvider>
                        </ContentRegistrationProvider>
                      </ContentDashboardProvider>
                    </ContentGiverProvider>
                  </StripeProvider>
                </ResolutionProvider>
              </UserProvider>
            </AuthProvider>
          </NextProvider>
        </ThemeProvider>
      </IntlProvider>
    </>
  )
}

export default _app
