import { PaletteOptions } from '@material-ui/core/styles/createPalette'
import { COLORS } from './colors'
import { PaletteType } from '@material-ui/core'

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    borderColor: {
      main: string
      black: string
      secondary: string
      light: string
    }
    passwordmeter: {
      weak: string
      ok: string
      good: string
      strong: string
    }
    inputColor: {
      main: string
      placeholder: string
    }
  }

  interface PaletteOptions {
    borderColor: {
      main: string
      black: string
      secondary: string
      light: string
    }
    passwordmeter: {
      weak: string
      ok: string
      good: string
      strong: string
    }
    inputColor: {
      main: string
      placeholder: string
    }
  }

  interface TypeText {
    invertedPrimary: string
    black: string
    newGray: string
    gray: string
    white: string
    lightGray: string
    grayPrimary: string
    midGray: string
  }
  interface TypeTextOptions {
    invertedPrimary: string
    black: string
    newGray: string
    gray: string
    white: string
    lightGray: string
    grayPrimary: string
  }

  interface TypeBackground {
    gradient: string
    avatar: string
    lightGray: string
    lightOrange: string
    accessibleWhite: string
    orangeGradient: string
    lighterGrey: string
    aboutUs: string
  }

  interface TypeBackgroundOptions {
    gradient: string
    avatar: string
    lightGray: string
    lightOrange: string
    accessibleWhite: string
    orangeGradient: string
    lighterGrey: string
  }
}

export const getThemePalette = (): PaletteOptions => {
  return {
    type: 'light' as PaletteType,
    background: {
      paper: COLORS.white,
      default: COLORS.white,
      gradient:
        'linear-gradient(248.16deg,#FFA552 2.38%, #FFB477 18.82%, #FFBC89 37.36%, #FFBD8D 41.84%, #FA5858 76.08%, #FA5486 98.48%)',
      avatar: COLORS.orange.avatar,
      lightGray: COLORS.gray.bg,
      accessibleWhite: COLORS.gray.accessible,
      orangeGradient: 'linear-gradient(0deg, #FFC9B3 0%, #FFD2C2 100%)',
      lightOrange: COLORS.lightOrange.primary,
      lighterGrey: COLORS.gray.lighter,
      aboutUs: COLORS.lightOrange.aboutUs,
    },
    primary: {
      main: COLORS.orange.primary,
      contrastText: COLORS.white,
    },
    secondary: {
      main: COLORS.white,
      contrastText: COLORS.orange.primary,
    },
    text: {
      primary: COLORS.navy,
      black: COLORS.navy,
      secondary: COLORS.orange.primary,
      invertedPrimary: COLORS.white,
      gray: COLORS.gray.secondary,
      white: COLORS.white,
      lightGray: COLORS.gray.light,
      newGray: COLORS.gray.new,
      grayPrimary: COLORS.gray.primary,
      midGray: COLORS.gray.mid,
    },
    borderColor: {
      main: COLORS.gray.light,
      black: COLORS.gray.primary,
      secondary: COLORS.gray.accessible,
      light: COLORS.gray.lightest,
    },
    passwordmeter: {
      weak: COLORS.orange.darker,
      ok: COLORS.orange.darker,
      good: COLORS.orange.darker,
      strong: COLORS.orange.darker,
    },
    inputColor: {
      main: COLORS.navy,
      placeholder: COLORS.gray.primary,
    },
    error: {
      main: COLORS.orange.error,
    },
  }
}
