import login from './login.json'
import signup from './signup.json'
import footer from './footer.json'
import header from './header.json'
import giver from './giver.json'
import profile from './profile.json'
import account from './account.json'
import error from './error.json'
import marketplace from './marketplace.json'
import contactUs from './contactUs.json'
import admin from './admin.json'
import home from './home.json'
import aboutUs from './aboutUs.json'
import faq from './faq.json'
import blogFooter from './blogFooter.json'
import storybook from './storybook.json'

type I18nMap = Record<string, string>

export const keyify = (prefix: string, imp: I18nMap): I18nMap => {
  const entries: I18nMap = {}
  Object.keys(imp).forEach(k => {
    entries[`${prefix}.${k}`] = imp[k]
  })
  return entries
}

const en = {
  ...keyify('login', login),
  ...keyify('signup', signup),
  ...keyify('footer', footer),
  ...keyify('header', header),
  ...keyify('giver', giver),
  ...keyify('profile', profile),
  ...keyify('account', account),
  ...keyify('error', error),
  ...keyify('marketplace', marketplace),
  ...keyify('contactUs', contactUs),
  ...keyify('admin', admin),
  ...keyify('home', home),
  ...keyify('aboutUs', aboutUs),
  ...keyify('faq', faq),
  ...keyify('blogFooter', blogFooter),
  ...keyify('storybook', storybook),
  unknown: 'unknown',
}

export default en
