import { ReactNode } from 'react'
import Header from '../Header/Header'
import { Footer } from '../Footer/Footer'

export default function Layout({ children }: { children: ReactNode }) {
  return (
    <div className="flex h-screen flex-col justify-between">
      <Header />
      <main className="my-auto">{children}</main>
      <Footer />
    </div>
  )
}
