import {
  ChangeEvent,
  ChangeEventHandler,
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react'
import { UserData } from '.'
import { useUser, useBio, useMember } from '~/hooks'
import { Dispatchable, Callbacks, isBioValid, isLocationValid, isNicknameValid } from '~/utils'

type UseProfileFormType = {
  nickname: string
  location: string
  bio: string
  showFieldsErrors: boolean
  setShowFieldsErrors: Dispatchable<boolean>
  hasFormFieldChanged: boolean
  saving: boolean
  isValid: boolean
  handleProfileFormChange: (
    fieldName: string
  ) => ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined
  updateProfile: (callbacks?: Callbacks) => void
}

const ProfileFormContext = createContext<UseProfileFormType | undefined>(undefined)

interface ProfileForm {
  nickname: string
  bio: string
  location: string
}

interface ProfileFormProps {
  children: ReactNode
}

const initialForm: ProfileForm = {
  nickname: '',
  bio: '',
  location: '',
}

export const ProfileFormProvider = ({ children }: ProfileFormProps) => {
  const {
    firstName,
    lastName,
    biography,
    displayName,
    profileName,
    location: userLocation,
    profileImageURL,
    updateUserData,
  } = useUser()
  const { saveBio } = useBio()
  const { getUserByProfileName } = useMember()
  const [{ nickname, bio, location }, setProfileForm] = useState<ProfileForm>(initialForm)
  const [avatar, setAvatar] = useState(profileImageURL)
  const [uploadedImage, setUploadedImage] = useState(profileImageURL)
  const [showFieldsErrors, setShowFieldsErrors] = useState(false)
  const [saving, setSaving] = useState(false)
  const [hasFormFieldChanged, setHasFormFieldChanged] = useState(false)

  const bioValuesChanged = useMemo(
    () =>
      nickname !== (displayName || `${firstName} ${lastName}`) ||
      location !== userLocation ||
      bio !== biography,
    [nickname, location, bio, displayName, userLocation, biography]
  )

  const isValid = useMemo(
    () => isNicknameValid(nickname) && isBioValid(bio) && isLocationValid(location),
    [nickname, bio, location]
  )

  const handleProfileFormChange =
    (fieldName: string) => (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setHasFormFieldChanged(true)
      const { value } = event.target

      setProfileForm(prev => ({
        ...prev,
        [fieldName]: value,
      }))
    }

  const updateProfile = async (callbacks?: Callbacks) => {
    setSaving(true)
    if (isValid) {
      await saveBio(
        {
          biography: bio,
          displayName: nickname,
          location: location,
          bioUpdated: bioValuesChanged,
          ...(avatar !== uploadedImage ? { image: avatar } : {}),
        },
        {
          success: async (userData?: UserData) => {
            setHasFormFieldChanged(false)
            if (callbacks?.success) callbacks.success()
            if (userData) {
              updateUserData(userData)
              await getUserByProfileName(profileName)
            }
          },
          error: err => {
            if (callbacks?.error) callbacks.error()
          },
          finally: () => {
            setSaving(false)
            if (callbacks?.finally) callbacks.finally()
          },
        }
      )
    } else {
      setSaving(false)
    }
  }

  useEffect(() => {
    setProfileForm({
      nickname: displayName ? displayName : `${firstName} ${lastName}`,
      bio: biography,
      location: userLocation,
    })
  }, [displayName, firstName, lastName, biography, userLocation])

  const value = {
    nickname,
    location,
    bio,
    showFieldsErrors,
    setShowFieldsErrors,
    hasFormFieldChanged,
    saving,
    isValid,
    handleProfileFormChange,
    updateProfile,
  }

  return <ProfileFormContext.Provider value={value}>{children}</ProfileFormContext.Provider>
}

export const useProfileForm = () => {
  const ctx = useContext(ProfileFormContext)
  if (!ctx) {
    throw new Error('You are using ProfileForm out of context.')
  }
  return ctx
}
