import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { Dispatchable } from '../utils'
import { EntryCollection } from 'contentful'
import { client } from '../services/contentfulService'

interface IContentDashboardProviderProps {
  children: ReactNode
}

type ContentDashboardFields = {
  shareProfileText: string
}

interface ContentDashboardType {
  fields: ContentDashboardFields
  assets?: Array<any>
  id?: string
}

type UseContentDashboardType = {
  dashboardContent: ContentDashboardType[] | undefined
  setDashboardContent: Dispatchable<ContentDashboardType[] | undefined>
  getShareProfileText: () => string | undefined
}

const ContentDashboardContext = createContext<UseContentDashboardType | undefined>(undefined)

export const ContentDashboardProvider = ({ children }: IContentDashboardProviderProps) => {
  const [dashboardContent, setDashboardContent] = useState<ContentDashboardType[]>()

  const getDashboardContent = useCallback(() => {
    client.getEntries({ content_type: 'dashboard' }).then((response: EntryCollection<any>) => {
      generateContentDashboardObject(response.items)
    })
  }, [])

  const generateContentDashboardObject = (items: Array<object>) => {
    const contentList: ContentDashboardType[] = []

    items.forEach((element: any) => {
      let newDashboardContent: ContentDashboardType = {
        fields: { shareProfileText: '' },
      }
      newDashboardContent.fields = element.fields

      contentList.push(newDashboardContent)
    })

    setDashboardContent(contentList)
  }

  useEffect(() => {
    if (!dashboardContent) getDashboardContent()
  }, [dashboardContent, getDashboardContent])

  const getShareProfileText = () => {
    if (dashboardContent) {
      const element = dashboardContent.find(item => item.fields.shareProfileText !== undefined)
      return element?.fields.shareProfileText
    }
  }

  const value = {
    dashboardContent,
    setDashboardContent,
    getShareProfileText,
  }

  return (
    <ContentDashboardContext.Provider value={value}>{children}</ContentDashboardContext.Provider>
  )
}

export const useContentDashboard = () => {
  const ctx = useContext(ContentDashboardContext)
  if (!ctx) {
    throw new Error('ContentDashboard out of context')
  }
  return ctx
}
