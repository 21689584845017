import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import { Palette } from '@material-ui/core/styles/createPalette'
import { TypographyOptions } from '@material-ui/core/styles/createTypography'

// Make a Dictionary from GuardianWell Typographies to MUI Typographies
export enum TYPOGRAPHY_VARIANTS {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  PARAGRAPH = 'subtitle1',
  PROMOTIONAL = 'subtitle2',
  LINK = 'button',
  MEDIUM_TEXT = 'body1',
  SMALL_TEXT = 'body2',
  XS_TEXT = 'caption',
  XS_TEXT_2 = 'overline',
}

const breakpoints = createBreakpoints({})

export const getThemeTypographies = ():
  | TypographyOptions
  | ((palette: Palette) => TypographyOptions) => {
  return {
    fontFamily: 'Effra-Regular',
    fontSize: 18,
    [TYPOGRAPHY_VARIANTS.H1]: {
      fontFamily: 'LyonDisplay-Regular',
      fontSize: '6.4rem',
      fontWeight: 500,
      fontStyle: 'normal',
    },
    [TYPOGRAPHY_VARIANTS.H2]: {
      fontSize: '2.6rem',
      letterSpacing: '0.02em',
      lineHeight: '3.2rem',
      fontStyle: 'normal',
      [breakpoints.up('md')]: {
        fontSize: '4rem',
      },
    },
    [TYPOGRAPHY_VARIANTS.H3]: {
      fontSize: '2.4rem',
      letterSpacing: '0.07rem',
      lineHeight: '3.2rem',
      fontWeight: 600,
      fontStyle: 'normal',
      [breakpoints.up('md')]: {
        fontSize: '2.6rem',
      },
    },
    [TYPOGRAPHY_VARIANTS.H4]: {
      fontSize: '2rem',
      lineHeight: '3rem',
      fontWeight: 600,
      fontStyle: 'normal',
      [breakpoints.up('md')]: {
        fontSize: '2.4rem',
        lineHeight: '3.2rem',
      },
    },
    [TYPOGRAPHY_VARIANTS.H5]: {
      fontSize: '1.8rem',
      letterSpacing: '-0.02em',
      lineHeight: '2.6rem',
      fontWeight: 600,
      fontStyle: 'normal',
    },
    [TYPOGRAPHY_VARIANTS.H6]: {
      fontSize: '1.6rem',
      letterSpacing: '-0.01em',
      lineHeight: '2.4rem',
      fontWeight: 600,
      fontStyle: 'normal',
    },
    [TYPOGRAPHY_VARIANTS.LINK]: {
      fontSize: '1.6rem',
      fontWeight: 500,
      lineHeight: '2.4rem',
      [breakpoints.up('md')]: {
        lineHeight: '3.1rem',
      },
    },
    [TYPOGRAPHY_VARIANTS.PROMOTIONAL]: {
      fontSize: '1.4rem',
      lineHeight: '2.4rem',
      fontWeight: 500,
      fontStyle: 'normal',
      [breakpoints.up('md')]: {
        fontSize: '1.6rem',
        lineHeight: '3.2rem',
      },
    },
    [TYPOGRAPHY_VARIANTS.PARAGRAPH]: {
      fontSize: '1.8rem',
      fontWeight: 400,
      [breakpoints.up('md')]: {
        fontSize: '1.8rem',
        lineHeight: '3.1rem',
      },
    },
    [TYPOGRAPHY_VARIANTS.MEDIUM_TEXT]: {
      fontSize: '1.6rem',
      fontWeight: 400,
      lineHeight: '2.4rem',
      [breakpoints.up('md')]: {
        fontSize: '2rem',
        lineHeight: '4rem',
      },
    },
    [TYPOGRAPHY_VARIANTS.SMALL_TEXT]: {
      fontSize: '1.8rem',
      fontWeight: 400,
    },
    [TYPOGRAPHY_VARIANTS.XS_TEXT]: {
      fontSize: '1.2rem',
      lineHeight: '1.4rem',
      fontWeight: 400,
      letterSpacing: '0.01em',
    },
    [TYPOGRAPHY_VARIANTS.XS_TEXT_2]: {
      fontSize: '1.4rem',
      fontWeight: 600,
      lineHeight: '2rem',
      letterSpacing: '0.04em',
      [breakpoints.up('md')]: {
        lineHeight: '3rem',
      },
    },
  }
}
