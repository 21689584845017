import { Entry, EntryCollection } from 'contentful'
import { client } from 'src/services/contentfulService'
import { CONTENT_ENTRIES } from 'src/utils/constants'
import { ContentfulFooter } from '~/components/Footer/Footer'
import fieldsParser from './contentful-parser'

/*
  This function represents parsing the contentful entries as form of props
*/
export const parseEntriesToProps = (item: any) => {
  if (!item) {
    return null
  }
  const parsed = fieldsParser(item)

  const basePageObj = {
    ...parsed,
    id: parsed.id,
    isPublicSite: parsed.isPublicSite,
    slug: parsed.slug,
    updatedAt: parsed?.updatedAt,
    isPageable: item?.metadata?.tags?.some(
      (i: { sys: { id: string } }) => i.sys.id === 'pagination'
    ),
  }

  if (parsed.__typename === 'page') {
    return {
      page: {
        ...basePageObj,
        contentEntities: parsed.contentEntities,
      },
      contentType: parsed.__typename,
    }
  }
  return {
    page: {
      ...basePageObj,
      content: parsed.content,
      sources: parsed.sources || null,
      asset: parsed.asset,
      headline: parsed.headline,
      footer: parsed.footer?.content,
    },
    contentType: parsed.__typename,
  }
}

export const getPageOrArticle = async (url: string, locale: string) => {
  const [page, article] = await Promise.all([getPage(url, locale), getArticle(url, locale)])
  return article || page
}

export const getArticle = async (url: string, locale: string) => {
  const pageResponse: EntryCollection<any> = await client.getEntries({
    include: 5,
    'fields.slug': url,
    locale,
    content_type: 'article',
  })
  if (pageResponse.items.length > 0) {
    const footer: Entry<any> = await client.getEntry(CONTENT_ENTRIES.GLOBAL_ARTICLE_FOOTER)
    pageResponse.items[0].fields.footer = footer?.fields
  }
  return pageResponse.items[0] || null
}

export const getPage = async (url: string, locale: string) => {
  const pageResponse: EntryCollection<any> = await client.getEntries({
    include: 5,
    'fields.slug': url,
    locale,
    content_type: 'page',
  })

  return pageResponse.items[0] || null
}

/*
  This function represents retrieving content from contentful and returns the content as form of props.
*/
export const getPageProps = async (url: string, locale: string) => {
  const pageContent = await getPageOrArticle(url, locale)
  return parseEntriesToProps(pageContent)
}

export const getFooter = async (isLoggedIn: boolean) => {
  let entryId = CONTENT_ENTRIES.GLOBAL_UNAUTHENTICATED_FOOTER
  if (isLoggedIn) {
    entryId = CONTENT_ENTRIES.GLOBAL_AUTHENTICATED_FOOTER
  }
  const pageResponse: Entry<ContentfulFooter> = await client.getEntry(entryId, {
    content_type: 'footer',
  })
  return pageResponse
}

export const getNavLinks = async () => {
  const pageResponse: EntryCollection<any> = await client.getEntries({
    content_type: 'navLinks',
  })
  return pageResponse.items[0] || null
}
