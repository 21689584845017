import { Box, PropTypes, Typography, TypographyTypeMap } from '@material-ui/core'
import { signIn } from 'next-auth/react'
import Link from 'next/link'
import type { ReactNode } from 'react'
import { MessageDescriptor, useIntl } from 'react-intl'
import { TYPOGRAPHY_VARIANTS } from 'theme/typography'
import OrangeUnderline from '../../public/assets/svg/orange-underline.svg'
import { ROUTES } from '../../src/utils'

export type TProps = MessageDescriptor & {
  align?: PropTypes.Alignment
  children?: ReactNode
  color?:
    | 'initial'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error'
  display?: 'initial' | 'block' | 'inline'
  gutterBottom?: boolean
  noWrap?: boolean
  paragraph?: boolean
  variant?: `${TYPOGRAPHY_VARIANTS}` | 'inherit'
  className?: string
  i18nValues?: Record<string, string>
  fontWeight?: number
  routerParam?: string
}

interface CustomLinkProps {
  children: React.ReactNode
  url: string
  color?: string
  underlined?: boolean
  scroll?: boolean
  className?: string
}

export const T = ({ variant = 'inherit', ...props }: TProps) => {
  const tProps = { ...props }
  const intl = useIntl()
  delete tProps.i18nValues

  // Combine MUI typography and react-intl FormattedMessage to allow for formatted messages to conform to MUI typography
  // theming styles
  return (
    <Box fontWeight={props.fontWeight} component={'span'}>
      <Typography variant={variant} {...(tProps as TypographyTypeMap)}>
        {intl.formatMessage(
          { id: props.id },
          {
            br: () => <br />,
            b: (...chunks) => <b>{chunks}</b>,
            i: (...chunks) => <i>{chunks}</i>,
            strong: (...chunks) => <strong>{chunks}</strong>,
            ...props.i18nValues,
            termsLink: (...chunks) => (
              <Link href={ROUTES.TERMS} className="font-bold text-navy underline">
                {chunks}
              </Link>
            ),
            galleriTermsLink: (...chunks) => (
              <Link href={ROUTES.TERMS} className="text-orange-primary">
                {chunks}
              </Link>
            ),
            marketPlaceLink: (...chunks) => <Link href={ROUTES.MARKETPLACE}>{chunks}</Link>,
            financialIncentivesLink: (...chunks) => (
              <Link
                scroll={false}
                passHref
                href={ROUTES.POLICY + '#NoticeOfFinancialIncentives'}
                className="text-orange-primary"
              >
                {chunks}
              </Link>
            ),
            previewAccountLink: (...chunks) => <Link href={`/${props.routerParam}`}>{chunks}</Link>,
            SignIn: (...chunks) => (
              <a onClick={() => signIn('okta', { callbackUrl: ROUTES.MEMBER })}>{chunks}</a>
            ),
            SignUp: (...chunks) => <Link href={ROUTES.SIGN_UP}>{chunks}</Link>,
            About: (...chunks) => <CustomLink url={ROUTES.ABOUT}>{chunks}</CustomLink>,
            Home: (...chunks) => <CustomLink url={ROUTES.HOME}>{chunks}</CustomLink>,
            GetInTouchEmail: (...chunks) => {
              return (
                <CustomLink url="mailto:hello@guardian-well.com" className="whitespace-nowrap">
                  {chunks}
                </CustomLink>
              )
            },
            Profile: (...chunks) => {
              return <CustomLink url={`/${props.routerParam}`}>{chunks}</CustomLink>
            },
            Faq: (...chunks) => {
              return <CustomLink url={ROUTES.FAQ}>{chunks}</CustomLink>
            },
            OrangeUnderline: (...chunks) => {
              return (
                <span className="relative inline-block max-w-max whitespace-pre-wrap break-words">
                  <span className="relative z-10">{chunks}</span>
                  <OrangeUnderline
                    style={{
                      position: 'absolute',
                      bottom: '0',
                      left: '0',
                      maxWidth: '100%',
                      marginBottom: '-10px',
                    }}
                  />
                </span>
              )
            },
          }
        )}
        {props.children}
      </Typography>
    </Box>
  )
}

export default T

export const CustomLink = ({ children, url, className }: CustomLinkProps) => {
  return (
    <Link
      passHref
      href={url}
      className={`cursor-pointer font-bold text-navy underline ${className}`}
    >
      {children}
    </Link>
  )
}
