import { Document } from '@contentful/rich-text-types'
import { ReactNode, createContext, useCallback, useContext } from 'react'
import { client } from '~/services/contentfulService'

interface IContentBannerProviderProps {
  children: ReactNode
}
export interface ContentBannerType {
  message: string
  link: string
  displayOn: string
  bannerMessage: Document
}

type UseContentBannerType = {
  getBannerContents: () => Promise<ContentBannerType[] | undefined>
}

const ContentBannerContext = createContext<UseContentBannerType | undefined>(undefined)

export const ContentBannerProvider = ({ children }: IContentBannerProviderProps) => {
  const getBannerContents = useCallback(async () => {
    try {
      const response = await client.getEntries<ContentBannerType>({
        content_type: 'banner',
      })
      return (response.items || []).map(item => ({
        message: item.fields.message || '',
        link: item.fields.link || '',
        displayOn: item.fields.displayOn || '',
        bannerMessage: item.fields.bannerMessage || '',
      }))
    } catch (err) {
      console.log(`Error fetching Banners from contentful: ${JSON.stringify(err)}`)
    }
  }, [])

  const value = {
    getBannerContents,
  }

  return <ContentBannerContext.Provider value={value}>{children}</ContentBannerContext.Provider>
}

export const useContentBanner = () => {
  const ctx = useContext(ContentBannerContext)
  if (!ctx) {
    throw new Error('ContentBanner out of context')
  }
  return ctx
}
