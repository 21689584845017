import { ReactNode, useState } from 'react'
import { TYPOGRAPHY_VARIANTS } from 'theme/typography'
import { Typography } from '@material-ui/core'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'

type AccordionSectionProps = {
  title: string
  content: string | ReactNode
  mainDivClasses?: string
  textColor?: string
  onlyText?: boolean
}

export const Accordion = ({
  title,
  content,
  mainDivClasses = 'justify-between',
  textColor = 'text-navy',
  onlyText = false,
}: AccordionSectionProps) => {
  const [isOpened, setOpened] = useState<boolean>(false)
  const handleAccordion = () => {
    setOpened(!isOpened)
  }
  return (
    <div onClick={handleAccordion}>
      <div className={`flex ${mainDivClasses} pb-6 pt-6 ${textColor}`}>
        {onlyText ? (
          title
        ) : (
          <Typography variant={TYPOGRAPHY_VARIANTS.H3} className="text-xl font-normal">
            {title}
          </Typography>
        )}
        {isOpened ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </div>
      {isOpened && (
        <div className={`overflow-hidden ${textColor}`}>
          {onlyText ? (
            content
          ) : (
            <Typography variant={TYPOGRAPHY_VARIANTS.H3} className="pb-2 pt-2 text-xl font-normal">
              {content}
            </Typography>
          )}
        </div>
      )}
    </div>
  )
}
