import Link from 'next/link'
import GuardianLogoNew from '../../../public/assets/svg/guardian-logo-new.svg'
import GuardianLogo from '../../../public/assets/svg/guardian-logo.svg'

interface LinkLogoProps {
  /**
   * The href is optional, if not provided defaults to the Home Page
   */
  href?: string
  color?: string
  logoColor?: string
}

const LinkLogo = ({ href = '/', logoColor }: LinkLogoProps) => {
  return (
    <Link href={href} className="flex h-auto cursor-pointer">
      {logoColor === 'white' ? <GuardianLogoNew /> : <GuardianLogo />}
    </Link>
  )
}

export default LinkLogo
