import { Button } from '@material-ui/core'
import { signIn } from 'next-auth/react'
import Link from 'next/link'
import { ROUTES } from '../../utils'
import T from '../T'

export const DesktopLinksPublic = ({ className }: { className?: string }) => {
  return (
    <div className={`space-x-5 ${className}`}>
      <NavLink href={ROUTES.ABOUT} id="header.link.about" />
      <NavLink href={ROUTES.SIGN_UP} id="header.link.join" />
      <Button
        className="ml-8 w-20 border-navy text-navy"
        variant="outlined"
        color="primary"
        size="small"
        onClick={() => signIn('okta', { callbackUrl: ROUTES.MEMBER })}
      >
        <T id="header.login.button" />
      </Button>
    </div>
  )
}

function NavLink({ href, id }: { href: string; id: string }) {
  return (
    <Link passHref href={href}>
      <T
        className="cursor-pointer text-base font-semibold uppercase text-navy hover:underline"
        id={id}
      />
    </Link>
  )
}
