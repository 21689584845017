import { Document } from '@contentful/rich-text-types'
import { signIn, useSession } from 'next-auth/react'
import { useEffect, useState } from 'react'
import { getFooter } from '~/cms/contentful-client'
import { useUser } from '~/hooks'
import { ROUTES } from '~/utils'
import LinkLogo from '../LinkLogo/LinkLogo'
import Disclaimer from './Disclaimer'
import { Link } from '@material-ui/core'
import { useRouter } from 'next/router'

export type ContentfulFooter = {
  content: Document
  disclosureContent: Document
  disclosureTitle: string
  links: FooterLink[]
}

type FooterLink = {
  fields: {
    href: string
    label: string
    name: string
  }
}

export const Footer = () => {
  const { profileName, id } = useUser()
  const { data: session, status } = useSession()
  const [footerData, setFooterData] = useState<ContentfulFooter | null>(null)
  const isAuthenticated = !!session && !!id

  useEffect(() => {
    const fetchFooterData = async () => {
      if (status === 'authenticated' || status === 'unauthenticated') {
        const data = await getFooter(isAuthenticated)
        setFooterData(data?.fields)
      }
    }

    fetchFooterData()
  }, [status, isAuthenticated])

  return (
    <div className="w-full bg-navy px-8 pb-24 pt-12 lg:px-32">
      <LinkLogo href={isAuthenticated ? ROUTES.MEMBER : ROUTES.HOME} logoColor="white" />
      {footerData ? (
        <FooterContent footerData={footerData} profileName={profileName} />
      ) : (
        <div>Loading...</div>
      )}
    </div>
  )
}

type FooterContentProps = {
  footerData: ContentfulFooter
  profileName: string
}

const FooterContent = ({ footerData, profileName }: FooterContentProps) => {
  const { content, disclosureContent, disclosureTitle, links } = footerData
  const router = useRouter()

  const handleClick = (href: string) => {
    if (href === '/sign-in') {
      signIn('okta', { callbackUrl: ROUTES.MEMBER })
    } else {
      router.push(href === '/profile/' ? href + profileName : href)
    }
  }

  return (
    <div className="flex flex-col-reverse lg:flex-row">
      <Disclaimer
        content={content}
        disclosureTitle={disclosureTitle}
        disclosureContent={disclosureContent}
      />
      <div className="mt-4 lg:ml-16">
        {links.map((link, index) => {
          const { href, name } = link.fields
          return (
            <Link
              key={index}
              className="block text-base text-white hover:cursor-pointer hover:underline"
              onClick={() => handleClick(href)}
            >
              {name}
            </Link>
          )
        })}
      </div>
    </div>
  )
}
