const dayjs = require('dayjs')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

export const getFromNow = (date: string): string => {
  return dayjs(date).fromNow()
}

export const formatDateMonthYear = (strDate: string) => dayjs(strDate).format('MMMM YYYY')

export const formatDateForTable = (strDate: string) =>
  dayjs(strDate).format('MM/DD/YYYY hh:mm:ss A')

export const getTimeDifferent = (
  date1: string,
  date2: string,
  unit: 'month' | 'day' | 'week'
): number => {
  const firstDate = dayjs(date1)
  return firstDate.diff(date2, unit, true)
}

export const fromDateTimeToTimestamp = (date: string, time: string = '00:00'): number => {
  if (!date) {
    throw new Error('fromDateTimeToTimestamp: date is required.')
  }
  return Math.floor(new Date(`${date} ${time}`).valueOf() / 1000)
}
