import { DefaultSeoProps } from 'next-seo'

const title = 'GuardianWell'
const description =
  'GuardianWell gives you access to resources designed to help you improve your physical, emotional and financial well-being. Plus, we offer exclusive member deals on innovative modern well-being products -- from nutrition, fitness, mental and financial health to products that help create a healthier environment'

export const defaultOpenGraphImage = {
  url: `${process.env.NEXT_PUBLIC_BASE_URL}/assets/guardianwell-meta-logo.jpg`,
  width: 1200,
  height: 630,
  alt: 'GuardianWell',
  type: 'image/jpg',
}

const config: DefaultSeoProps = {
  title,
  description,
  openGraph: {
    title,
    description,
    siteName: title,
    type: 'website',
  },
}

export default config
