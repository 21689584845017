import { useCallback, useState } from 'react'
import { useAuthContext } from '../provider/authProvider'
import { API, Callbacks } from '../utils'
import Stripe from 'stripe'
import { Card } from '../components/Account/Panels/Payments'

const capitalizeFirstLetter = (string: String) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const cardFromPaymentMethod = (paymentMethod: Stripe.PaymentMethod): Card => {
  return {
    id: paymentMethod.id,
    name: paymentMethod.billing_details.name as string,
    cardNumber: paymentMethod.card?.last4 as string,
    expiration: paymentMethod.card?.exp_month + '/' + paymentMethod.card?.exp_year,
    cvc: '',
    zip: paymentMethod.billing_details.address?.postal_code as string,
    cardType: capitalizeFirstLetter(paymentMethod.card?.brand as string),
  }
}

export const usePaymentMethod = () => {
  const { doPost, doGet, doPut, doDelete } = useAuthContext()
  const [cards, setCards] = useState<Card[] | undefined>()

  const getPaymentMethodList = useCallback(
    (callbacks?: Callbacks) => {
      doGet<Stripe.ApiList<Stripe.PaymentMethod>>(API.GET_PAYMENT_METHOD_LIST, callbacks).then(
        response => {
          if (response) {
            if (response.data) {
              setCards(
                response.data.map((paymentMethod: Stripe.PaymentMethod) =>
                  cardFromPaymentMethod(paymentMethod)
                )
              )
            } else {
              setCards([])
            }
          }
        }
      )
    },
    [doGet]
  )

  const addPaymentMethod = useCallback(
    (paymentMethodId: string, resolve: () => void, callbacks?: Callbacks) => {
      doPost<Stripe.PaymentMethod>(API.CREATE_PAYMENT_METHOD, { paymentMethodId }, callbacks).then(
        response => {
          if (response) {
            setCards([...(cards as Card[]), cardFromPaymentMethod(response)])
          }
          resolve()
        }
      )
    },
    [doPost, cards]
  )

  const updatePaymentMethod = useCallback(
    (cardId: string, cardInfo: Card, resolve: () => void, callbacks?: Callbacks) => {
      doPut(API.UPDATE_PAYMENT_METHOD(cardId), cardInfo, callbacks).then(response => {
        getPaymentMethodList()
        resolve()
      })
    },
    [doPut]
  )

  const removePaymentMethod = useCallback(
    (cardId: string, resolve: () => void, callbacks?: Callbacks) => {
      doDelete(API.UPDATE_PAYMENT_METHOD(cardId), callbacks).then(reesponse => {
        getPaymentMethodList()
        resolve()
      })
    },
    [doDelete]
  )

  return {
    getPaymentMethodList,
    addPaymentMethod,
    updatePaymentMethod,
    removePaymentMethod,
    cards,
  }
}
