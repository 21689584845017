import { css } from 'styled-components'

enum BreakpointsEnum {
  DESKTOP_MIN_WIDTH = 767.99,
  TABLET_MAX_WIDTH = 1024,
  MOBILE_MAX_WIDTH = 768,
  WIDESCREEN_MIN_WIDTH = 1440,
}

export interface ScreenBreakpoints {
  mobile: string
  tablet: string
  desktop: string
  wideScreen: string
}

export const breakpoints: ScreenBreakpoints = {
  mobile: `screen and (max-width: ${BreakpointsEnum.MOBILE_MAX_WIDTH}px)`,
  tablet: `screen and (min-width: ${BreakpointsEnum.TABLET_MAX_WIDTH}px)`,
  desktop: `screen and (min-width: ${BreakpointsEnum.DESKTOP_MIN_WIDTH}px)`,
  wideScreen: `screen and (min-width: ${BreakpointsEnum.WIDESCREEN_MIN_WIDTH}px)`,
}

export const breakpoint =
  (key: keyof typeof breakpoints) =>
  (...args: Parameters<typeof css>) => {
    const styles = css(...args)
    return (props: { theme: { screenBreakpoints: typeof breakpoints } }) =>
      css`
        @media ${props.theme.screenBreakpoints[key]} {
          ${styles}
        }
      `
  }

export default breakpoints
