import { createContext, ReactNode, useCallback, useContext, useState } from 'react'
import { useAuthContext } from '../provider/authProvider'
import { API } from '../utils/constants'
import { Callbacks, Dispatchable } from '../utils/helper'

type UseMemberType = {
  firstName: string
  lastName: string
  profileName: string
  createdAt: string
  amount: string
  memberProfile: GetProfileType | undefined
  paymentId: string
  setFirstName: Dispatchable<string>
  setLastName: Dispatchable<string>
  setCreatedAt: Dispatchable<string>
  setProfileName: Dispatchable<string>
  setAmount: Dispatchable<string>
  setMemberProfile: Dispatchable<GetProfileType | undefined>
  setPaymentId: Dispatchable<string>
  getUserByProfileName: (id: string, callbacks?: Callbacks) => Promise<void>
}

export type GetProfileType = {
  firstName: string
  lastName: string
  profileName: string
  createdAt: string
  active: boolean
  location?: string
  biography?: string
  profilePictureURL?: string
  displayName?: string
}

const MemberContext = createContext<UseMemberType | undefined>(undefined)

interface MemberProviderProps {
  children: ReactNode
}

export const MemberProvider = ({ children }: MemberProviderProps) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [createdAt, setCreatedAt] = useState('')
  const [profileName, setProfileName] = useState('')
  const [amount, setAmount] = useState('')
  const [paymentId, setPaymentId] = useState('')
  const [memberProfile, setMemberProfile] = useState<GetProfileType>()

  const { doGet } = useAuthContext()

  const getUserByProfileName = useCallback(
    (id: string, callbacks?: Callbacks) =>
      doGet<GetProfileType>(API.GET_USER_BY_PROFILE_NAME + id, callbacks).then(response => {
        if (!!response) {
          setMemberProfile({
            firstName: response.firstName,
            lastName: response.lastName,
            profileName: response.profileName,
            createdAt: response.createdAt,
            active: response.active,
            location: response.location,
            biography: response.biography,
            profilePictureURL: response.profilePictureURL,
            displayName: response.displayName,
          })
        }
      }),
    []
  )

  const value = {
    amount,
    firstName,
    lastName,
    createdAt,
    profileName,
    memberProfile,
    paymentId,
    setAmount,
    setFirstName,
    setLastName,
    setCreatedAt,
    setProfileName,
    getUserByProfileName,
    setMemberProfile,
    setPaymentId,
  }

  return <MemberContext.Provider value={value}>{children}</MemberContext.Provider>
}

export const useMember = () => {
  const ctx = useContext(MemberContext)
  if (!ctx) {
    throw new Error('You are using Member out of context.')
  }
  return ctx
}
