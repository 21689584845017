import { ReactNode } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

interface StripeProviderProps {
  children: ReactNode
}

const STRIPE_KEY = process.env.NEXT_PUBLIC_STRIPE_KEY || ''

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_KEY)
const options = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap',
    },
  ],
}

export const StripeProvider = ({ children }: StripeProviderProps) => (
  <Elements options={options} stripe={stripePromise}>
    {children}
  </Elements>
)
