import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { Asset, createClient, EntryCollection } from 'contentful'
import { Dispatchable } from '../utils'
import {
  ContentMarketplaceProductsType,
  parseProductContent,
} from './useContentMarketplaceProducts'

interface IMerchantProviderProps {
  children: ReactNode
}

export type MerchantModel = {
  name: string
  image: any
  title: string
  description: string
  linkDescription: string
  desktopBannerImage: any
  mobileBannerImage: any
  productLogo: any
  products: ContentMarketplaceProductsType[]
  active: boolean
  complianceNumber?: string
  expiration?: string
  disclaimer: string
}

export type FeaturedModel = {
  image: any
  title: string
  description: string
  linkDescription: string
  linkReference: string
}

export interface ModelType<T> {
  fields: T
  assets: Array<any> | undefined
  id: string
}

type UseMerchantType = {
  merchants: ModelType<MerchantModel>[] | undefined
  setMerchants: Dispatchable<ModelType<MerchantModel>[] | undefined>
  featured: ModelType<FeaturedModel>[] | undefined
  setFeatured: Dispatchable<ModelType<FeaturedModel>[] | undefined>
  selectedMerchantId: string | undefined
  selectedMerchant: ModelType<MerchantModel> | undefined
  setSelectedMerchantId: Dispatchable<string | undefined>
  getMerchantDetails: (id: string) => Promise<ModelType<MerchantModel> | undefined>
}

const MerchantContext = createContext<UseMerchantType | undefined>(undefined)

export const MerchantProvider = ({ children }: IMerchantProviderProps) => {
  const [merchants, setMerchants] = useState<ModelType<MerchantModel>[]>()
  const [featured, setFeatured] = useState<ModelType<FeaturedModel>[]>()
  const [selectedMerchantId, setSelectedMerchantId] = useState<string | undefined>()
  const [selectedMerchant, setSelectedMerchant] = useState<ModelType<MerchantModel> | undefined>()

  const cntfSpace = process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID || ''
  const cntfToken = process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN || ''
  const cntfHost = process.env.NEXT_PUBLIC_CONTENTFUL_HOST || ''

  const client = createClient({
    // This is the space ID. A space is like a project folder in Contentful terms
    space: cntfSpace,
    // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
    accessToken: cntfToken,
    // This is the host api url, for the lower env's we use the Preview api and for prod we use the Delivery cdn api.
    host: cntfHost,
  })

  const getMerchant = useCallback(() => {
    try {
      client
        .getEntries({ content_type: 'merchantIndex' })
        .then((response: EntryCollection<any>) => {
          if (response.items.length > 0)
            generateMerchantObject(response.items, response.includes.Asset)
        })
    } catch (err) {
      console.log(`Error fetching merchants from contentful: ${JSON.stringify(err)}`)
    }
  }, [client])

  const getMerchantDetails = useCallback(
    async (name: string) => {
      try {
        const response: any = await client.getEntries({
          content_type: 'merchantIndex',
          'fields.name[match]': name,
          include: 1,
        })

        if (response && response.items[0]) {
          let newMerchant: ModelType<MerchantModel> = {
            fields: {
              name: '',
              image: '',
              title: '',
              description: '',
              linkDescription: '',
              desktopBannerImage: '',
              mobileBannerImage: '',
              productLogo: '',
              products: [],
              active: false,
              disclaimer: '',
            },
            id: '',
            assets: [],
          }

          const { productLogo } = response.items[0].fields
          newMerchant.fields = response.items[0].fields
          newMerchant.fields.name = (response.items[0].fields.name as string).toLowerCase()
          newMerchant.fields.products = generateProductObject(
            response.items[0].fields.products ?? []
          )
          newMerchant.fields.productLogo = productLogo
            ? {
                title: productLogo.fields.title,
                url: productLogo.fields.file.url,
              }
            : undefined
          newMerchant.id = response.items[0].sys.id

          setSelectedMerchant(newMerchant)
          return newMerchant
        }
      } catch (err) {
        console.log(`Error fetching merchants detail from contentful: ${JSON.stringify(err)}`)
      }
    },
    [client]
  )

  const getFeatured = useCallback(() => {
    try {
      client.getEntries({ content_type: 'featured' }).then((response: EntryCollection<any>) => {
        if (response.items.length > 0)
          generateFeaturedObject(response.items, response.includes.Asset)
      })
    } catch (err) {
      console.log(`Error fetching featured from contentful: ${JSON.stringify(err)}`)
    }
  }, [client])

  useEffect(() => {
    if (!merchants) getMerchant()
    if (!featured) getFeatured()
  }, [getMerchant, merchants, getFeatured, featured])

  const generateMerchantObject = (items: Array<object>, images: Array<Asset>) => {
    const merchantList: ModelType<MerchantModel>[] = []
    items.forEach((element: any) => {
      let newMerchant: ModelType<MerchantModel> = {
        fields: {
          name: '',
          image: '',
          title: '',
          description: '',
          linkDescription: '',
          desktopBannerImage: '',
          mobileBannerImage: '',
          productLogo: '',
          products: [],
          active: false,
          disclaimer: '',
        },
        id: '',
        assets: [],
      }
      newMerchant.fields.name = (element.fields.name as string).toLowerCase()
      newMerchant.fields = { ...element.fields }
      newMerchant.fields.products = generateProductObject(element.fields.products)
      newMerchant.id = element.sys.id
      merchantList.push(newMerchant)
    })

    setMerchants(merchantList)
  }

  const generateFeaturedObject = (items: Array<object>, images: Array<Asset>) => {
    const featuredList: ModelType<FeaturedModel>[] = []

    items.forEach((element: any) => {
      let newFeatured: ModelType<FeaturedModel> = {
        fields: { image: '', title: '', description: '', linkDescription: '', linkReference: '' },
        id: '',
        assets: [],
      }
      newFeatured.fields = element.fields
      newFeatured.id = element.sys.id

      featuredList.push(newFeatured)
    })

    setFeatured(featuredList)
  }

  const generateProductObject = (items: Array<object>) => {
    const productList: ContentMarketplaceProductsType[] = []
    ;(items ?? []).forEach((element: any) => {
      productList.push(parseProductContent(element))
    })

    return productList
  }

  const value = {
    merchants,
    featured,
    setMerchants,
    setFeatured,
    selectedMerchantId,
    setSelectedMerchantId,
    getMerchantDetails,
    selectedMerchant,
  }

  return <MerchantContext.Provider value={value}>{children}</MerchantContext.Provider>
}

export const useMerchants = () => {
  const ctx = useContext(MerchantContext)
  if (!ctx) {
    throw new Error('Mechant out of context')
  }
  return ctx
}
