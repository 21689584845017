import React, { useContext, ReactNode, createContext, useCallback } from 'react'
import { client } from '~/services/contentfulService'

interface IContentFAQProviderProps {
  children: ReactNode
}
export interface ContentFAQType {
  question: string
  answer: string
}

type UseContentFAQType = {
  getFAQContents: () => Promise<ContentFAQType[] | undefined>
}

const ContentFAQContext = createContext<UseContentFAQType | undefined>(undefined)

export const ContentFAQProvider = ({ children }: IContentFAQProviderProps) => {
  const getFAQContents = useCallback(async () => {
    try {
      const response = await client.getEntries<ContentFAQType>({
        content_type: 'faq',
        order: 'fields.priority',
      })
      return (response.items || []).map(item => ({
        question: item.fields.question,
        answer: item.fields.answer,
      }))
    } catch (err) {
      console.log(`Error fetching faqs from contentful: ${JSON.stringify(err)}`)
    }
  }, [])

  const value = {
    getFAQContents,
  }

  return <ContentFAQContext.Provider value={value}>{children}</ContentFAQContext.Provider>
}

export const useContentFAQ = () => {
  const ctx = useContext(ContentFAQContext)
  if (!ctx) {
    throw new Error('ContentFAQ out of context')
  }
  return ctx
}
