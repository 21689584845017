import { useTheme } from '@material-ui/core'
import { StripeCardNumberElementOptions } from '@stripe/stripe-js'
import { useMemo } from 'react'

export const useOptions = () => {
  const theme = useTheme()

  return useMemo(
    () =>
      ({
        style: {
          base: {
            fontFamily: theme.typography.fontFamily,
            fontVariant: 'normal',
            fontSize: '15px',
            color: theme.palette.inputColor.main,
            '::placeholder': {
              color: '#0D3F5E',
            },
          },
          invalid: {
            color: theme.palette.passwordmeter.weak,
          },
        },
      } as StripeCardNumberElementOptions),
    [theme]
  )
}
