import { createContext, ReactNode, useCallback, useContext } from 'react'
import { Entry } from 'contentful'
import { client } from '../services/contentfulService'
import { Document, BLOCKS } from '@contentful/rich-text-types'
import { API, Callbacks } from '../utils'
import { useAuthContext } from '../provider/authProvider'
import Stripe from 'stripe'

interface IContentMarketplaceProductsProviderProps {
  children: ReactNode
}

export interface MarketplaceProductFields {
  image: {
    url: string
    title: string
  }
  name: string
  listPrice: number
  clementinePrice: number
  description: Document
  purchaseMessage: Document
  linkUrl: string
  linkText: string
  code?: string
  badge?: string
}

export interface ContentMarketplaceProductsType {
  fields: MarketplaceProductFields
  assets?: Array<any>
  id?: string
}

type UseContentMarketplaceProductType = {
  getProductContent: (id: string) => Promise<MarketplaceProductFields | undefined>
  buyProduct: (productData: BuyProductType, callbacks?: Callbacks) => void
  getPromoCode: (id: string) => Promise<Stripe.ApiList<Stripe.PromotionCode> | undefined>
  updatePromoCode: (
    id: string,
    newPromotionCode: Stripe.PromotionCodeUpdateParams
  ) => Promise<Stripe.ApiList<Stripe.PromotionCode> | undefined>
  deletePromoCode: (id: string) => Promise<Stripe.ApiList<Stripe.PromotionCode> | undefined>
  createProductNotification: (
    notificationData: ProductNotificationType,
    callbacks?: Callbacks
  ) => void
}

const ContentMarketplaceProductsContext = createContext<
  UseContentMarketplaceProductType | undefined
>(undefined)

export const parseProductContent = (element: any) => {
  const { file, title } = element.fields.image.fields
  let newProductsContent: ContentMarketplaceProductsType = {
    fields: {
      clementinePrice: 0,
      listPrice: 0,
      description: {
        nodeType: BLOCKS.DOCUMENT,
        data: {},
        content: [],
      },
      image: {
        title: '',
        url: '',
      },
      linkText: '',
      linkUrl: '',
      name: '',
      purchaseMessage: {
        nodeType: BLOCKS.DOCUMENT,
        data: {},
        content: [],
      },
    },
  }
  newProductsContent.fields = { ...element.fields }
  newProductsContent.fields.image = {
    title,
    url: file.url,
  }
  newProductsContent.id = element.sys.id

  return newProductsContent
}

export type BuyProductType = {
  id: string
  price: number
  merchantId: string
}

export type ProductNotificationType = {
  email: string
  campaign: string
}

export const ContentMarketplaceProductsProvider = ({
  children,
}: IContentMarketplaceProductsProviderProps) => {
  const { doPost, doGet, doPut, doDelete } = useAuthContext()

  const buyProduct = useCallback(
    (productoData: BuyProductType, callbacks?: Callbacks) =>
      doPost(API.BUY_PRODUCT, productoData, callbacks),
    [doPost]
  )

  const createProductNotification = useCallback(
    (notoficationData: ProductNotificationType, callbacks?: Callbacks) =>
      doPost(API.CREATE_PRODUCT_NOTIFICATION, notoficationData, callbacks),
    [doPost]
  )
  const getProductContent = useCallback(async (id: string) => {
    try {
      const response: Entry<any> = await client.getEntry(id, { content_type: 'merchantProducts' })
      const parsedProduct = parseProductContent(response)
      return parsedProduct.fields
    } catch (err) {
      console.log(`Error fetching product detail from contentful: ${JSON.stringify(err)}`)
    }
  }, [])

  const getPromoCode = useCallback(
    async (id: string) => {
      try {
        const response = await doGet<Stripe.ApiList<Stripe.PromotionCode>>(API.GET_PROMO_CODE(id))
        return response
      } catch (err) {
        console.log(`Error fetching promode code detail from stripe: ${JSON.stringify(err)}`)
      }
    },
    [doGet]
  )

  const deletePromoCode = useCallback(
    async (id: string) => {
      try {
        const response = await doDelete<Stripe.ApiList<Stripe.PromotionCode>>(
          API.GET_PROMO_CODE(id)
        )
        return response
      } catch (err) {
        console.log(`Error fetching promode code detail from stripe: ${JSON.stringify(err)}`)
      }
    },
    [doDelete]
  )

  const updatePromoCode = useCallback(
    async (id: string, newPromotionCode: Stripe.PromotionCodeUpdateParams) => {
      try {
        const response = await doPut<Stripe.ApiList<Stripe.PromotionCode>>(
          API.GET_PROMO_CODE(id),
          newPromotionCode
        )
        return response
      } catch (err) {
        console.log(`Error fetching promode code detail from stripe: ${JSON.stringify(err)}`)
      }
    },
    [doPut]
  )

  const value = {
    getProductContent,
    buyProduct,
    getPromoCode,
    deletePromoCode,
    updatePromoCode,
    createProductNotification,
  }

  return (
    <ContentMarketplaceProductsContext.Provider value={value}>
      {children}
    </ContentMarketplaceProductsContext.Provider>
  )
}

export const useContentMarketplaceProducts = () => {
  const ctx = useContext(ContentMarketplaceProductsContext)
  if (!ctx) {
    throw new Error('ContentMarketplaceProducts out of context')
  }
  return ctx
}
