import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, Block, Document, INLINES, Inline } from '@contentful/rich-text-types'
import { Accordion } from '../Shared/Accordion/Accordion'
import { ReactNode } from 'react'

const Disclaimer = ({
  content,
  disclosureTitle,
  disclosureContent,
}: {
  content: Document
  disclosureTitle: string
  disclosureContent: Document
}) => {
  const textClasses = 'text-xs font-normal tracking-wide text-white'
  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node: Block | Inline, children: ReactNode) => (
        <span className={`${textClasses} underline`}>
          <a href={node.data.uri}>{children}</a>
        </span>
      ),
      [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: ReactNode) => {
        return <span className={`${textClasses} pt-5`}>{children}</span>
      },
    },
    renderText: (text: string) => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  }

  return (
    <div className={`flex max-w-5xl flex-col pt-5 text-xs`}>
      {documentToReactComponents(content, options)}
      <Accordion
        title={disclosureTitle}
        content={documentToReactComponents(disclosureContent, options)}
        mainDivClasses=""
        textColor="text-white"
        onlyText
      />
    </div>
  )
}

export default Disclaimer
