import { Variant } from '@material-ui/core/styles/createTypography'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { Session } from 'next-auth'
import { signIn, signOut } from 'next-auth/react'
import { NextRouter } from 'next/router'
import { Dispatch, SetStateAction } from 'react'
import validator from 'validator'
import { COLORS } from '../../theme/colors'
import { Author, SortField } from '../hooks'
import { ADMIN_ID, MOVEMENT_TYPE, ROUTES } from './constants'

export enum Emojis {
  HeartEyes = 'HeartEyes',
  Happy = 'Happy',
  Hello = 'Hello',
  Party = 'Party',
  Star = 'Star',
  Medal = 'Medal',
}

export enum Categories {
  Fitness = 'Fitness',
  Nutrition = 'Nutrition',
  Mindfulness = 'Mindfulness',
  Insurance = 'Insurance',
  Environmental = 'Environmental',
  Financial = 'Financial',
  Educational = 'Educational',
}

export enum ColorUpvoting {
  Yellow = 'Yellow',
  Orange = 'Orange',
  Violet = 'Violet',
  Pink = 'Pink',
  Green = 'Green',
  Skyblue = 'Skyblue',
  Purple = 'Purple',
}

export enum HeadersOptions {
  Thanks,
  Empty,
  Avatar,
}

export enum RequestType {
  Post,
  Get,
  Delete,
  Put,
}

const almostOneLetterRegex = /[a-zA-Z]/

// Other type than 'any' gives a Typescript error when passing an actual value to 'T'
export type Callbacks<T = any> = {
  success?: (value?: T) => void
  error?: (value?: string) => void
  finally?: () => void
}

export type Dispatchable<T> = Dispatch<SetStateAction<T>>

export type ColorProps = {
  color: string
}

export type ResolutionProps = {
  ismobile: string
  style?: any
  hasLoggedIn?: any
  id?: any
}

export type MDProps = {
  hmobile: Variant
  hdesktop: Variant
} & ResolutionProps

export type DataProps = {
  user?: {
    idToken?: string
  }
  props?: {
    content?: {
      session?: {
        user?: {
          idToken?: string
        }
      }
    }
  }
}

export const formatPrice = (value: number) => {
  const formatter = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  return formatter.format(value)
}

export const isSSNValid = (value: string) => {
  const numberValue = Number(value)
  return value.length === 4 && !isNaN(numberValue)
}

export const isEmailValid = (email: string) => {
  if (email.length > 50 && email.length < 3) return false
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailRegex.test(email)
}

export const isPasswordValid = (value: string) => {
  if (value.length >= 8 && value.length <= 30) {
    const conditions = [
      /[A-Z]/,
      /[a-z]/,
      /\d/,
      /[\"|!|#|$|%|&|'|(|)|\*|\+|,|\-|.|\/|\\|:|;|<|=|>|\?|@|\[|\]|\^|_|`|{|\||}|~|\]]/,
    ]
    return conditions.every(condition => condition.test(value))
  }
  return false
}

export const fullName = (firstName: string, lastName: string) => {
  return (
    firstName.charAt(0).toUpperCase() +
    firstName.slice(1) +
    ' ' +
    lastName.charAt(0).toUpperCase() +
    lastName.slice(1)
  )
}

export const getFirstName = (name: string | undefined) => {
  return !name ? '' : name.split(' ')[0]
}

export const capitalizeFirstLetter = (text: string) => text.charAt(0).toUpperCase() + text.slice(1)

export const isFirstNameValid = (value: string) =>
  value.length > 0 && value.length <= 30 && almostOneLetterRegex.test(value)

export const isLastNameValid = (value: string) =>
  value.length > 0 && value.length <= 30 && almostOneLetterRegex.test(value)

export const isPhoneValid = (value: string) => value.length === 10 && validator.isMobilePhone(value)

export type PasswordStrengthValue = '' | 'weak' | 'ok' | 'good' | 'strong'

export type PasswordStrengthKey = 0 | 1 | 2 | 3 | 4

export type GetPasswordStrengthType = {
  strength: PasswordStrengthKey
  value: PasswordStrengthValue
}

export const getPasswordStrength = (
  password: string,
  email: string,
  firstName: string,
  lastName: string
): GetPasswordStrengthType => {
  const conditions = {
    upper: /[A-Z]/,
    lower: /[a-z]/,
    number: /\d/,
    special: /[^a-zA-Z\d\s:]/,
    repeatedCharacters: /((\w)\2{2,})/,
    repeatedSequence: /(.)\1\1/,
    minLength: 8,
    score: 0,
  }
  const strength: Record<PasswordStrengthKey, PasswordStrengthValue> = {
    0: 'weak',
    1: 'weak',
    2: 'ok',
    3: 'good',
    4: 'strong',
  }

  let { upper, lower, number, special, repeatedCharacters, repeatedSequence, minLength, score } =
    conditions

  const username = email.slice(0, email.indexOf('@'))

  if (
    password.length < minLength ||
    password.includes(firstName) ||
    password.includes(lastName) ||
    password.includes(username)
  )
    return { strength: 0, value: 'weak' }

  if (
    upper.test(password) &&
    lower.test(password) &&
    number.test(password) &&
    special.test(password)
  )
    score += 1

  if (password.length >= 10) score += 1

  if (!repeatedCharacters.test(password)) score++

  if (!repeatedSequence.test(password)) score++

  return {
    value: strength[Math.trunc(score) as PasswordStrengthKey],
    strength: Math.trunc(score) as PasswordStrengthKey,
  }
}

const API_URL = process.env.API_URL || ''

export const manageResponse = (response: AxiosResponse<any>, callbacks?: Callbacks) => {
  if (response.status >= 200 && response.status < 400) {
    if (callbacks?.success != null) callbacks.success(response.data)
  } else {
    throw Error(response.data)
  }
  return response.data
}

export const manageError = (error: any, callbacks?: Callbacks) => {
  const { response } = error as AxiosError

  if (response?.status === 500) window.location.href = ROUTES.INTERNAL_SERVER
  else if (callbacks?.error) {
    if (response?.data?.message?.length > 0 && response?.data?.message[0]?.length > 1)
      callbacks.error(response?.data?.message[0] ?? String(error))
    else callbacks.error(response?.data?.message ?? String(error))
  } else throw error
}

export const doPost = async <T>(endpoint: string, body: unknown, callbacks?: Callbacks) => {
  try {
    const result = await axios
      .post(`${API_URL}${endpoint}`, body)
      .then(response => manageResponse(response, callbacks))
    return result as T
  } catch (error) {
    manageError(error, callbacks)
  } finally {
    if (callbacks?.finally != null) callbacks.finally()
  }
}

export const doGet = async <T>(endpoint: string, callbacks?: Callbacks) => {
  try {
    const result = await axios
      .get(`${API_URL}${endpoint}`)
      .then(response => manageResponse(response))
    return result as T
  } catch (error) {
    manageError(error, callbacks)
  } finally {
    if (callbacks?.finally != null) callbacks.finally()
  }
}

export const doPut = async <T>(endpoint: string, body: unknown, callbacks?: Callbacks) => {
  try {
    const result = await axios
      .put(`${API_URL}${endpoint}`, body)
      .then(response => manageResponse(response, callbacks))
    return result as T
  } catch (error: any) {
    manageError(error, callbacks)
  } finally {
    if (callbacks?.finally != null) callbacks.finally()
  }
}

export const priceHandler = (value: string) => {
  const bannedChars = ['+', '-', ',']
  const index = value.indexOf('.')

  const preventDuplicateDots = (currentValue: string) => {
    const position = currentValue.split('.', index).join('.').length - 1
    return currentValue.slice(0, position === 0 ? 2 : position)
  }

  // this code removes all banned characters allowed by input type number that are not numbers
  bannedChars.forEach(char => {
    if (value.includes(char)) value = value.replace(char, '')
  })

  // this code removes all alphabetic characters
  value = value.replace(/([a-zA-Z ])/g, '')

  // this code removes all special characters that are not a dot
  value = value.replaceAll(/[^a-zA-Z\s\d.]/g, '')

  //this code allows to write only two decimals
  if (index !== -1) {
    const splitedByDecimals = value.split('.')
    if (splitedByDecimals[1].length > 2) value = value.slice(0, splitedByDecimals[0].length + 3)
  }

  // this code prevent to add more than one dot
  while (value.split('.').length - 1 > 1) {
    value = preventDuplicateDots(value)
  }

  return value
}

export const isPriceValid = (value: string) => Number(value) <= 250 && Number(value) >= 10

export const preventSpecialCharacters = (value: string) => value.replaceAll(/[^a-zA-Z\s]/g, '')

type Directions = 'up' | 'down' | 'left' | 'right'

export const getGap = (remValue: number, direction: Directions = 'down') => {
  const target = {
    up: 'marginTop',
    down: 'marginBottom',
    left: 'marginLeft',
    right: 'marginRight',
  }
  return {
    '@supports (-webkit-touch-callout: none)': {
      '& > *': {
        [target[direction]]: `${remValue}rem`,
      },
      '&:not(:last-child)': {
        [target[direction]]: '0',
      },
    },

    gap: `${remValue}rem`,
  }
}

export const isMessageValid = (message: string) =>
  message.length > 9 && message.length <= 400 && almostOneLetterRegex.test(message)

export type PageProps<T> =
  | {
      type: 'success'
      content: { data: T; session: Session | null }
    }
  | {
      type: 'error'
      content: {
        statusCode: number
        errorMessage: string
        session: null
      }
    }
  | {
      type: 'info'
      content: {
        statusCode: number
        message: string
        session: null
      }
    }

export const handleUnauthorize = (props: PageProps<unknown>, router: NextRouter, data: any) => {
  const isActivation = router.query?.type_hint === 'ACTIVATION'

  if (isActivation) {
    signIn('okta')
    return
  }

  if (props.type !== 'error') {
    return
  }

  switch (props.content.statusCode) {
    case 401:
      router.push(ROUTES.HOME)
      break
    case 404:
      router.push(ROUTES.NOT_FOUND)
      break
    case 440:
      logoutFromOkta(`${window.location.origin}${ROUTES.HOME}`, data)
      break
    default:
      router.push(ROUTES.INTERNAL_SERVER)
  }
}

export function doServerSideGet<T>(
  url: string,
  headers: Record<string, string>,
  session: Session | null
) {
  return handlePromise(
    axios.get<T>(url, {
      headers,
    }),
    session
  )
    .then(result => {
      return result
    })
    .catch(error => {
      throw error
    })
}

export function handlePromise<T>(promise: Promise<AxiosResponse<T, any>>, session: Session | null) {
  return promise
    .then(response => {
      return {
        props: {
          type: 'success',
          content: {
            data: response.data,
            session: session ? { ...session, accessToken: '' } : null,
          },
        },
      }
    })
    .catch((err: AxiosError) => {
      return {
        props: {
          type: 'error',
          content: {
            statusCode: err.response?.status || 500,
            message: err.message,
          },
        },
      }
    })
}

export const sessionHasExpired = (session: Session) => {
  if (session.expires && session.expires <= new Date().toISOString()) {
    return {
      props: {
        type: 'error',
        content: {
          statusCode: 440,
          message: 'Session expired',
        },
      },
    }
  }
  return false
}

export const NOT_LOGGED_IN = {
  props: {
    type: 'error',
    content: {
      statusCode: 440,
      message: 'not logged in',
    },
  },
}

export const validateNotEmptyText = (value: string, minLength = 0, maxLength = 400) =>
  value.length > minLength && value.length <= maxLength && almostOneLetterRegex.test(value)

export const isBioValid = (value: string) => !value || validateNotEmptyText(value, 9, 1500)

export const isNicknameValid = (value: string) => validateNotEmptyText(value, 0, 25)

export const isLocationValid = (value: string) => !value || validateNotEmptyText(value, 1, 25)

export const includesAnyNames = (value: string, firstName: string, lastName: string) =>
  value.includes(firstName) || value.includes(lastName)

export const isRecoveryValid = (
  currentRecovery: string,
  firstName: string,
  lastName: string,
  otherRecovery: string
) =>
  validateNotEmptyText(currentRecovery, 3, 100) &&
  (firstName.length < 1 ||
    lastName.length < 1 ||
    !includesAnyNames(currentRecovery, firstName, lastName)) &&
  (otherRecovery.length < 1 || !currentRecovery.includes(otherRecovery))

export const isChangableStatus = (statusId: string) =>
  MOVEMENT_TYPE.PAYMENT_PENDING === statusId || MOVEMENT_TYPE.PAYMENT_FULFILLED === statusId

export const isDisplayableStatus = (statusId: string) =>
  [
    MOVEMENT_TYPE.PAYMENT_FULFILLED,
    MOVEMENT_TYPE.PAYMENT_CANCELED,
    MOVEMENT_TYPE.PAYMENT_PENDING,
    MOVEMENT_TYPE.REFUND,
  ].indexOf(statusId) >= 0

export const stringAvatar = (firstName: string, lastName: string) =>
  `${firstName.trim().charAt(0).toUpperCase()}${lastName.trim().charAt(0).toUpperCase()}`

export const showDifferentSecurityErrors = (
  currentRecovery: string,
  firstName: string,
  lastName: string,
  otherRecovery: string,
  callback: Dispatchable<'length' | 'name' | 'recovery'>
) => {
  if (!validateNotEmptyText(currentRecovery, 3, 100)) {
    callback('length')
  } else if (
    firstName.length > 0 &&
    lastName.length > 0 &&
    includesAnyNames(currentRecovery, firstName, lastName)
  ) {
    callback('name')
  } else if (otherRecovery.length > 0 && currentRecovery.includes(otherRecovery)) {
    callback('recovery')
  }
}

export const getSubstringBetweenCharacters = (
  text: string,
  startingChar: string,
  endingChar: string
): string => {
  const startingCharIndex = text.indexOf(startingChar) + 1
  const endingCharIndex = text.indexOf(endingChar)

  if (startingCharIndex === -1 || endingCharIndex === -1) return text

  return text.substring(startingCharIndex, endingCharIndex)
}

export const getImageExtensionFromBase64 = (dataURL: string) => {
  const [fileHeader] = dataURL.split(',')
  const mimeType = getSubstringBetweenCharacters(fileHeader, ':', ';')
  const [, fileExtension] = mimeType.split('/')

  return fileExtension
}

export const handleAdminRoleRedirection = (roleId: string, router: NextRouter) => {
  if (roleId === ADMIN_ID) {
    router.push(ROUTES.ADMIN_PORTAL)
  }
}

export const getColorByCategory = (color: ColorUpvoting) => {
  switch (color) {
    case ColorUpvoting.Green:
      return COLORS.upvoting.green
      break
    case ColorUpvoting.Orange:
      return COLORS.upvoting.orange
      break
    case ColorUpvoting.Pink:
      return COLORS.upvoting.pink
      break
    case ColorUpvoting.Purple:
      return COLORS.upvoting.purple
      break
    case ColorUpvoting.Skyblue:
      return COLORS.upvoting.skyblue
      break
    case ColorUpvoting.Violet:
      return COLORS.upvoting.violet
      break
    case ColorUpvoting.Yellow:
      return COLORS.upvoting.yellow
      break
    default:
      return COLORS.upvoting.yellow
      break
  }
}

export const getSortField = (param: SortField | 'pendings' | 'actions') => {
  switch (param) {
    case 'pendings':
    case 'pendingPaymentsCount':
      return 'pendingPaymentsCount'
    case 'actions':
      return ''
    default:
      return param
  }
}

export const createAuthor = ({
  authorFirstName,
  authorLastName,
  authorEmail,
  authorId,
  authorAvatar = '',
  authorAgeRange,
}: {
  authorFirstName: string
  authorLastName: string
  authorEmail: string
  authorId: string
  authorAvatar?: string
  authorAgeRange?: string
}): Author => {
  return {
    authorFullname:
      `${capitalizeFirstLetter(authorFirstName)} ${capitalizeFirstLetter(authorLastName)}` || '',
    authorEmail: authorEmail,
    authorId,
    authorAvatar,
    authorAgeRange,
  }
}

export type CardType =
  | 'Mastercard'
  | 'Visa'
  | 'Amex'
  | 'Discover'
  | 'Diners'
  | 'Unionpay'
  | 'JCB'
  | ''

export const isClient = (): boolean => {
  return typeof window !== 'undefined'
}

export const getHostUrl = (): string => {
  const port = isClient() ? (window.location.port ? ':' + window.location.port : '') : ''
  const hostUrl = isClient()
    ? `${window.location.protocol}//${window.location.hostname}${port}`
    : ''

  return `${hostUrl}`
}

export const logoutFromOkta = (redirectURL: string, data: DataProps) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ user_id: null })

  const idToken = data?.user?.idToken || data?.props?.content?.session?.user?.idToken

  if (!idToken) return window.location.assign('/')

  signOut({ redirect: false })
    .then(() =>
      window.location.assign(
        `${process.env.NEXT_PUBLIC_OKTA_BASE_URL}/v1/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${redirectURL}`
      )
    )
    .catch(error => {
      console.error('Logout failed:', error)
      window.location.assign('/')
    })
}
