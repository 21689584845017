import { EntryCollection } from 'contentful'
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { client } from '../services/contentfulService'
import { Dispatchable } from '../utils'

interface IContentRegistrationProviderProps {
  children: ReactNode
}

export type ContentRegistrationFields = {
  name: string
  header: string
  subheader?: string
  description?: string
}

interface ContentRegistrationType {
  fields: ContentRegistrationFields
  assets?: Array<any>
  id?: string
}

type StepContent = ContentRegistrationFields | undefined

type UseContentRegistrationType = {
  registrationContent: ContentRegistrationType[] | undefined
  setRegistrationContent: Dispatchable<ContentRegistrationType[] | undefined>
  getFirstStep: () => StepContent
  getCongratulationsStep: () => StepContent
}

const ContentRegistrationContext = createContext<UseContentRegistrationType | undefined>(undefined)

const ContentRegistrationProvider = ({ children }: IContentRegistrationProviderProps) => {
  const [registrationContent, setRegistrationContent] = useState<ContentRegistrationType[]>()

  const getRegistrationContent = useCallback(() => {
    client.getEntries({ content_type: 'registration' }).then((response: EntryCollection<any>) => {
      generateContentRegistrationObject(response.items)
    })
  }, [])

  const generateContentRegistrationObject = (items: Array<object>) => {
    const contentList: ContentRegistrationType[] = []

    items.forEach((element: any) => {
      let newRegistrationContent: ContentRegistrationType = {
        fields: { name: '', header: '', subheader: '', description: '' },
      }
      newRegistrationContent.fields = element.fields

      contentList.push(newRegistrationContent)
    })

    setRegistrationContent(contentList)
  }

  useEffect(() => {
    if (!registrationContent) getRegistrationContent()
  }, [registrationContent, getRegistrationContent])

  const getStep = (stepName: string): StepContent => {
    if (registrationContent) {
      const element = registrationContent.find(item => item.fields.name === stepName)
      return element?.fields
    }
  }

  const getFirstStep = () => {
    return getStep('firstStep')
  }

  const getCongratulationsStep = () => {
    return getStep('congratsStep')
  }

  const value = {
    registrationContent,
    setRegistrationContent,
    getFirstStep,
    getCongratulationsStep,
  }

  return (
    <ContentRegistrationContext.Provider value={value}>
      {children}
    </ContentRegistrationContext.Provider>
  )
}

export const useContentRegistration = () => {
  const ctx = useContext(ContentRegistrationContext)
  if (!ctx) {
    throw new Error('ContentRegistration out of context')
  }
  return ctx
}

export default ContentRegistrationProvider
