import { Button, Dialog, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu'
import { signIn } from 'next-auth/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { ROUTES } from '../../utils'
import T from '../T'

export const MobileMenuPublic = ({ className }: { className?: string }) => {
  const [open, setOpen] = useState(false)

  const router = useRouter()

  const onClickHandler = () => {
    let callBackUrl = router.asPath === '/' ? `${router.basePath}${ROUTES.MEMBER}` : router.asPath
    signIn('okta', {
      redirect: true,
      callbackUrl: callBackUrl,
    })
  }

  return (
    <div className={className}>
      <IconButton
        className="border-0"
        size="medium"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={() => setOpen(true)}
        color="inherit"
      >
        <MenuIcon color="primary" fontSize="large" />
      </IconButton>
      <Dialog fullScreen open={open} onClose={() => setOpen(false)}>
        <div className="flex justify-end">
          <IconButton className="border-0" onClick={() => setOpen(false)}>
            <CloseIcon color="primary" fontSize="large" />
          </IconButton>
        </div>
        <div className="ml-10 space-y-6">
          <NavLink href={ROUTES.ABOUT} id="header.link.about" />
          <NavLink href={ROUTES.SIGN_UP} id="header.link.join" />
          <div className="-ml-2">
            <Button
              className="w-40 text-navy"
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => onClickHandler()}
            >
              <T id="header.login.button" />
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

function NavLink({ href, id }: { href: string; id: string }) {
  return (
    <div>
      <Link passHref href={href}>
        <T
          className="cursor-pointer text-base font-normal uppercase text-navy hover:underline"
          id={id}
          align="left"
        />
      </Link>
    </div>
  )
}
