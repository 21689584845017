import { Overrides } from '@material-ui/core/styles/overrides'
import { AlertClassKey } from '@material-ui/lab'
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides'
import { ToggleButtonClassKey } from '@material-ui/lab/ToggleButton'
import { CSSProperties } from 'react'
import { COLORS } from './colors'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

type overridesPickers = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P]
}

interface OverridesNameToClassKey {
  MuiAlert?: Partial<Record<AlertClassKey, CSSProperties | (() => CSSProperties)>>
}
declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey extends overridesPickers {}

  export interface ComponentNameToClassKey extends OverridesNameToClassKey {}
  export interface ComponentNameToClassKey {
    MuiToggleButton: ToggleButtonClassKey
  }
}

const breakpoints = createBreakpoints({})

export const getThemeOverrides = (): Overrides => {
  return {
    MuiPaper: {
      rounded: {
        borderRadius: '1.2rem',
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'rgba(255, 98, 64, 0.1)',
          border: `1px solid ${COLORS.orange.primary}`,
        },
      },
    },
    MuiTypography: {
      paragraph: {
        marginBottom: 0,
      },
      button: {
        textTransform: 'none',
      },
    },
    MuiListItem: {
      root: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiButton: {
      root: {
        height: '6.4rem',
        padding: '2rem',
        borderRadius: '5rem',
        fontSize: '1.4rem',
        lineHeight: '3.2rem',
        fontWeight: 600,
      },
      sizeSmall: {
        height: '4.4rem',
        fontSize: '1.4rem',
        lineHeight: '1.8rem',
        padding: '0.6rem',
      },
      sizeLarge: {
        height: '6.4rem',
        fontSize: '1.4rem',
        lineHeight: '3.2rem',
        padding: '2rem',
      },
      contained: {
        boxShadow: 'none',
      },
      containedPrimary: {
        backgroundColor: COLORS.orange.primary,
        color: COLORS.gray.accessible,
        '&:hover': {
          backgroundColor: COLORS.orange.darker,
        },
        '&.Mui-disabled': {
          backgroundColor: COLORS.orange.darker,
          color: COLORS.gray.accessible,
          borderColor: 'transparent',
        },
      },
      containedSecondary: {
        backgroundColor: COLORS.lightOrange.primary,
        border: `1px solid ${COLORS.orange.primary}`,
        color: COLORS.orange.primary,
        '&:hover': {
          backgroundColor: COLORS.lightOrange.darker,
        },
        '&.Mui-disabled': {
          backgroundColor: COLORS.lightOrange.light,
          color: COLORS.gray.light,
          borderColor: 'transparent',
        },
      },
      outlinedPrimary: {
        backgroundColor: COLORS.gray.accessible,
        borderColor: COLORS.orange.primary,
        color: COLORS.orange.primary,
        padding: '2rem',
        '&:hover': {
          backgroundColor: COLORS.lightOrange.light,
          boxShadow: '0px 8px 10px 1px rgba(0, 0, 0, 0.14)',
        },
        '&.Mui-disabled': {
          backgroundColor: COLORS.gray.lightest,
          color: COLORS.gray.primary,
          borderColor: 'transparent',
        },
      },
      textSecondary: {
        backgroundColor: 'transparent',
        color: COLORS.gray.primary,
        '&:hover': {
          backgroundColor: COLORS.gray.lightest,
        },
      },
    },
    MuiTextField: {
      root: {
        '& .MuiInput-underline:before': {
          borderBottom: 'none',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
          borderBottom: 'none',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: COLORS.gray.primary,
        '&.Mui-focused': {
          color: COLORS.gray.primary,
        },
      },
    },
    MuiInput: {
      root: {
        fontSize: '1.6rem',
        lineHeight: '3.2rem',
      },
    },
    MuiOutlinedInput: {
      root: {
        fontSize: '1.6rem',
        lineHeight: '3.2rem',
        height: '4.8rem',
        '& $notchedOutline': {
          borderColor: COLORS.gray.primary,
        },
        '&$focused $notchedOutline': {
          borderColor: COLORS.gray.primary,
          borderWidth: '1px',
        },
        '&.Mui-error': {
          '& $notchedOutline': {
            borderColor: COLORS.orange.error,
          },
          backgroundColor: COLORS.lightOrange.lighter,
        },
      },
      multiline: {
        height: 'auto',
        minHeight: '4.8rem',
      },
      notchedOutline: {},
    },
    MuiFormLabel: {
      root: {
        fontSize: '1.6rem',
        lineHeight: '3.2rem',
        '&.MuiInputLabel-outlined': {
          transform: 'translate(14px, 10px) scale(1)',
          '&.MuiInputLabel-marginDense': {
            transform: 'translate(14px, 9px) scale(1)',
          },
          '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -10px) scale(0.75)',
          },
          '&.Mui-error': {
            color: COLORS.gray.primary,
          },
        },
        [breakpoints.up('sm')]: {
          fontSize: '1.6rem',
          lineHeight: '3.2rem',
        },
      },
    },
    MuiBreadcrumbs: {
      separator: {
        color: COLORS.gray.light,
      },
    },
    MuiToggleButton: {
      root: {
        '&$selected': {
          backgroundColor: 'rgba(255, 98, 64, 0.1)',
          border: `1px solid ${COLORS.orange.primary}`,
          color: COLORS.navy,
          '&:hover': {
            backgroundColor: 'rgba(255, 98, 64, 0.1)',
          },
        },
        '&:hover': {
          backgroundColor: 'rgba(255, 98, 64, 0.1)',
        },
      },
    },
    MuiDivider: {
      root: {
        opacity: 0.3,
        backgroundColor: COLORS.orange.primary,
        border: `1px solid ${COLORS.orange.primary}`,
        transform: 'rotate(180deg)',
      },
    },
    MuiSnackbarContent: {
      root: {
        borderRadius: '.8rem',
        justifyContent: 'center',
        padding: '2.4rem 5rem 2.4rem 5rem',
        maxHeight: '8rem',
        [`@media screen and (max-width: 764px)`]: {
          padding: '1.4rem 3.8rem 1.5rem 3.8rem;',
          borderRadius: '.4rem',
        },
      },
      message: {
        padding: 0,
      },
    },
    MuiAlert: {
      root: {
        alignItems: 'center',
      },
    },
    MuiCard: {
      root: {
        border: `1px solid ${COLORS.gray.bg}`,
      },
    },
    MuiCardContent: {
      root: {
        padding: '0.8rem',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '1.2rem',
        backgroundColor: COLORS.orange.primary,
        boxSizing: 'border-box',
        boxShadow: '0px 0px 0px 8px rgba(255, 98, 64, 0.3)',
        borderRadius: '5px',
      },
      arrow: {
        color: COLORS.orange.primary,
      },
    },
    MuiPickersSlideTransition: {
      transitionContainer: {
        '& p': {
          lineHeight: '2.8rem',
        },
      },
    },
    MuiSwitch: {
      root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'inline-grid',
      },
      switchBase: {
        '&.MuiIconButton-root:hover': {
          border: 0,
        },
        padding: 2,
        color: COLORS.gray.light,
        '&$checked': {
          transform: 'translateX(12px)',
          color: COLORS.white,
          '& + $track': {
            opacity: 1,
            backgroundColor: COLORS.gray.darker,
            borderColor: COLORS.gray.darker,
          },
        },
      },
      thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
      },
      track: {
        border: `1px solid ${COLORS.gray.light}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: COLORS.white,
      },
      checked: {},
    },
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: COLORS.orange,
        },
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: COLORS.orange,
        },
      },
    },
  }
}
