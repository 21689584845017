import { useEffect, useState } from 'react'
import { useTheme } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteRenderOptionState,
} from '@material-ui/lab'
import SearchIcon from '@material-ui/icons/Search'
import styled from 'styled-components'
import { useMerchants } from '~/hooks/useMerchants'
import { Box } from '@material-ui/core'
import UpdateBalanceConfirmContent from './UpdateBalanceConfirmContent'
import NewTransactionConfirmContent from './NewTransactionConfirmContent'
import { NewTransactionDataType, UpdateBalanceDataType } from '~/hooks'
import { Typography } from '@mui/material'

type EditPhotoDialogProps = {
  open: boolean
  handleCancel: () => void
  handleConfirmNewTransaction: (data: UpdateBalanceDataType | NewTransactionDataType) => void
  currentBalance: number
}

type Option = {
  label?: string
  id?: string
  price?: number
}

const dentalInsuranceProducts: Option[] = [
  {
    label: 'Starter',
    id: 'Starter',
  },
  {
    label: 'Core',
    id: 'Core',
  },
  {
    label: 'Achiever',
    id: 'Achiever',
  },
  {
    label: 'Diamond',
    id: 'Diamond',
  },
]

export default function NewTransactionPrompt({
  open,
  handleCancel,
  handleConfirmNewTransaction,
  currentBalance,
}: EditPhotoDialogProps) {
  const [selectedPartner, setSelectedPartner] = useState<Option>()
  const [selectedProduct, setSelectedProduct] = useState<Option>()
  const [mode, setMode] = useState<string>('input')
  const [productNote, setProductNote] = useState<string>('')
  const [inputAmount, setInputAmount] = useState<string>('0')
  const { merchants, getMerchantDetails, selectedMerchant } = useMerchants()
  const [productPrice, setProductPrice] = useState<number>(0)
  const [validationError, setValidationError] = useState<boolean>(false)
  const theme = useTheme()

  const handlePartnerChange = (
    event: React.ChangeEvent<{}>,
    value: NonNullable<string | Option>,
    reason: AutocompleteChangeReason
  ) => {
    if (typeof value === 'object') {
      setSelectedPartner(value as Option)
      if (value.id !== 'Admin Balance Update') getMerchantDetails(value.label as string)
      setSelectedProduct({})
    }
  }

  const handleProductChange = (
    event: React.ChangeEvent<{}>,
    value: NonNullable<string | Option>,
    reason: AutocompleteChangeReason
  ) => {
    setValidationError(false)
    setSelectedProduct(value as Option)
  }

  const handleNext = () => {
    if (validateInputs()) {
      setMode('confirm')
    } else {
      setValidationError(true)
    }
  }

  const handleConfirm = () => {
    if (selectedPartner?.id === 'Admin Balance Update') {
      handleConfirmNewTransaction({
        partner: selectedPartner.id,
        productNote,
        newBalance: getNewBalance(currentBalance, inputAmount),
      })
    } else {
      handleConfirmNewTransaction({
        partner: selectedPartner?.id as string,
        product: selectedProduct?.id as string,
        price:
          selectedPartner?.label === 'Guardian Direct: Dental Insurance'
            ? productPrice
            : (selectedProduct?.price as number),
      })
    }
  }

  const handleProductNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValidationError(false)
    setProductNote(event.currentTarget.value)
  }

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValidationError(false)
    if (isValidNumber(event.currentTarget.value)) {
      setInputAmount(event.currentTarget.value)
    }
  }

  const handleProductPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValidationError(false)
    setProductPrice(+event.currentTarget.value)
  }

  function validateInputs(): boolean {
    if (selectedPartner?.id === 'Admin Balance Update') {
      return !!inputAmount && inputAmount !== '0' && productNote.trim() !== ''
    } else {
      return isProductOption(selectedProduct)
    }
  }

  function onClose(): void {
    setValidationError(false)
    clearFieldState()
    handleCancel()
  }

  function onCancel(): void {
    handleCancel()
    clearFieldState()
    setValidationError(false)
  }

  function clearFieldState(): void {
    setProductNote('')
    setProductPrice(0)
    setSelectedPartner({})
    setSelectedProduct({})
  }

  useEffect(() => {
    setMode('input')
  }, [open])

  return (
    <div>
      <StyledDialog open={open} onClose={onClose}>
        <DialogContent>
          {mode === 'input' ? (
            <>
              <StyledH1> New Transaction </StyledH1>
              <Autocomplete
                freeSolo
                disableClearable
                options={[
                  {
                    label: 'Admin Balance Update',
                    id: 'Admin Balance Update',
                  },
                  ...(merchants || []).map(option => ({
                    label: option.fields.title,
                    id: option.id,
                  })),
                ]}
                classes={{
                  option: 'option',
                }}
                getOptionLabel={(option: Option) => option.label as string}
                renderOption={(option: Option, state: AutocompleteRenderOptionState) => {
                  if (option.id === 'Admin Balance Update') {
                    return (
                      <div
                        style={{
                          borderBottom: '1px solid #FF6240',
                          width: '100%',
                        }}
                      >
                        {option.label}
                      </div>
                    )
                  } else {
                    return option.label
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Search Partners"
                    margin="normal"
                    variant="outlined"
                    color="primary"
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                      startAdornment: <SearchIcon fontSize="large" />,
                    }}
                  />
                )}
                onChange={handlePartnerChange}
              />
              {selectedPartner?.id && (
                <>
                  {selectedPartner.id === 'Admin Balance Update' ? (
                    <>
                      <StyledTextField
                        required
                        variant="outlined"
                        label="update amount"
                        InputProps={{
                          startAdornment: '$',
                        }}
                        onChange={handleAmountChange}
                        value={inputAmount}
                      />
                      <StyledTextField
                        required
                        variant="outlined"
                        label="Product Note"
                        fullWidth
                        onChange={handleProductNoteChange}
                        value={productNote}
                      />
                      {validationError ? (
                        <StyledErrorText>
                          Please fill out both the amount and product note fields
                        </StyledErrorText>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <Autocomplete
                        disableClearable
                        options={(selectedMerchant?.fields?.products || []).map(option => ({
                          label: option.fields.name,
                          id: option.id,
                          price: option.fields.listPrice,
                        }))}
                        classes={{
                          option: 'option',
                        }}
                        getOptionLabel={(option: Option) => option.label as string}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Search Products"
                            margin="normal"
                            variant="outlined"
                            color="primary"
                            InputProps={{
                              ...params.InputProps,
                              type: 'search',
                              startAdornment: <SearchIcon fontSize="large" />,
                            }}
                            onChange={() => {
                              setValidationError(false)
                            }}
                          />
                        )}
                        onChange={handleProductChange}
                      />
                      {selectedPartner.label === 'Guardian Direct: Dental Insurance' && (
                        <Box component="div" display="flex" alignItems="center">
                          <StyledTextField
                            variant="outlined"
                            label="amount"
                            InputProps={{
                              startAdornment: '$',
                            }}
                            onChange={handleProductPriceChange}
                            value={productPrice}
                            type="number"
                          />
                          <StyledText tcolor={theme.palette.text.black}>per month</StyledText>
                        </Box>
                      )}
                      {validationError ? (
                        <StyledErrorText>
                          Please fill out both the partner and product fields
                        </StyledErrorText>
                      ) : null}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {selectedPartner?.id === 'Admin Balance Update' ? (
                <UpdateBalanceConfirmContent
                  amount={Number(inputAmount)}
                  newBalance={getNewBalance(currentBalance, inputAmount)}
                  productNote={productNote}
                />
              ) : (
                <NewTransactionConfirmContent
                  partner={selectedPartner?.label as string}
                  product={selectedProduct?.label as string}
                  price={
                    selectedPartner?.label === 'Guardian Direct: Dental Insurance'
                      ? productPrice
                      : undefined
                  }
                />
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          {mode === 'input' ? (
            <StyledButton variant="contained" color="primary" onClick={handleNext}>
              Next
            </StyledButton>
          ) : (
            <StyledButton variant="contained" color="primary" onClick={handleConfirm}>
              Confirm
            </StyledButton>
          )}
          <StyledButton onClick={onCancel} variant="outlined" color="primary">
            Cancel
          </StyledButton>
        </DialogActions>
      </StyledDialog>
    </div>
  )
}

const StyledDialog = styled(Dialog)({
  '& .MuiDialogContent-root': {
    width: '425px',
    paddingTop: '42px',
    paddingLeft: '45px',
    paddingRight: '45px',
  },
  '& .MuiDialogActions-root': {
    marginTop: '16px',
    justifyContent: 'space-between',
    paddingLeft: '50px',
    paddingRight: '77px',
    paddingBottom: '42px',
  },
})

export const StyledH1 = styled.h1({
  fontFamily: "'Inter'",
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '24px',
  lineHeight: '32px',
  letterSpacing: '-0.02em',
  color: '#000000',
  marginBottom: '24px',
})

const StyledButton = styled(Button)({
  padding: '16px 42px',
})

const StyledTextField = styled(TextField)({
  marginTop: '24px',
})

const StyledText = styled(Typography)<{ tcolor: string }>(({ tcolor }) => ({
  color: tcolor,
  fontSize: '1.6rem !important',
  marginLeft: '16px !important',
  marginTop: '12px !important',
}))

const StyledErrorText = styled(Typography)({
  color: 'red',
  marginTop: '12px !important',
})

// amount utilities

function getNewBalance(currentBalance: number, inputAmount: string) {
  // limits to 2 decimal places
  return Math.round((currentBalance + parseFloat(inputAmount)) * 100) / 100
}

function isValidNumber(value: string) {
  // handle negative numbers, decimals, empty strings, and limit declimals to 2
  const re = /^-?\d*\.?\d{0,2}$/
  return value === '' || re.test(value)
}

function isProductOption(option: string | Option | undefined): option is Option {
  return typeof option === 'object' && ('label' in option || 'id' in option || 'price' in option)
}
