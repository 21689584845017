import { Palette, PaletteOptions } from '@material-ui/core/styles/createPalette'

import { useMemo } from 'react'
import { createTheme, Theme } from '@material-ui/core/styles'

import { getThemeTypographies } from './typography'
import { getThemePalette } from './palette'
import { getThemeOverrides } from './overrides'
import {
  breakpoint as screenBreakpoint,
  breakpoints as screenBreakpoints,
  ScreenBreakpoints,
} from './breakpoints'

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    palette: Palette
    headerHeight: number
  }
  interface ThemeOptions {
    palette?: PaletteOptions
    headerHeight?: number
    screenBreakpoint?: Function
    screenBreakpoints?: ScreenBreakpoints
  }
}

const createGenericTheme = (): Theme => {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1010,
        lg: 1200,
        xl: 1536,
      },
    },
    screenBreakpoints,
    screenBreakpoint,
    spacing: factor => `${factor}rem`,
    typography: getThemeTypographies(),
    palette: getThemePalette(),
    overrides: getThemeOverrides(),
  })

  return theme
}

export const useTheme = (): Theme => {
  return useMemo(() => {
    return createGenericTheme()
  }, [])
}
