import * as React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import styled from 'styled-components'

type EditNameDialogProps = {
  open: boolean
  email?: string
  name?: string
  handleNext: (value: string) => void
  handleCancel: () => void
}

export default function EditNameDialog({
  open,
  email = '',
  name = '',
  handleNext,
  handleCancel,
}: EditNameDialogProps) {
  const [value, setValue] = React.useState<string>('')

  const handleChange = (event: any) => {
    setValue(event.target.value)
  }

  React.useEffect(() => {
    setValue(email)
  }, [email])

  return (
    <div>
      <StyledDialog open={open} onClose={handleCancel}>
        <DialogContent>
          <NameText>{name}</NameText>
          <StyledH1> Edit email </StyledH1>
          <TextField
            id="email"
            fullWidth
            variant="outlined"
            value={value}
            onChange={handleChange}
            type="email"
          />
        </DialogContent>
        <DialogActions>
          <StyledButton
            onClick={() => {
              handleNext(value)
            }}
            variant="contained"
            color="primary"
          >
            Next
          </StyledButton>
          <StyledButton onClick={handleCancel} variant="outlined" color="primary">
            Cancel
          </StyledButton>
        </DialogActions>
      </StyledDialog>
    </div>
  )
}

const StyledDialog = styled(Dialog)({
  '& .MuiDialogContent-root': {
    width: '425px',
    paddingTop: '42px',
    paddingLeft: '45px',
    paddingRight: '45px',
  },
  '& .MuiDialogActions-root': {
    marginTop: '16px',
    justifyContent: 'space-between',
    paddingLeft: '50px',
    paddingRight: '77px',
    paddingBottom: '42px',
  },
})

const NameText = styled.div({
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.01em',
  color: '#585858',
})

const StyledH1 = styled.h1({
  fontFamily: "'Inter'",
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '24px',
  lineHeight: '32px',
  letterSpacing: '-0.02em',
  color: '#000000',
  marginBottom: '24px',
})

const StyledButton = styled(Button)({
  padding: '16px 42px',
})
