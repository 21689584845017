import { Grid, Drawer as MUIDrawer, DrawerProps as MUIDrawerProps } from '@mui/material'
import { ReactNode } from 'react'
import CancelIcon from '../../../public/assets/svg/cancel-icon.svg'

interface DrawerProps {
  open: boolean
  handleClose: () => void
  children: ReactNode
  id?: string
}

const Drawer = ({
  open,
  handleClose,
  anchor,
  children,
  id,
  ...props
}: DrawerProps & Pick<MUIDrawerProps, 'anchor'>) => {
  return (
    <MUIDrawer
      anchor={anchor}
      open={open}
      {...props}
      onClose={handleClose}
      classes={{
        paper: 'w-full max-w-4xl p-5 sm:w-3/4 sm:p-12',
      }}
    >
      <Grid container>
        <Grid id={id} onClick={handleClose} className="mb-4 flex w-full justify-end sm:mb-14">
          <CancelIcon className="h-4 w-6 cursor-pointer sm:h-8 sm:w-9" />
        </Grid>
        {children}
      </Grid>
    </MUIDrawer>
  )
}

export default Drawer
