import { createContext, ReactNode, useContext, useState } from 'react'
import { UserData } from '.'
import { useAuthContext } from '../provider/authProvider'
import { API } from '../utils'
import { Callbacks } from '../utils/helper'

interface Errors {
  bio?: Error
  image?: Error
}

type UseBioType = {
  saveBio: (data: BioUpdateProps, callbacks?: Callbacks) => Promise<void>
  bioSettingsLoader: boolean
}

const BioContext = createContext<UseBioType | undefined>(undefined)

interface MemberProviderProps {
  children: ReactNode
}

type BioUpdateProps = Pick<UserData, 'biography' | 'location' | 'displayName'> & {
  image?: string
  bioUpdated?: boolean
}

export const BioProvider = ({ children }: MemberProviderProps) => {
  const [bioSettingsLoader, setBioSettingsLoader] = useState(false)

  const { doPut, doPost } = useAuthContext()

  const saveBio = async (
    { biography, location, displayName, image, bioUpdated = false }: BioUpdateProps,
    callbacks?: Callbacks<UserData>
  ) => {
    const errors: Errors = {}
    let updatedUserData

    if (!bioUpdated) {
      typeof callbacks?.success === 'function' && callbacks.success()
      typeof callbacks?.finally === 'function' && callbacks.finally()
      return
    }

    setBioSettingsLoader(true)
    try {
      const bioResponse = await doPut<UserData>(
        API.UPDATE_USER_PROFILE,
        {
          biography,
          location,
          displayName,
        },
        callbacks
      )

      if (!!bioResponse) updatedUserData = bioResponse
    } catch (err) {
      console.log(`API BIO Error | ${JSON.stringify(err)}`)
      errors.bio = err as Error
    }

    if (!!image) {
      try {
        const imageResponse = await doPost<UserData>(API.PROFILE_PICTURE, { image }, callbacks)

        if (!!imageResponse) updatedUserData = imageResponse
      } catch (err) {
        errors.image = err as Error
        console.log(`API IMAGE Error ${JSON.stringify(err)}`)
      }
    }
    setBioSettingsLoader(false)
  }

  const value = {
    saveBio,
    bioSettingsLoader,
  }

  return <BioContext.Provider value={value}>{children}</BioContext.Provider>
}

export const useBio = () => {
  const ctx = useContext(BioContext)
  if (!ctx) {
    throw new Error('You are using Bio out of context.')
  }
  return ctx
}
