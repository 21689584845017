import { useIntl } from 'react-intl'

export type TranslateFn = (
  key: string | Error,
  values?: Record<string, string>,
  defaultMessageId?: string
) => string

/**
 * useTranslation hook
 * @param baseKey - the base key to use for all translations with the t function returned
 */
export const useTranslation = (baseKey?: string): TranslateFn => {
  const { formatMessage } = useIntl()
  return (
    key: string | Error,
    values?: Record<string, string>,
    defaultMessageId?: string
  ): string => {
    let defaultMessage: string | undefined = undefined
    if (defaultMessageId) {
      const id = baseKey ? `${baseKey}.${defaultMessageId}` : defaultMessageId
      defaultMessage = formatMessage({ id })
    }
    if (typeof key === 'string') {
      const id = baseKey ? `${baseKey}.${key}` : key
      return formatMessage({ id, defaultMessage }, values)
    } else {
      const error = key
      const code = error.code || 'unknown'
      // error type
      if (!defaultMessageId) {
        defaultMessage = formatMessage({ id: 'webSharedErrors.unknown' })
      }
      const id = baseKey ? `${baseKey}.errors.${code}` : `errors.${code}`
      console.log(`%c ${id}`, 'background: #333; color: #FF0000')
      return formatMessage({ id, defaultMessage }, values)
    }
  }
}
