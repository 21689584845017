import { createContext, ReactNode, useCallback, useContext, useState } from 'react'
import auth0, { DbSignUpOptions } from 'auth0-js'
import axios from 'axios'
import { Callbacks, manageResponse, manageError } from '../utils'

export type SignUpFn = (
  opts: {
    firstName: string
    lastName: string
    email: string
    password: string
    phone: string
    ageRange: string
  },
  callbacks?: Callbacks
) => void
export type ResetPasswordFn = (email: string) => Promise<void>
export type PostFn = <T>(
  endpoint: string,
  body?: unknown,
  callbacks?: Callbacks
) => Promise<T | undefined>
export type PutFn = <T>(
  endpoint: string,
  body?: unknown,
  callbacks?: Callbacks
) => Promise<T | undefined>
export type GetFn = <T>(endpoint: string, callbacks?: Callbacks) => Promise<T | undefined>
export type DeleteFn = <T>(endpoint: string, callbacks?: Callbacks) => Promise<T | undefined>

export type AuthContextData = {
  resetPassword: ResetPasswordFn
  loading: boolean
  signUp: SignUpFn
  jwt?: string
  doPost: PostFn
  doPut: PutFn
  doGet: GetFn
  doDelete: DeleteFn
}

const AuthContext = createContext<AuthContextData | undefined>(undefined)

const auth0Client = new auth0.WebAuth({
  domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN || '',
  clientID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || '',
  responseType: 'token id_token',
  audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE || '',
})

const connection = process.env.NEXT_PUBLIC_AUTH0_DBCONNECTION || ''

const SERVER_API_URL = process.env.NEXT_PUBLIC_SERVER_API_URL || ''

export const useAuthContext = (): AuthContextData => {
  const ctx = useContext(AuthContext)
  return ctx as AuthContextData
}

interface AuthProviderProps {
  children: ReactNode
}

type SetHeaderType = {
  headers:
    | {
        'Content-Type': string
      }
    | {
        'Content-Type': string
        Authorization: string
      }
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [loading, setLoading] = useState(true)

  const setHeader = (): SetHeaderType => {
    return {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  }

  const doPost = useCallback(async function <T>(
    endpoint: string,
    body?: unknown,
    callbacks?: Callbacks
  ) {
    try {
      const result = await axios
        .post(`${SERVER_API_URL}${endpoint}`, JSON.stringify(body), setHeader())
        .then(response => manageResponse(response, callbacks))
      return result as T
    } catch (error) {
      manageError(error, callbacks)
    } finally {
      if (callbacks?.finally != null) callbacks.finally()
    }
  },
  [])

  const doPut = useCallback(async function <T>(
    endpoint: string,
    body?: unknown,
    callbacks?: Callbacks
  ) {
    try {
      const result = await axios
        .put(`${SERVER_API_URL}${endpoint}`, JSON.stringify(body), setHeader())
        .then(response => manageResponse(response, callbacks))
      return result as T
    } catch (error) {
      manageError(error, callbacks)
    } finally {
      if (callbacks?.finally != null) callbacks.finally()
    }
  },
  [])

  const doGet = useCallback(async function <T>(endpoint: string, callbacks?: Callbacks) {
    try {
      const result = await axios
        .get(`${SERVER_API_URL}${endpoint}`, setHeader())
        .then(response => manageResponse(response))
      return result as T
    } catch (error) {
      manageError(error, callbacks)
    } finally {
      if (callbacks?.finally != null) callbacks.finally()
      setLoading(false)
    }
  }, [])

  const doDelete = useCallback(async function <T>(endpoint: string, callbacks?: Callbacks) {
    try {
      const result = await axios
        .delete(`${SERVER_API_URL}${endpoint}`, setHeader())
        .then(response => manageResponse(response))
      return result as T
    } catch (error) {
      manageError(error, callbacks)
    } finally {
      if (callbacks?.finally != null) callbacks.finally()
      setLoading(false)
    }
  }, [])

  const signUp: SignUpFn = (
    { email, password, firstName, lastName, phone, ageRange },
    callbacks
  ) => {
    const payload: DbSignUpOptions = {
      email: email,
      password: password,
      connection,
      scope: 'openid profile email',
      userMetadata: {
        firstName: firstName,
        lastName: lastName,
        telephone: phone,
        ageRange: ageRange,
      },
    }

    auth0Client.signup(payload, (err, _res) => {
      if (err && callbacks?.error) {
        callbacks.error(String(err))
      } else if (callbacks?.success) {
        callbacks.success()
      }
      if (callbacks?.finally) {
        callbacks.finally()
      }
    })
  }

  const resetPassword = useCallback(email => {
    return new Promise<void>((cb, cbErr) => {
      auth0Client.changePassword({ email, connection }, error => {
        // noop
        if (error) {
          cbErr(error)
        } else {
          cb()
        }
      })
    })
  }, [])

  const value = {
    loading,
    signUp,
    doPost,
    doPut,
    doGet,
    doDelete,
    resetPassword,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthProvider
