export const ROUTES = {
  HOME: '/',
  UNBOUNCE_HOME: '/',
  SIGN_UP: '/sign-up',
  LOGIN: '/sign-in',
  FORGOT_PASSWORD: '/forgot-password',
  FOUNDS: '/founds',
  ACCOUNT: '/account',
  AUTHORIZE: '/authorize',
  LOGOUT: '/logout',
  SUPPORT: '/support-a-member',
  ABOUT: '/about-us',
  CONTACT: 'mailto:support@guardian-well.com',
  TERMS: '/terms-and-conditions',
  UNBOUNCE_TERMS: '/terms-and-conditions',
  POLICY: '/privacy-policy',
  UNBOUNCE_POLICY: '/privacy-policy',
  PAYMENT_COMFIRMATION: '/payment-confirmation',
  DISABLED_ACCOUNT: '/disabled-account',
  PROFILE_URL: (id: string) => `/profile/${id}`,
  MARKETPLACE: '/shop',
  MARKETPLACE_UNBOUNCE: '/marketplace-unbounce',
  INTERNAL_SERVER: '/500',
  NOT_FOUND: '/404',
  SHARE_PROFILE_URL: (id: string) => `/profile/${id}/share`,
  PAYMENT: (id: string) => `/profile/${id}/payment`,
  FAQ: '/faq',
  MERCHANTS_REF: '/merchants/:name',
  MERCHANTS: '/merchants/',
  ADMIN_PORTAL: '/admin/members',
  ADMIN_REPORTS: '/admin/reports',
  ADMIN_USERS: '/admin/users',
  ADMIN_COUPONS: '/admin/coupons',
  ADMIN_LEADS: '/admin/leads',
  ADMIN_GRAIL: '/admin/grail',
  ADMIN_PROFILE_SETTINGS: '/admin/profile-settings',
  TRANSACTIONS: (id: string) => `/admin/transactions/${id}`,
  ADD_FUNDS: '/add-funds',
  MEMBER: '/member',
}

export const API = {
  CREATE_STRIPE_ACCOUNT: '/api/stripe/create-account',
  INITIALIZE_STRIPE_PAYMENT: '/api/stripe/initialize-payment',
  INITIALIZE_STRIPE_PAYMENT_GUEST_USER: '/api/proxy/stripe/initialize-payment/guest-user',
  INITIALIZE_STRIPE_PAYMENT_MARKETPLACE: '/api/stripe/initialize-payment/marketplace',
  GET_STRIPE_RECEIPT: '/api/stripe/receipt/',
  STRIPE_WEBHOOK: '/api/stripe/webhook',
  CREATE_PROFILE_NAME: '/api/users/sign-up/generate-profile-name',
  FORGOT_PASSWORD: '/api/users/public/forgot-password',
  GET_USER_BY_PROFILE_NAME: '/api/users/public/profile-name/',
  UPDATE_USER_PROFILE: '/api/users/update',
  DELETE_USER_PROFILE: '/api/users',
  DELETE_USER_PROFILE_NEXT: '/api/users/delete',
  GET_USER_PROFILE: '/api/users/profile',
  ENABLE_USER_PROFILE: '/api/users/enable',
  GET_DONATIONS_BY_TRANSACTION_ID: (id: string) => `/api/proxy/marketplace/donator/${id}`,
  GET_RECENT_GIVERS: (page: number) => `/api/ledger/movements/earnings/${page}`,
  GET_RECENT_TRANSACTIONS: (page: number) => `/api/ledger/movements/${page}`,
  GET_PENDING_TRANSACTIONS_BY_ID: (id: string, page: number | string) =>
    `/api/ledger/admin/${page}?userID=${id}`,
  UPDATE_TRANSACTION_STATUS: (id: string) => `/api/ledger/admin/${id}`,
  UPDATE_TRANSACTION_AMOUNT: (id: string) => `/api/ledger/admin/${id}/amount`,
  BALANCE: (userId: string) => `/api/ledger/admin/${userId}/balance`,
  TRANSACTIONS: (userId: string) => `/api/ledger/admin/${userId}/transactions`,
  RESEND_CONFIRMATION: (id: string) => `/api/ledger/admin/${id}/resend`,
  GET_PENDING_TRANSACTIONS: (page: number) => `/api/users/admin/with-pendings/${page}`,
  GET_MEMBERS: (page: number) => `/api/users/admin/with-members/${page}`,
  GET_ALL_MEMBERS: `/api/users/admin/with-all-members`,
  GET_TRANSACTIONS: (page: number) => `/api/users/admin/with-transactions/${page}`,
  GET_LEADS: (page: number) => `/api/marketplace/product-notification/${page}`,
  GET_ALL_TRANSACTIONS: `/api/users/admin/with-all-transactions`,
  GET_ADMIN_USERS: (page: number) => `/api/users/admin/list/${page}`,
  LIST_FACTORS: `/api/users/factors`,
  ENROLL_FACTOR: `/api/users/factors`,
  ACTIVATE_FACTOR: (factorId: string) => `/api/users/factors/${factorId}/verify`,
  RESET_FACTOR: (factorId: string) => `/api/users/factors/${factorId}`,
  RESEND_PASSCODE: (factorId: string) => `/api/users/factors/${factorId}/resend`,
  CHANGE_PASSWORD: '/api/users/change-password',
  RESEND_CODE: '/api/users/send-verification-email',
  CONTACT_US: '/api/public/contact-form',
  SIGN_UP: '/api/users/sign-up',
  BUY_PRODUCT: `/api/proxy/marketplace/buy/`,
  PROFILE_PICTURE: '/api/users/profile-picture',
  USER_ADMIN: (id: string) => `/api/users/admin/${id}`,
  USER_ADMIN_AVATAR_UPDATE: (id: string) => `/api/users/admin/${id}/avatar-update`,
  USER_ADMIN_REACTIVATE: (id: string) => `/api/users/admin/${id}/reactivate`,
  USER_ADMIN_NEXT: '/api/users/admin',
  USER_ACCOUNT_ACTIVATION: '/api/users/sign-up/activate-account',
  GET_POLL_ANSWERS: '/api/poll/answers/for-marketplace',
  SEND_VOTES_POLL: '/api/poll/answers/vote',
  GET_LATEST_ANSWERS: '/api/poll/votes/latest-for-marketplace',
  GET_PAYMENT_METHOD_LIST: '/api/stripe/paymentcards',
  CREATE_PAYMENT_METHOD: '/api/stripe/paymentcard',
  UPDATE_PAYMENT_METHOD: (id: string) => `/api/stripe/paymentcard/${id}`,
  REMOVE_PAYMENT_METHOD: (id: string) => `/api/stripe/paymentcard/${id}`,
  SEND_PURCHASE_CONFIRMATION_WITH_GIFT: (id: string) =>
    `/api/ledger/admin/${id}/purchase-confirmation`,
  GET_COUPONS: '/api/stripe/coupons',
  GET_COUPONS_PAGE: (pageParams: any) => `/api/stripe/coupons/${pageParams.page}`,
  GET_COUPON: (id?: string) => `/api/stripe/coupon${id ? '/' + id : ''}`,
  GET_LEADS_PAGE: (pageParams: any) =>
    `/api/marketplace/product-notification/${pageParams.page}${
      pageParams.query ? '?query=' + pageParams.query : ''
    }`,
  DELETE_LEAD: (id?: string) => `/api/proxy/marketplace/product-notification/${id}`,
  GET_GRAIL_GALLERI_KEYS_PAGE: (pageParams: any) =>
    `/api/proxy/galleri-keys/${pageParams.page}${
      pageParams.query ? '?query=' + pageParams.query : ''
    }`,
  GET_GRAIL_GALLERI_KEYS_ALL_PAGE: () => `/api/proxy/galleri-keys/all`,
  DELETE_GRAIL_GALLERI_KEYS: (id?: string) => `/api/proxy/galleri-keys/${id}`,
  GET_PRODUCTS: '/api/stripe/products',
  GET_PROMO_CODE: (id: string) => `/api/stripe/promotion_codes/${id}`,
  CREATE_PRODUCT_NOTIFICATION: '/api/proxy/marketplace/product-notification',
  GET_GALLERI_KEY: (email: string) => `/api/proxy/galleri-keys/by-email-or-new?email=${email}`,
  PUT_GALLERI_KEY: (id: string) => `api/proxy/galleri-keys/${id}`,
}

export const ADMIN_ID = '4c830694-3fde-485b-a98c-09c0b790fb16'

export const MOVEMENT_TYPE = {
  CLEMENTINE_INCENTIVE: '3a030736-0655-4af6-abcb-581b28f6fd4c',
  DONATION: '9e573018-e7ed-4560-824c-36b8ca102a95',
  PAYMENT_PENDING: 'f81ab483-866f-4685-a97c-72cf8d2c07c5',
  PAYMENT_FULFILLED: 'a243e71c-1f6c-47ee-80c5-4206ab7406fa',
  PAYMENT_CANCELED: '930be7be-e348-4895-baa1-2e112601e8b5',
  REFUND: 'e348b8f6-a26b-4567-979f-5b49d7e26b60',
}

export const NOTIFICATION_TYPE = {
  GUARDIAN_DIRECT_DENTAL_INSURANCE: 'guardian direct dental insurance',
  GALLERI_TEST_AVAILABLE: 'galleri test available',
}

export enum MOVEMENT_TYPE_ENUM {
  PAYMENT_PENDING = 'f81ab483-866f-4685-a97c-72cf8d2c07c5',
  PAYMENT_FULFILLED = 'a243e71c-1f6c-47ee-80c5-4206ab7406fa',
  PAYMENT_CANCELED = '930be7be-e348-4895-baa1-2e112601e8b5',
}

export const MOVEMENT_TO_STATUS: Record<MOVEMENT_TYPE_ENUM, string> = {
  [MOVEMENT_TYPE_ENUM.PAYMENT_PENDING]: 'Pending',
  [MOVEMENT_TYPE_ENUM.PAYMENT_FULFILLED]: 'Confirmed',
  [MOVEMENT_TYPE_ENUM.PAYMENT_CANCELED]: 'Cancelled',
}

export enum LOCAL_STORAGE_KEYS {
  IS_FIRST_LOGIN = 'CLEMENTINE_FIRST_LOGIN',
  LAST_LOGIN_AT = 'CLEMENTINE_LAST_LOGIN_AT',
  GET_STARTED = 'CLEMENTINE_GET_STARTED',
  SHOW_WELCOME_NOTIFICATION = 'CLEM_SHOW_WELCOME',
  EDIT_PROFILE = 'EDIT_PROFILE',
  EXCHANGE_REFERRAL = 'EXCHANGE_REFERRAL',
  REFERRAL_SOURCE = 'REFERRAL_SOURCE',
}

export const DEFAULT_REFERRAL_SOURCE = 'Direct'

export const REFERRAL_SOURCE_MAP: Record<string, string> = {
  grail_gc: 'GRAIL_GUARDIAN_CUSTOMER',
  grail_nc: 'GRAIL_GUARDIAN_NONCUSTOMER',
  dentalexchange: 'DENTALEXCHANGE',
  guardiandirect: 'GUARDIANDIRECT',
}
export const REFERRAL_SOURCES = ['Dental Exchange', 'GRAIL Guardian Customer', 'Guardian Direct']
/*
  All Contentful pageType goes here.
  Article: GW articles,Blogs that has banner image and content.
  Static: GW static pages
*/
//
export const PAGE_TYPES = {
  ARTICLE: 'article',
  STATIC: 'static',
  PAGE: 'page',
}

export const CONTENT_ENTRIES = {
  GLOBAL_ARTICLE_FOOTER: '7s7GcBkiCQMrvxhkEhGV8C',
  GLOBAL_UNAUTHENTICATED_FOOTER: 'XRRVjsnKRSj44lqODbYFo',
  GLOBAL_AUTHENTICATED_FOOTER: 'VqmWbyV6qmibGFURzsYRY',
}

export const CATEGORY_MAP: Record<string, string> = {
  body: 'Healthy Body',
  mind: 'Healthy Mind',
  wallet: 'Financial Fitness',
}
