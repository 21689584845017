import { createClient } from 'contentful'

const cntfSpace = process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID || ''
const cntfToken = process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN || ''
const cntfHost = process.env.NEXT_PUBLIC_CONTENTFUL_HOST || ''

export const client = createClient({
  // This is the space ID. A space is like a project folder in Contentful terms
  space: cntfSpace,
  // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
  accessToken: cntfToken,
  // This is the host api url, for the lower env's we use the Preview api and for prod we use the Delivery cdn api.
  host: cntfHost,
})
