// imports the React Javascript Library
import React, { ChangeEvent } from 'react'
//Card
import CardActionArea from '@material-ui/core/CardActionArea'

import Fab from '@material-ui/core/Fab'
import Grid from '@material-ui/core/Grid'

import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined'

//Tabs

type ImageUploadCardProps = {
  selectFile: (file: File | null) => void
}

type ImageUploadCardState = {
  mainState: string // initial, search, gallery, uploaded
  imageUploaded: number
  selectedFile: File | null | (String | ArrayBuffer | null)[]
}
class ImageUploadCard extends React.Component<ImageUploadCardProps, ImageUploadCardState> {
  constructor(props: any) {
    super(props)
  }

  state = {
    mainState: 'initial', // initial, search, gallery, uploaded
    imageUploaded: 0,
    selectedFile: null,
  }

  handleUploadClick = (event: ChangeEvent<HTMLInputElement>) => {
    var file = event?.target?.files?.[0]
    const reader = new FileReader()
    var url = reader.readAsDataURL(file as Blob)

    reader.onloadend = () => {
      this.setState({
        selectedFile: [reader.result],
      })
    }

    console.log(url) // Would see a path?

    this.setState({
      mainState: 'uploaded',
      selectedFile: event?.target?.files?.[0] as File,
      imageUploaded: 1,
    })
    this.props.selectFile(file as File)
  }

  renderInitialState() {
    return (
      <React.Fragment>
        <Grid>
          <input
            accept="image/*"
            id="contained-button-file"
            multiple
            type="file"
            onChange={this.handleUploadClick}
            style={{ display: 'none' }}
          />
          <label htmlFor="contained-button-file">
            <Fab
              component="span"
              size="large"
              style={{
                color: 'white',
                margin: 10,
                backgroundColor: '#a9a9a9',
                width: '112px',
                height: '112px',
              }}
            >
              <PhotoCameraOutlinedIcon fontSize="large" />
            </Fab>
          </label>
        </Grid>
      </React.Fragment>
    )
  }

  renderUploadedState() {
    return (
      <React.Fragment>
        <CardActionArea onClick={this.imageResetHandler}>
          <img width="112" src={this.state.selectedFile || ''} />
        </CardActionArea>
      </React.Fragment>
    )
  }

  imageResetHandler = () => {
    this.setState({
      mainState: 'initial',
      selectedFile: null,
      imageUploaded: 0,
    })
    this.props.selectFile(null)
  }

  render() {
    return (
      <React.Fragment>
        <div>
          {(this.state.mainState == 'initial' && this.renderInitialState()) ||
            (this.state.mainState == 'uploaded' && this.renderUploadedState())}
        </div>
      </React.Fragment>
    )
  }
}

export default ImageUploadCard
