import { createContext, ReactNode, useCallback, useContext, useState } from 'react'
import { EntryCollection } from 'contentful'
import { client } from '../services/contentfulService'
import { Document, BLOCKS } from '@contentful/rich-text-types'

interface IStaticPagesProviderProps {
  children: ReactNode
}

export interface StaticPageFields {
  title: string
  description: Document
  disclaimerTitle?: string
  disclaimerDescription?: Document
  seoTitle?: string
  seoDescription?: string
  seoCanonical?: string
}

export interface StaticPagesType {
  fields: StaticPageFields
  assets?: Array<any>
  id?: string
}

type UseStaticPagesType = {
  getPageContent: (pageName: string) => void
  pageContent: StaticPagesType | undefined
}

const StaticPagesContext = createContext<UseStaticPagesType | undefined>(undefined)

export const StaticPagesProvider = ({ children }: IStaticPagesProviderProps) => {
  const [pageContent, setPageContent] = useState<StaticPagesType>()

  const getPageContent = useCallback(async (pageName: string) => {
    const response: EntryCollection<any> = await client.getEntries({
      content_type: 'staticPage',
      'fields.pageName': pageName,
    })
    parsePageContent(response.items[0])
  }, [])

  const parsePageContent = (element: any) => {
    let newPageContent: StaticPagesType = {
      fields: {
        title: '',
        description: {
          nodeType: BLOCKS.DOCUMENT,
          data: {},
          content: [],
        },
        disclaimerTitle: '',
        disclaimerDescription: {
          nodeType: BLOCKS.DOCUMENT,
          data: {},
          content: [],
        },
        seoTitle: '',
        seoDescription: '',
        seoCanonical: '',
      },
    }
    newPageContent.fields = element.fields
    newPageContent.id = element.sys.id

    setPageContent(newPageContent)
  }

  const value = {
    getPageContent,
    pageContent,
  }

  return <StaticPagesContext.Provider value={value}>{children}</StaticPagesContext.Provider>
}

export const useStaticPages = () => {
  const ctx = useContext(StaticPagesContext)
  if (!ctx) {
    throw new Error('useStaticPages out of context')
  }
  return ctx
}
