export const COLORS = {
  white: '#FFFFFF',
  navy: '#0D3F5E',
  gray: {
    accessible: '#FDFDFD',
    new: '#BCBCBC',
    bg: '#FCFCFC',
    primary: '#777777',
    light: '#B0B0B0',
    lighter: '#F5F5F5',
    lightest: '#ECECEC',
    secondary: '#4F4F4F',
    darker: '#0E0E0E',
    mid: '#A9A9A9',
    lightestHome: '#f8f8f8a6',
    dark: '#D3D3D3',
    radioBg: '#F9F9F9',
  },
  orange: {
    border: '#FFC0B3',
    primary: '#E85526',
    darker: '#FB471F',
    avatar: '#FEA17F',
    error: '#F24E2D',
    borderHome: '#FF8F76',
  },
  lightOrange: {
    primary: '#FFECE0',
    darker: '#FFD3B8',
    light: '#FFF6F0',
    lighter: '#FFEFEC',
    lightest: '#FFF9F7',
    aboutUs: '#FCEFE8',
    lightestHome: '#FDECE4',
  },
  green: '#0F9D58',
  upvoting: {
    yellow: '#FFCA24',
    orange: '#FA7D54',
    violet: '#BF74A7',
    pink: '#EF4268',
    green: '#BCD631',
    skyblue: '#80CCED',
    purple: '#8B76DF',
  },
}
