import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import axios from 'axios'
import { TABLE_KIND } from '~/components/Admin/AdminTable/TableHeader'
import { useAuthContext } from '../provider/authProvider'
import { API } from '../utils'
import { Callbacks, createAuthor, Dispatchable } from '../utils/helper'

export type GetAdminType = {
  users: Pending[]
  total: number
}

export type GetMembersAdminType = {
  users: Members[]
  total: number
}

export type GetTransactionAdminType = {
  users: Transactions[]
  total: number
}

export type GetLeadAdminType = {
  result: Leads[]
  total: number
}

export type GetUsersAdminType = {
  users: User[]
  total: number
}

export type User = {
  email: string
  firstName: string
  lastName: string
  telephone?: string
  id: string
  active: string
  balance: number | null
}

export type GetPendingsById = {
  movements: Movement[]
  total: number
  authorFirstName: string
  authorLastName: string
  authorEmail: string
  authorId: string
  authorAgeRange: string
}

export type Movement = {
  id: string
  createdAt: string
  amount: number
  giftCode?: string
  type?: {
    description: string
    id: string
  }
  order?: {
    contentfulProductName: string
    contentfulMerchantName: string
  }
  movementTypeId?: string
  donator?: {
    email: string
    firstName: string
    lastName: string
  }
}

type Pending = {
  id: string
  email: string
  firstName: string
  lastName: string
  telephone?: string
  profileName: string
  displayName: string | null
  location: string | null
  biography: string | null
  profilePictureURL: string | null
  balance: number
  pendingPaymentsCount: number
  referralSource: string | null
  oktaId: string
  active: boolean
  createdAt: string
}

type Members = {
  id: string
  email: string
  firstName: string
  lastName: string
  balance: number
  purchasePaymentsCount: number
  referralSource: string
  createdAt: string
}

type Transactions = {
  id: string
  buyer: {
    firstName: string
    lastName: string
  }
  firstName: string
  lastName: string
  contentfulMerchantName: string
  contentfulProductName: string
  movement: {
    amount: number
    giftCode: string
    createdAt: string
  }
  amount: number
  giftCode: string
  createdAt: string
}

type Leads = {
  id: string
  email: string
  campaign: string
  createdAt: string
}

type GrailGalleriKeys = {
  id: string
  email: string
  key: string
  createdAt: string
}

type PendingsParams = {
  page: number
  query?: string
  sort?: {
    field: SortField
    direction: 'asc' | 'desc'
  }
}

type MembersParams = {
  page: number
  query?: string
  sort?: {
    field: SortField
    direction: 'asc' | 'desc'
  }
}

type ReportTransactionParams = {
  page: number
  query?: string
  sort?: {
    field: SortField
    direction: 'asc' | 'desc'
  }
}

type LeadParams = {
  page: number
  query?: string
  sort?: {
    field: SortField
    direction: 'asc' | 'desc'
  }
}

type UsersParams = {
  page: number
  query?: string
  currentUserId: string
}

type TransactionsParams = {
  page: number
  id: string
  query?: string
  sort?: {
    field: SortField
    direction: 'asc' | 'desc'
  }
}

export type PendingDisplayableRow = Pick<
  Pending,
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'balance'
  | 'pendingPaymentsCount'
  | 'referralSource'
  | 'id'
  | 'createdAt'
>

export type UserDisplayableRow = Pick<
  User,
  'firstName' | 'lastName' | 'email' | 'active' | 'id' | 'telephone'
>

export type MembersDisplayableRow = Pick<
  Members,
  'firstName' | 'lastName' | 'email' | 'balance' | 'purchasePaymentsCount' | 'referralSource' | 'id'
>

export type TransactionDisplayableRow = Pick<
  Transactions,
  | 'firstName'
  | 'lastName'
  | 'contentfulMerchantName'
  | 'contentfulProductName'
  | 'amount'
  | 'giftCode'
  | 'id'
>

export type LeadsDisplayRow = Pick<Leads, 'createdAt' | 'email' | 'campaign' | 'id'>

export type GrailGalleriKeysDisplayRow = Pick<
  GrailGalleriKeys,
  'createdAt' | 'email' | 'key' | 'id'
>

export type KeyOfPendingDisplayableRow = keyof PendingDisplayableRow

export type KeyOfUserDisplayableRow = keyof UserDisplayableRow

export type KeyOfMemberDisplayableRow = keyof MembersDisplayableRow

export type MovementDisplayableRow = Pick<
  Movement & {
    productName?: string
    merchantName?: string
    partnerName?: string | JSX.Element
    statusId: string
  },
  'createdAt' | 'merchantName' | 'productName' | 'id' | 'statusId' | 'giftCode' | 'partnerName'
> & { amount: number | string }

export type KeyOfMovementDisplayableRow = keyof MovementDisplayableRow

type UpdateStatusPutResponse = {
  id: string
  movementTypeId: string
}

export type UpdateTransactionStatusFn = (
  id: string,
  movementTypeId: string,
  callbacks?: Callbacks
) => Promise<unknown>

export type SortField = keyof Omit<PendingDisplayableRow, 'id'> | 'date' | 'amount' | ''
export type SortFieldMember = keyof Omit<MembersDisplayableRow, 'id'> | ''

export type PendingHeaders = 'firstName' | 'lastName' | 'email' | 'balance' | 'pendings' | 'actions'

type UseAdminType = {
  pendings: Pending[]
  setPendings: Dispatchable<Pending[]>
  getPendingsPage: (params: PendingsParams, callbacks?: Callbacks) => Promise<void>
  members: Members[]
  setMembers: Dispatchable<Members[]>
  getAllMembersPage: (callbacks?: Callbacks) => Promise<any>
  getAllTransactionPage: (callbacks?: Callbacks) => Promise<any>
  getMembersPage: (params: MembersParams, body?: any, callbacks?: Callbacks) => Promise<void>
  getTransactionPage: (
    params: ReportTransactionParams,
    body?: any,
    callbacks?: Callbacks
  ) => Promise<void>
  getLeadPage: (params: LeadParams, callbacks?: Callbacks) => Promise<void>
  getUsersListPage: (params: UsersParams, callbacks?: Callbacks) => Promise<void>
  deleteUser: (id: string, callbacks?: Callbacks) => Promise<void>
  activateUser: (id: string, callbacks?: Callbacks) => Promise<void>
  updateUser: (
    user: Pick<UserDisplayableRow, 'id' | 'firstName' | 'lastName' | 'email'>,
    callbacks?: Callbacks
  ) => Promise<void>
  updateUserAvatar: (id: string, formData: FormData, callbacks?: Callbacks) => Promise<void>
  addUser: (user: UserDisplayableRow, callbacks?: Callbacks) => Promise<void>
  usersList: User[]
  setUsersList: Dispatchable<User[]>
  pendingActivePage: PendingDisplayableRow[]
  membersActivePage: MembersDisplayableRow[]
  transactionActivePage: TransactionDisplayableRow[]
  leadActivePage: LeadsDisplayRow[]
  usersListActivePage: UserDisplayableRow[]
  currentPage: number
  setCurrentPage: Dispatchable<number>
  allRows: number
  setAllRows: Dispatchable<number>
  setAllTransactionRows: Dispatchable<number>
  query: string
  setQuery: Dispatchable<string>
  totalPages: number
  totalTransactionPages: number
  transactions: Movement[]
  setTransactions: Dispatchable<Movement[]>
  getTransactionsById: (params: TransactionsParams, callbacks?: Callbacks) => Promise<void>
  author?: Author
  setAuthor: Dispatchable<Author | undefined>
  sortField: SortField
  setSortField: Dispatchable<SortField>
  sortDirection: 'asc' | 'desc'
  setSortDirection: Dispatchable<'asc' | 'desc'>
  updateTransactionStatus: UpdateTransactionStatusFn
  handleSort: (header: SortField, tableType: TABLE_KIND, id?: string, query?: string) => void
  updateAdminBalance: (userId: string, data: UpdateBalanceDataType) => Promise<void>
  addNewTransaction: (userId: string, data: NewTransactionDataType) => Promise<void>
}

const AdminContext = createContext<UseAdminType | undefined>(undefined)

interface MemberProviderProps {
  children: ReactNode
}

export const getPendingsQuery = (params: PendingsParams, connector = '?') => {
  if (params.sort && params.query) {
    return `${connector}sort=${params.sort.field}_${
      params.sort.direction
    }&query=${encodeURIComponent(params.query)}`
  } else if (params.sort && !params.query) {
    return `${connector}sort=${params.sort.field}_${params.sort.direction}`
  } else if (!params.sort && params.query) {
    return `${connector}query=${encodeURIComponent(params.query)}`
  } else {
    return ''
  }
}

export const getMembersQuery = (params: MembersParams, connector = '?') => {
  if (params.sort && params.sort.field && params.query) {
    return `${connector}sort=${params.sort.field}_${
      params.sort.direction
    }&query=${encodeURIComponent(params.query)}`
  } else if (params.sort && params.sort.field && !params.query) {
    return `${connector}sort=${params.sort.field}_${params.sort.direction}`
  } else if (!params.sort && params.query) {
    return `${connector}query=${encodeURIComponent(params.query)}`
  } else {
    return `${connector}sort=email_desc`
  }
}

export const getTransactionQuery = (params: ReportTransactionParams, connector = '?') => {
  if (params.query) {
    return `${connector}query=${encodeURIComponent(params.query)}`
  } else {
    return ''
  }
}

export const getLeadQuery = (params: LeadParams, connector = '?') => {
  if (params.query) {
    return `${connector}email=${encodeURIComponent(params.query)}`
  } else {
    return ''
  }
}

export interface Author {
  authorFullname: string
  authorEmail: string
  authorId: string
  authorAvatar: string
  authorAgeRange?: string
}

export type UpdateBalanceDataType = {
  partner: 'Admin Balance Update'
  newBalance: number
  productNote: string
}

export type NewTransactionDataType = {
  partner: string
  product: string
  price?: number
}

const ITEMS_PER_PAGE = 50
export const AdminProvider = ({ children }: MemberProviderProps) => {
  const [pendings, setPendings] = useState<Pending[]>([])
  const [members, setMembers] = useState<Members[]>([])
  const [reportTransaction, setReportTransaction] = useState<Transactions[]>([])
  const [leadTransaction, setLeadTransaction] = useState<Leads[]>([])
  const [usersList, setUsersList] = useState<User[]>([])
  const [transactions, setTransactions] = useState<Movement[]>([])
  const [allTransactionRows, setAllTransactionRows] = useState<number>(0)

  const [currentPage, setCurrentPage] = useState(1)
  const [query, setQuery] = useState('')
  const [author, setAuthor] = useState<Author>()
  const [allRows, setAllRows] = useState(0)
  const [sortField, setSortField] = useState<SortField>('')
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc')
  const { doGet, doPut, doDelete, doPost } = useAuthContext()

  const getPendingsPage = useCallback(
    (params: PendingsParams, callbacks?: Callbacks) =>
      doGet<GetAdminType>(`${API.GET_PENDING_TRANSACTIONS(params.page)}${getPendingsQuery(params)}`)
        .then(response => {
          if (response) {
            setPendings(response.users)
            setAllRows(response.total)
          }
          if (callbacks?.success) callbacks.success()
        })
        .catch(error => {
          if (callbacks?.error) callbacks.error(String(error))
        })
        .finally(() => {
          if (callbacks?.finally) callbacks.finally()
        }),
    [doGet]
  )

  const getMembersPage = useCallback(
    (params: MembersParams, body?: any, callbacks?: Callbacks) =>
      doPost<GetMembersAdminType>(
        `${API.GET_MEMBERS(params.page)}${getMembersQuery(params)}`,
        body || {}
      )
        .then(response => {
          if (response) {
            setMembers(response.users)
            setAllRows(response.total)
          }
          if (callbacks?.success) callbacks.success()
        })
        .catch(error => {
          if (callbacks?.error) callbacks.error(String(error))
        })
        .finally(() => {
          if (callbacks?.finally) callbacks.finally()
        }),
    [doPost]
  )

  const getAllMembersPage = async () =>
    doPost<GetMembersAdminType>(`${API.GET_ALL_MEMBERS}`, {}).then(
      response => response?.users || []
    )

  const getTransactionPage = useCallback(
    (params: ReportTransactionParams, body?: any, callbacks?: Callbacks) =>
      doPost<GetTransactionAdminType>(
        `${API.GET_TRANSACTIONS(params.page)}${getTransactionQuery(params)}`,
        body || {}
      )
        .then(response => {
          if (response) {
            setReportTransaction(response.users)
            setAllTransactionRows(response.total)
          }
          if (callbacks?.success) callbacks.success()
        })
        .catch(error => {
          if (callbacks?.error) callbacks.error(String(error))
        })
        .finally(() => {
          if (callbacks?.finally) callbacks.finally()
        }),
    [doPost]
  )

  const getLeadPage = useCallback(
    (params: LeadParams, callbacks?: Callbacks) =>
      doGet<GetLeadAdminType>(`${API.GET_LEADS(params.page)}${getLeadQuery(params)}`)
        .then(response => {
          if (response) {
            setLeadTransaction(response.result)
            setAllTransactionRows(response.total)
          }
          if (callbacks?.success) callbacks.success()
        })
        .catch(error => {
          if (callbacks?.error) callbacks.error(String(error))
        })
        .finally(() => {
          if (callbacks?.finally) callbacks.finally()
        }),
    [doGet]
  )

  const getAllTransactionPage = async () =>
    doPost<GetTransactionAdminType>(`${API.GET_ALL_TRANSACTIONS}`, {}).then(
      response => response?.users || []
    )

  const getUsersListPage = useCallback(
    (params: UsersParams, callbacks?: Callbacks) =>
      doGet<GetUsersAdminType>(
        `${API.GET_ADMIN_USERS(params.page)}${
          params.query ? `?query=${encodeURIComponent(params.query)}` : ''
        }`
      )
        .then(response => {
          if (response) {
            setUsersList(response.users.filter(userList => userList.id !== params.currentUserId))
          }
          if (callbacks?.success) callbacks.success()
        })
        .catch(error => {
          if (callbacks?.error) callbacks.error(String(error))
        })
        .finally(() => {
          if (callbacks?.finally) callbacks.finally()
        }),
    [doGet]
  )

  const deleteUser = useCallback(
    (id: string, callbacks?: Callbacks) =>
      doDelete(`${API.USER_ADMIN(id)}`)
        .then(response => {
          if (callbacks?.success) callbacks.success()
        })
        .catch(error => {
          if (callbacks?.error) callbacks.error(String(error))
        })
        .finally(() => {
          if (callbacks?.finally) callbacks.finally()
        }),
    [doDelete]
  )

  const activateUser = useCallback(
    (id: string, callbacks?: Callbacks) =>
      doPost(`${API.USER_ADMIN_REACTIVATE(id)}`)
        .then(response => {
          if (callbacks?.success) callbacks.success()
        })
        .catch(error => {
          if (callbacks?.error) callbacks.error(String(error))
        })
        .finally(() => {
          if (callbacks?.finally) callbacks.finally()
        }),
    [doPost]
  )

  const updateUser = useCallback(
    (
      user: Pick<UserDisplayableRow, 'id' | 'firstName' | 'lastName' | 'email'>,
      callbacks?: Callbacks
    ) =>
      doPut(`${API.USER_ADMIN(user.id)}`, {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      })
        .then(() => {
          if (callbacks?.success) callbacks.success()
        })
        .catch(error => {
          if (callbacks?.error) callbacks.error(String(error))
        })
        .finally(() => {
          if (callbacks?.finally) callbacks.finally()
        }),
    [doPut]
  )

  const updateUserAvatar = useCallback(
    (id: string, formData: FormData, callbacks?: Callbacks) =>
      axios
        .put(`${API.USER_ADMIN_AVATAR_UPDATE(id)}`, formData, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        })
        .then(() => {
          if (callbacks?.success) callbacks.success()
        })
        .catch(error => {
          if (callbacks?.error) callbacks.error(String(error))
        })
        .finally(() => {
          if (callbacks?.finally) callbacks.finally()
        }),
    []
  )

  const addUser = useCallback(
    (user: UserDisplayableRow, callbacks?: Callbacks) =>
      doPost(API.USER_ADMIN_NEXT, {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        active: user.active === 'YES',
      })
        .then(() => {
          if (callbacks?.success) callbacks.success()
        })
        .catch(error => {
          if (callbacks?.error) callbacks.error(String(error))
        })
        .finally(() => {
          if (callbacks?.finally) callbacks.finally()
        }),
    [doPost]
  )

  const getTransactionsById = useCallback(
    (params: TransactionsParams, callbacks?: Callbacks) =>
      doGet<GetPendingsById>(
        `${API.GET_PENDING_TRANSACTIONS_BY_ID(params.id, params.page)}${getPendingsQuery(
          params,
          '&'
        )}`
      )
        .then(response => {
          if (response) {
            const { movements, total, authorEmail, authorFirstName, authorLastName, authorId } =
              response
            const newTxs = movements.filter(
              record => !transactions.some(transaction => transaction.id === record.id)
            )
            setTransactions(newTxs)
            setAllTransactionRows(total)
            const author = createAuthor({ authorFirstName, authorLastName, authorEmail, authorId })
            setAuthor(author)
          }
          callbacks?.success && callbacks.success()
        })
        .catch(error => {
          callbacks?.error && callbacks.error(String(error))
        })
        .finally(() => {
          callbacks?.finally && callbacks.finally()
        }),
    [doGet]
  )

  const updateAdminBalance = useCallback(
    async (userId: string, data: UpdateBalanceDataType) => {
      try {
        await doPut(API.BALANCE(userId), data)
      } catch (err) {
        console.log(`Error happened from an api to update admin balance: ${JSON.stringify(err)}`)
      }
    },
    [doPut]
  )

  const addNewTransaction = useCallback(
    async (userId: string, data: NewTransactionDataType) => {
      try {
        await doPost(API.TRANSACTIONS(userId), data)
      } catch (err) {
        console.log(`Error happened from an api to update admin balance: ${JSON.stringify(err)}`)
      }
    },
    [doPost]
  )

  const handleSort = useCallback(
    (header: SortField, tableType: TABLE_KIND, id?: string, query?: string) => {
      if (header !== '') {
        if (header === sortField) {
          setSortDirection(ctx => (ctx === 'asc' ? 'desc' : 'asc'))
          setCurrentPage(1)
          tableType === TABLE_KIND.PENDINGS &&
            getPendingsPage({
              page: 1,
              query,
              sort: {
                direction: sortDirection === 'asc' ? 'desc' : 'asc',
                field: header,
              },
            })
          tableType === TABLE_KIND.MEMBERS &&
            getMembersPage({
              page: 1,
              query,
              sort: {
                direction: sortDirection === 'asc' ? 'desc' : 'asc',
                field: header,
              },
            })
          tableType === TABLE_KIND.TRANSACTIONS &&
            getTransactionPage({
              page: 1,
              query,
              sort: {
                direction: sortDirection === 'asc' ? 'desc' : 'asc',
                field: header,
              },
            })
          tableType === TABLE_KIND.MOVEMENTS &&
            getTransactionsById(
              {
                page: 1,
                query,
                id: id + '',
                sort: {
                  direction: sortDirection === 'asc' ? 'desc' : 'asc',
                  field: header,
                },
              },
              { finally: () => {} }
            )
          tableType === TABLE_KIND.LEADS &&
            getLeadPage({
              page: 1,
              query,
              sort: {
                direction: sortDirection === 'asc' ? 'desc' : 'asc',
                field: header,
              },
            })
        } else {
          setSortField(header)
          setSortDirection('asc')
          setCurrentPage(1)
          tableType === TABLE_KIND.PENDINGS &&
            getPendingsPage({
              page: 1,
              query,
              sort: {
                direction: 'asc',
                field: header,
              },
            })
          tableType === TABLE_KIND.MEMBERS &&
            getMembersPage({
              page: 1,
              query,
              sort: {
                direction: 'asc',
                field: header,
              },
            })
          tableType === TABLE_KIND.TRANSACTIONS &&
            getTransactionPage({
              page: 1,
              query,
              sort: {
                direction: 'asc',
                field: header,
              },
            })
          tableType === TABLE_KIND.LEADS &&
            getLeadPage({
              page: 1,
              query,
              sort: {
                direction: 'asc',
                field: header,
              },
            })
          tableType === TABLE_KIND.MOVEMENTS &&
            getTransactionsById(
              {
                id: id + '',
                page: 1,
                query,
                sort: {
                  direction: sortDirection === 'asc' ? 'desc' : 'asc',
                  field: header,
                },
              },
              { finally: () => {} }
            )
        }
      }
    },
    [
      sortField,
      getPendingsPage,
      sortDirection,
      getTransactionsById,
      getMembersPage,
      getTransactionPage,
      getLeadPage,
    ]
  )

  const updateTransactionStatus = useCallback(
    (id: string, movementTypeId: string, callbacks?: Callbacks) =>
      doPut<UpdateStatusPutResponse>(
        API.UPDATE_TRANSACTION_STATUS(id),
        { movementTypeId },
        callbacks
      )
        .then(response => {
          if (response) {
            const newTransactions = transactions.map(transaction =>
              transaction.id === response.id
                ? { ...transaction, statusId: response.movementTypeId }
                : transaction
            )
            setTransactions(newTransactions)
          }
          if (callbacks?.success) callbacks.success()
        })
        .catch(_error => {
          if (callbacks?.error) callbacks.error()
        })
        .finally(() => {
          if (callbacks?.finally) callbacks.finally()
        }),
    [doPut, setTransactions, transactions]
  )

  const pendingActivePage = useMemo<PendingDisplayableRow[]>(
    () =>
      pendings.map(pending => ({
        createdAt: pending.createdAt,
        firstName: pending.firstName,
        lastName: pending.lastName,
        email: pending.email,
        balance: pending.balance,
        pendingPaymentsCount: pending.pendingPaymentsCount,
        referralSource: pending.referralSource,
        id: pending.id,
      })),
    [pendings]
  )

  const membersActivePage = useMemo<MembersDisplayableRow[]>(
    () =>
      members.map(member => ({
        createdAt: member.createdAt,
        firstName: member.firstName,
        lastName: member.lastName,
        email: member.email,
        balance: member.balance,
        purchasePaymentsCount: member.purchasePaymentsCount,
        referralSource: member.referralSource,
        id: member.id,
      })),
    [members]
  )

  const transactionActivePage = useMemo<TransactionDisplayableRow[]>(
    () =>
      reportTransaction.map(element => ({
        createdAt: element.movement?.createdAt,
        firstName: element.buyer?.firstName || '',
        lastName: element.buyer?.lastName || '',
        contentfulMerchantName: element.contentfulMerchantName,
        contentfulProductName: element.contentfulProductName,
        amount: element.movement?.amount,
        giftCode: element.movement?.giftCode || '',
        id: element.id,
      })),
    [reportTransaction]
  )

  const leadActivePage = useMemo<LeadsDisplayRow[]>(
    () =>
      leadTransaction.map(element => ({
        createdAt: element?.createdAt,
        email: element?.email || '',
        campaign: element?.campaign || '',
        id: element.id,
      })),
    [leadTransaction]
  )

  const usersListActivePage = useMemo<UserDisplayableRow[]>(
    () =>
      usersList
        .map(user => ({
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          active: user.active ? 'YES' : 'NO',
          id: user.id,
        }))
        .filter(
          (_user, index) =>
            index >= ITEMS_PER_PAGE * (currentPage - 1) && ITEMS_PER_PAGE * currentPage > index
        ),
    [currentPage, usersList]
  )

  const totalPages = useMemo(() => Math.ceil(allRows / ITEMS_PER_PAGE), [allRows])
  const totalTransactionPages = useMemo(
    () => Math.ceil(allTransactionRows / ITEMS_PER_PAGE),
    [allTransactionRows]
  )

  const value = {
    pendings,
    members,
    setPendings,
    setMembers,
    usersList,
    getUsersListPage,
    setUsersList,
    getPendingsPage,
    getAllMembersPage,
    getAllTransactionPage,
    getMembersPage,
    getTransactionPage,
    getLeadPage,
    deleteUser,
    activateUser,
    updateUser,
    addUser,
    currentPage,
    setCurrentPage,
    pendingActivePage,
    membersActivePage,
    transactionActivePage,
    leadActivePage,
    usersListActivePage,
    allRows,
    setAllRows,
    allTransactionRows,
    setAllTransactionRows,
    totalPages,
    totalTransactionPages,
    query,
    setQuery,
    transactions,
    getTransactionsById,
    setTransactions,
    author,
    setAuthor,
    updateTransactionStatus,
    sortField,
    setSortField,
    sortDirection,
    setSortDirection,
    handleSort,
    updateUserAvatar,
    updateAdminBalance,
    addNewTransaction,
  }

  return <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
}

export const useAdmin = () => {
  const ctx = useContext(AdminContext)
  if (!ctx) {
    throw new Error('You are using Admin out of context.')
  }
  return ctx
}
