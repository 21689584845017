import { Box } from '@material-ui/core'
import React from 'react'
import { StyledH1 } from './NewTransactionPrompt'

type UpdateBalanceConfirmContentProps = {
  amount: number
  newBalance: number
  productNote: string
}
export default function UpdateBalanceConfirmContent({
  amount,
  newBalance,
  productNote,
}: UpdateBalanceConfirmContentProps) {
  return (
    <>
      <StyledH1>Update balance</StyledH1>
      <Box component="div" fontSize="16px" marginTop="20px" lineHeight="20px" color="#00131A">
        <Box component="div" marginBottom="10px" fontWeight="500">
          AMOUNT
        </Box>
        <span>
          {amount > 0 ? '+' : amount < 0 ? '-' : ''}${Math.abs(amount)}
        </span>
      </Box>
      <Box component="div" fontSize="16px" marginTop="20px" lineHeight="20px" color="#00131A">
        <Box component="div" marginBottom="10px" fontWeight="500">
          NEW BALANCE
        </Box>
        <span>
          {newBalance < 0 ? '-' : ''}${Math.abs(newBalance)}
        </span>
      </Box>
      <Box component="div" fontSize="16px" marginTop="20px" lineHeight="20px" color="#00131A">
        <Box component="div" marginBottom="10px" fontWeight="500">
          PRODUCT NOTE
        </Box>
        <Box component="span" fontStyle="italic">
          {productNote}
        </Box>
      </Box>
    </>
  )
}
