import {
  Box,
  TableHead,
  Button,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Avatar as MuiAvatar,
  Popover,
  Radio,
  RadioGroup,
  TableCell,
  TableRow,
  TextField,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core'

import { Theme } from '@material-ui/core/styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import CloseIcon from 'public/assets/svg/orange-close-icon.svg'
import SearchIcon from 'public/assets/svg/search-icon.svg'
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import T from '~/components/T'
import {
  NewTransactionDataType,
  SortField,
  UpdateBalanceDataType,
  useAdmin,
  useResolution,
  useTranslation,
  useUser,
} from '~/hooks'
import {
  ColorProps,
  Dispatchable,
  ResolutionProps,
  createAuthor,
  getGap,
  getSortField,
  stringAvatar,
} from '~/utils'
import { TYPOGRAPHY_VARIANTS } from '../../../../theme/typography'
import AdditionalFilterDrawer, { FilterData } from '../AdditionalFilterDrawer'
import EditEmailDialog from './EditEmailDialog'
import EditNameDialog from './EditNameDialog'
import EditPhotoDialog from './EditPhotoDialog/EditPhotoDialog'
import NewTransactionPrompt from './NewTransactionPrompt'

export enum TABLE_KIND {
  PENDINGS = 'pendings',
  MOVEMENTS = 'movements',
  MEMBERS = 'members',
  REPORT_TRANSACTIONS = 'reportTransactions',
  TRANSACTIONS = 'transactions',
  USERS = 'USERS',
  LEADS = 'leads',
  GRAIL = 'grail',
}

function getAdvanceFilterText(stateName: string) {
  switch (stateName) {
    case 'signUpDate':
      return 'Sign-Up Date'
    case 'signUpStartAndEndDate':
      return 'Sign-Up Date'
    case 'balanceNoMin':
      return 'Balance'
    case 'balanceNoMax':
      return 'Balance'
    case 'balance':
      return 'Balance'
    case 'purchaseNoMin':
      return 'Purchases'
    case 'purchaseNoMax':
      return 'Purchases'
    case 'purchase':
      return 'Purchases'
    case 'ageRange':
      return 'Age Range'
    case 'referralSource':
      return 'Referral Source'

    case 'transactionDate':
      return 'Transaction Date'
    case 'transactionStartAndEndDate':
      return 'Transaction Date'
    case 'partners':
      return 'Partners'
    case 'coupon':
      return 'Coupons'
  }
}

interface TableHeaderProps {
  headers: string[]
  kind: TABLE_KIND
  setQuery?: Dispatchable<string>
  userId?: string
  additionalFilter?: boolean
  handleFilterSave?: any
}

function TableHeader({
  headers,
  setQuery,
  kind,
  userId,
  additionalFilter,
  handleFilterSave,
}: TableHeaderProps) {
  const {
    getPendingsPage,
    getMembersPage,
    getUsersListPage,
    sortDirection,
    sortField,
    handleSort,
    author,
    getTransactionPage,
    pendings,
    updateUser,
    setAuthor,
    updateUserAvatar,
    updateAdminBalance,
    addNewTransaction,
    getTransactionsById,
    getLeadPage,
  } = useAdmin()
  const [_query, _setQuery] = useState('')
  const t = useTranslation('admin')
  const theme = useTheme()
  const isPendingsOrMovements = [TABLE_KIND.PENDINGS, TABLE_KIND.MOVEMENTS].includes(kind)
  const isMembers = [TABLE_KIND.MEMBERS].includes(kind)
  const isTransactions = [TABLE_KIND.REPORT_TRANSACTIONS].includes(kind)
  const isLeads = [TABLE_KIND.LEADS].includes(kind)
  const isGrail = [TABLE_KIND.GRAIL].includes(kind)
  const [advanceFilter, setAdvanceFilter] = useState<FilterData | any>({ filterType: 'Members' })
  const [isEditingName, setIsEditingName] = useState<boolean>(false)
  const [isEditingEmail, setIsEditingEmail] = useState<boolean>(false)
  const [isEditingPhoto, setIsEditingPhoto] = useState<boolean>(false)
  const [showNewTransactionPrompt, setShowNewTransactionPrompt] = useState<boolean>(false)

  const handleKeydown = ({ code }: KeyboardEvent<HTMLDivElement>) => {
    if (code !== 'Enter') {
      return
    }

    isPendingsOrMovements
      ? getPendingsPage({
          query: _query,
          page: 1,
          sort: sortField
            ? {
                field: sortField,
                direction: sortDirection,
              }
            : undefined,
        })
      : isMembers
      ? getMembersPage({
          query: _query,
          page: 1,
          sort: sortField
            ? {
                field: sortField,
                direction: sortDirection,
              }
            : undefined,
        })
      : isTransactions
      ? getTransactionPage({
          query: _query,
          page: 1,
          sort: sortField
            ? {
                field: sortField,
                direction: sortDirection,
              }
            : undefined,
        })
      : isLeads
      ? getLeadPage({
          query: _query,
          page: 1,
          sort: sortField
            ? {
                field: sortField,
                direction: sortDirection,
              }
            : undefined,
        })
      : getUsersListPage({ query: _query, page: 1, currentUserId: userId || '' })
  }

  const handleIconButtonClick = () => {
    if (!setQuery) {
      return
    }

    _setQuery('')
    setQuery('')
    isPendingsOrMovements
      ? getPendingsPage({
          query: '',
          page: 1,
          sort: sortField
            ? {
                field: sortField,
                direction: sortDirection,
              }
            : undefined,
        })
      : isMembers
      ? getMembersPage({
          query: '',
          page: 1,
          sort: sortField
            ? {
                field: sortField,
                direction: sortDirection,
              }
            : undefined,
        })
      : isTransactions
      ? getTransactionPage({
          query: '',
          page: 1,
          sort: sortField
            ? {
                field: sortField,
                direction: sortDirection,
              }
            : undefined,
        })
      : isLeads
      ? getLeadPage({
          query: '',
          page: 1,
          sort: sortField
            ? {
                field: sortField,
                direction: sortDirection,
              }
            : undefined,
        })
      : getUsersListPage({ query: '', page: 1, currentUserId: userId || '' })
  }

  const handleChangeTextField = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    _setQuery(value)
    setQuery && setQuery(value)
  }

  const handleClickSort = (sortFieldValue: SortField) => () => {
    handleSort(sortFieldValue, kind, userId, _query)
  }

  const handleAdditionalFilterSave = (filterData: FilterData) => {
    setOpenDrawer(false)
    setAdvanceFilter(filterData)
    handleFilterSave(filterData)
  }

  const conditionCheck = (advanceFilterKey: string, advanceFilter: FilterData | any) => {
    if (advanceFilterKey === 'signUpDate' && advanceFilter['signUpStartAndEndDate'] === undefined) {
      return true
    } else if (
      (advanceFilterKey === 'signUpDate' ||
        advanceFilterKey === 'signUpStartDate' ||
        advanceFilterKey === 'signUpEndDate') &&
      advanceFilter['signUpStartAndEndDate'] !== undefined
    ) {
      return false
    } else if (
      advanceFilterKey === 'transactionDate' &&
      advanceFilter['transactionStartAndEndDate'] === undefined
    ) {
      return true
    } else if (
      (advanceFilterKey === 'transactionDate' ||
        advanceFilterKey === 'transactionStartDate' ||
        advanceFilterKey === 'transactionEndDate') &&
      advanceFilter['transactionStartAndEndDate'] !== undefined
    ) {
      return false
    } else if (
      advanceFilterKey !== 'filterType' &&
      advanceFilterKey !== 'balanceNoMin' &&
      advanceFilterKey !== 'balanceNoMax' &&
      advanceFilterKey !== 'purchaseNoMin' &&
      advanceFilterKey !== 'purchaseNoMax'
    ) {
      return true
    }
  }

  const handleDeleteAdvanceFilter = (advanceFilterKey: string) => {
    var newAdvanceFilter = Object.assign({}, advanceFilter)
    if (advanceFilterKey === 'balance') {
      delete newAdvanceFilter.balanceNoMin
      delete newAdvanceFilter.balanceNoMax
    } else if (advanceFilterKey === 'purchase') {
      delete newAdvanceFilter.purchaseNoMin
      delete newAdvanceFilter.purchaseNoMax
    } else if (advanceFilterKey === 'signUpStartAndEndDate') {
      delete newAdvanceFilter.signUpDate
      delete newAdvanceFilter.signUpStartDate
      delete newAdvanceFilter.signUpEndDate
    } else if (advanceFilterKey === 'transactionStartAndEndDate') {
      delete newAdvanceFilter.transactionDate
      delete newAdvanceFilter.transactionStartDate
      delete newAdvanceFilter.transactionEndDate
    }
    delete newAdvanceFilter[advanceFilterKey]
    setAdvanceFilter(newAdvanceFilter)
    handleFilterSave(newAdvanceFilter)
  }

  const { isXs } = useResolution()
  const [drawerTitleId, setDrawerTitleId] = useState<string>('')
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const classes = useStyles()
  const { firstName, lastName } = useUser()
  useEffect(() => {
    if (!!author) {
      getPendingsPage({
        page: 1,
        query: author?.authorEmail,
      })
    }
  }, [author, getPendingsPage])

  const [menuAnchorElement, setMenuAnchorElement] = useState<any>(null)
  const handleMenuOpen = (event: any) => setMenuAnchorElement(event.currentTarget)
  const handleMenuClose = () => setMenuAnchorElement(null)

  const showEditEmailModal = () => {
    setMenuAnchorElement(null)
    setIsEditingEmail(true)
  }

  const closeEditEmailModal = () => {
    setIsEditingEmail(false)
  }

  const showEditNameModal = () => {
    setMenuAnchorElement(null)
    setIsEditingName(true)
  }

  const closeEditNameModal = () => {
    setIsEditingName(false)
  }

  const showEditPhotoModal = () => {
    setMenuAnchorElement(null)
    setIsEditingPhoto(true)
  }

  const closeEditPhotoModal = () => {
    setIsEditingPhoto(false)
  }

  const updateName = (name: string) => {
    if (author && author.authorEmail) {
      const [firstName, lastName] = name.split(' ')
      updateUser(
        {
          id: author.authorId,
          firstName,
          lastName,
          email: author.authorEmail,
        },
        {
          success: () => {
            let _author = createAuthor({
              authorFirstName: firstName,
              authorLastName: lastName,
              authorEmail: author.authorEmail,
              authorId: author.authorId,
              authorAvatar: author.authorAvatar,
              authorAgeRange: author.authorAgeRange,
            })
            return setAuthor(_author)
          },
        }
      )
    }
    closeEditNameModal()
  }

  const updateEmail = (email: string) => {
    if (author) {
      const [firstName, lastName] = author.authorFullname.split(' ')
      updateUser(
        {
          id: author.authorId,
          firstName,
          lastName,
          email,
        },
        {
          success: () => {
            let _author = createAuthor({
              authorFirstName: firstName,
              authorLastName: lastName,
              authorEmail: email,
              authorId: author.authorId,
              authorAvatar: author.authorAvatar,
              authorAgeRange: author.authorAgeRange,
            })
            return setAuthor(_author)
          },
        }
      )
    }
    closeEditEmailModal()
  }

  const updatePhoto = (file: File) => {
    if (author) {
      const [firstName, lastName] = author.authorFullname.split(' ')
      const formData = new FormData()
      formData.append('file', file)

      updateUserAvatar(author?.authorId, formData, {
        success: (response: any) => {
          let _author = createAuthor({
            authorFirstName: firstName,
            authorLastName: lastName,
            authorEmail: author.authorEmail,
            authorId: author.authorId,
            authorAvatar: author.authorAvatar,
            authorAgeRange: author.authorAgeRange,
          })
          return setAuthor(_author)
        },
      })
    }
    closeEditNameModal()
  }

  const exportPage = () => {}

  const handleClickNewTransaction = () => {
    setShowNewTransactionPrompt(true)
  }

  const closeNewTransactionPrompt = () => {
    setShowNewTransactionPrompt(false)
  }

  const handleConfirmNewTransaction = async (
    data: UpdateBalanceDataType | NewTransactionDataType
  ): Promise<void> => {
    if (data.partner === 'Admin Balance Update') {
      // Update admin balance update
      await updateAdminBalance(userId as string, data as UpdateBalanceDataType)
    } else {
      // add new transaction
      await addNewTransaction(userId as string, data as NewTransactionDataType)
    }
    await getTransactionsById({
      id: userId as string,
      page: 1,
      sort: {
        field: 'date',
        direction: 'desc',
      },
    })
    closeNewTransactionPrompt()
  }
  return (
    <TableHead>
      {!!setQuery ? (
        <>
          {additionalFilter && (
            <TableCell scope="col" colSpan={headers.length}>
              <div style={{ display: 'flex' }}>
                <T
                  id={'admin.additional.filter.results.by'}
                  className="text-lg font-semibold leading-10 tracking-tighter"
                  variant={TYPOGRAPHY_VARIANTS.H3}
                />
                <FilterResultsBy
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="Members"
                  value={advanceFilter?.filterType}
                  onChange={e => {
                    setAdvanceFilter({ filterType: e.target.value })
                    handleFilterSave({ filterType: e.target.value })
                  }}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label="Members"
                    value="Members"
                    name="Members"
                    style={{ marginLeft: '5px', marginTop: '-10px' }}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Transactions"
                    value="Transactions"
                    name="Transactions"
                    style={{ marginTop: '-10px' }}
                  />
                </FilterResultsBy>
              </div>
            </TableCell>
          )}
          <SearchContainer>
            <TableCell
              role="cell"
              scope="col"
              colSpan={additionalFilter ? headers.length - 3 : headers.length}
            >
              <StyledTableCell>
                <StyledTextField
                  fullWidth
                  tcolor={theme.palette.primary.main}
                  variant="filled"
                  placeholder={t('pendings.header.search')}
                  value={_query}
                  onChange={handleChangeTextField}
                  onKeyDown={handleKeydown}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <StyledInputAdornment position="end">
                        <StyledIconButton disabled={_query === ''} onClick={handleIconButtonClick}>
                          <CloseIcon />
                        </StyledIconButton>
                      </StyledInputAdornment>
                    ),
                  }}
                />
              </StyledTableCell>
            </TableCell>
            {additionalFilter && (
              <TableCell scope="col" colSpan={3}>
                <StyledTableCell>
                  <AddButton
                    ismobile={String(isXs)}
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => {
                      setDrawerTitleId('admin.additional.filter.your.results')
                      setOpenDrawer(true)
                    }}
                  >
                    <ButtonT
                      id="admin.reports.additional.filters"
                      ismobile={String(isXs)}
                      variant={TYPOGRAPHY_VARIANTS.XS_TEXT_2}
                    />
                  </AddButton>
                </StyledTableCell>
              </TableCell>
            )}
          </SearchContainer>
          {additionalFilter && TABLE_KIND.MEMBERS && (
            <>
              <TableCell scope="col" colSpan={headers.length}>
                <div className={classes.root}>
                  <div style={{ display: 'flex' }}>
                    <T
                      className="text-lg font-semibold leading-10 tracking-tighter"
                      id={'admin.reports.additional.results.for'}
                      variant={TYPOGRAPHY_VARIANTS.H3}
                    />
                    {advanceFilter && Object.keys(advanceFilter)?.length > 0 ? (
                      Object.keys(advanceFilter).map(advanceFilterKey => {
                        if (advanceFilterKey === 'filterType') {
                          return (
                            <Chip
                              key={advanceFilterKey}
                              variant="outlined"
                              label={advanceFilter[advanceFilterKey]}
                              style={{ marginLeft: '10px' }}
                            />
                          )
                        } else if (conditionCheck(advanceFilterKey, advanceFilter)) {
                          return (
                            <Chip
                              key={advanceFilterKey}
                              variant="outlined"
                              label={
                                getAdvanceFilterText(advanceFilterKey) +
                                ': ' +
                                advanceFilter[advanceFilterKey]
                              }
                              style={{ marginLeft: '10px' }}
                              onDelete={() => {
                                handleDeleteAdvanceFilter(advanceFilterKey)
                              }}
                            />
                          )
                        }
                      })
                    ) : (
                      <Chip
                        variant="outlined"
                        label={t('reports.additional.members')}
                        style={{ marginLeft: '10px' }}
                      />
                    )}
                  </div>
                </div>
              </TableCell>
            </>
          )}
          <AdditionalFilterDrawer
            open={openDrawer}
            handleClose={() => {
              setOpenDrawer(false)
            }}
            titleId={drawerTitleId}
            onSave={handleAdditionalFilterSave}
            advanceFilter={advanceFilter}
          />
        </>
      ) : isLeads || isGrail ? (
        <></>
      ) : (
        <SearchContainer style={{ position: 'relative' }}>
          <Box position="absolute" display="flex" top="0" right="0">
            <ExportButton color="primary" variant="outlined" onClick={handleClickNewTransaction}>
              New Transaction
            </ExportButton>
            <ExportButton color="primary" variant="contained" onClick={exportPage}>
              Export Page
            </ExportButton>
          </Box>
          <StyledTableCell>
            <StyledAvatar
              textColor={theme.palette.text.black}
              background={theme.palette.background.orangeGradient}
            >
              {stringAvatar(firstName, lastName)}
            </StyledAvatar>
            <div style={{ width: '100%' }}>
              <StyledTableCell>
                <AuthorName>{author?.authorFullname}</AuthorName>
                <StyledIconButton
                  aria-label="Edit Menu"
                  aria-controls="admin-user-menu"
                  aria-haspopup="true"
                  onClick={handleMenuOpen}
                  color="primary"
                  size="medium"
                >
                  <MoreHorizIcon aria-hidden="true" fontSize="large" />
                </StyledIconButton>
                <StyledPopover
                  id="admin-user-menu"
                  anchorEl={menuAnchorElement}
                  keepMounted
                  open={Boolean(menuAnchorElement)}
                  onClose={handleMenuClose}
                  transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top',
                  }}
                  anchorReference="anchorEl"
                  anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                  }}
                  borderColor={theme.palette.primary.main}
                  backgroundColor={theme.palette.background.accessibleWhite}
                  boxShadowColor={theme.palette.background.lightOrange}
                >
                  <StyledMenuItem
                    onClick={showEditNameModal}
                    textColor={theme.palette.text.black}
                    hoverBgColor={theme.palette.background.lightOrange}
                  >
                    <T id="admin.user.management.edit.name" />
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={showEditEmailModal}
                    textColor={theme.palette.text.black}
                    hoverBgColor={theme.palette.background.lightOrange}
                  >
                    <T id="admin.user.management.edit.email" />
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={showEditPhotoModal}
                    textColor={theme.palette.text.black}
                    hoverBgColor={theme.palette.background.lightOrange}
                  >
                    <T id="admin.user.management.edit.photo" />
                  </StyledMenuItem>
                </StyledPopover>
                <EditNameDialog
                  open={isEditingName}
                  name={author?.authorFullname}
                  handleCancel={closeEditNameModal}
                  handleNext={updateName}
                />
                <EditEmailDialog
                  open={isEditingEmail}
                  email={author?.authorEmail}
                  name={author?.authorFullname}
                  handleCancel={closeEditEmailModal}
                  handleNext={updateEmail}
                />
                <EditPhotoDialog
                  open={isEditingPhoto}
                  name={author?.authorFullname}
                  handleCancel={closeEditPhotoModal}
                  handleNext={updatePhoto}
                />
                <NewTransactionPrompt
                  open={showNewTransactionPrompt}
                  handleCancel={closeNewTransactionPrompt}
                  handleConfirmNewTransaction={handleConfirmNewTransaction}
                  currentBalance={pendings[0] ? pendings[0]?.balance : 0}
                />
              </StyledTableCell>
              <Grid container justifyContent="flex-start">
                <Grid item xs={5}>
                  <AttributeLabel tcolor={theme.palette.text.black}>
                    <strong>EMAIL</strong>
                  </AttributeLabel>
                </Grid>
                <Grid item>
                  <AttributeLabel tcolor={theme.palette.text.black}>
                    {author?.authorEmail}
                  </AttributeLabel>
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-start">
                <Grid item xs={5}>
                  <AttributeLabel tcolor={theme.palette.text.black}>
                    <strong>AGE RANGE</strong>
                  </AttributeLabel>
                </Grid>
                <Grid item>
                  <AttributeLabel tcolor={theme.palette.text.black}>
                    {author?.authorAgeRange}
                  </AttributeLabel>
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-start">
                <Grid item xs={5}>
                  <AttributeLabel tcolor={theme.palette.text.black}>
                    <strong>ACCOUNT</strong>
                  </AttributeLabel>
                </Grid>
                <Grid item>
                  <AttributeLabel tcolor={theme.palette.text.black}>Active</AttributeLabel>
                </Grid>
              </Grid>
            </div>
          </StyledTableCell>
          <AttributeLabel tcolor={theme.palette.text.black}>
            <strong>TOTAL BALANCE</strong>
          </AttributeLabel>
          <BalanceText>{pendings[0] ? '$' + pendings[0]?.balance : 0}</BalanceText>
        </SearchContainer>
      )}
      <StyledTableRow color={theme.palette.primary.main}>
        {headers.map(header => {
          const sortFieldValue = getSortField(
            header.replace('pendings.', '').replace('transactions.', '').replace('reports.', '') as
              | SortField
              | TABLE_KIND.PENDINGS
          )

          return isPendingsOrMovements || isMembers ? (
            <SorteableTableCell
              key={header}
              align="left"
              nopointer={String(sortFieldValue === '')}
              onClick={handleClickSort(sortFieldValue)}
            >
              <SortContainer color={theme.palette.primary.main}>
                <T id={`admin.${header}`} className="uppercase" />
                {sortField &&
                  sortField === sortFieldValue &&
                  (sortDirection === 'desc' ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />)}
              </SortContainer>
            </SorteableTableCell>
          ) : (
            <TableCell scope="col" key={header} align="left">
              <T id={`admin.${header}`} className="uppercase" />
            </TableCell>
          )
        })}
      </StyledTableRow>
    </TableHead>
  )
}

export default TableHeader

const StyledTextField = styled(TextField)<{ tcolor: string }>(({ tcolor }) => ({
  '& .MuiFilledInput-root': {
    color: 'black',
    background: 'transparent',
    fontSize: '1.6rem',
  },
  '& .MuiFilledInput-underline:before': {
    borderBottom: `2px solid ${tcolor}`,
  },
  '& .MuiFormControl-root': {
    width: 'calc(100% - 210px)',
  },
}))

const AuthorName = styled(Typography)({
  fontSize: '36px',
  fontWeight: '600',
  lineHeight: '80px',
})

const AttributeLabel = styled(Typography)<{ tcolor: string }>(({ tcolor }) => ({
  color: tcolor,
  fontSize: '1.6rem',
}))

const SearchContainer = styled(TableRow)({
  '& .MuiTableCell-head': {
    padding: '1.6rem 0 4rem',
  },
})

const StyledTableCell = styled.div({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  ...getGap(0.8),
})

const StyledTableRow = styled(TableRow)<ColorProps>(({ color }) => ({
  borderBottom: `1px solid ${color}`,
}))

const StyledIconButton = styled(IconButton)({
  padding: 0,
  '&:hover': {
    border: 0,
    backgroundColor: 'transparent',
  },
})

const StyledInputAdornment = styled(InputAdornment)({
  marginTop: '1.6rem',
})

const SorteableTableCell = styled(TableCell)<{ nopointer: string }>(({ nopointer }) => ({
  ...(nopointer === 'true' ? {} : { cursor: 'pointer' }),
}))

const SortContainer = styled.div<ColorProps>(({ color }) => ({
  display: 'flex',
  '& svg': {
    color,
  },
}))

const ButtonT = styled(T)<ResolutionProps>(({ ismobile }) => ({
  padding: ismobile === 'true' ? '0 2.7rem ' : '0.6rem 4.7rem',
}))

const AddButton = styled(Button)<ResolutionProps>(({ ismobile }) => ({
  marginTop: ismobile === 'true' ? '2rem' : '0',
}))

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiChip-root': {
        borderRadius: '5px',
      },
    },
  })
)

const FilterResultsBy = styled(RadioGroup)({
  width: '100%',
  marginTop: '1.1rem',
  display: 'contents',
})

const StyledAvatar = styled(MuiAvatar)<StyledAvatarProps>(({ textColor, background }) => ({
  background,
  color: textColor,
  fontWeight: 600,
  borderRadius: '100px',
  height: '129px',
  width: '129px',
  fontSize: '40px',
  lineHeight: '26px',
  marginRight: '36px',
}))

const BalanceText = styled(Typography)(() => ({
  fontSize: '24px',
}))

const StyledButton = styled(Button)({
  minWidth: '0',
  padding: '0',
})

interface StyledPopoverProps {
  borderColor: string
  backgroundColor: string
  boxShadowColor: string
}
const StyledPopover = styled(Popover)<StyledPopoverProps>(
  ({ borderColor, backgroundColor, boxShadowColor }) => ({
    '.MuiPaper-root': {
      width: '20rem',
      backgroundColor,
      border: `1px solid ${borderColor}`,
      padding: '2rem 1rem',
      boxShadow: `0rem 2rem 2rem ${boxShadowColor}`,
    },
  })
)

interface StyledMenuItemProps {
  hoverBgColor: string
  textColor: string
}

interface StyledAvatarProps {
  background: string
  textColor: string
}

const StyledMenuItem = styled(MenuItem)<StyledMenuItemProps>(({ textColor, hoverBgColor }) => ({
  padding: '0.7rem 0.5rem',
  backgroundColor: 'transparent',
  fontSize: '1.4rem',
  lineHeight: '2rem',
  color: textColor,
  '&:hover': {
    backgroundColor: hoverBgColor,
  },
}))

const ExportButton = styled(Button)({
  width: '165px',
  height: '48px',
  fontSize: '12px',
  marginLeft: '14px',
})
