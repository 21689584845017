import { Box } from '@material-ui/core'
import React from 'react'
import { StyledH1 } from './NewTransactionPrompt'

type NewTransactionConfirmContentProps = {
  partner: string
  product: string
  price?: number
}
export default function NewTransactionConfirmContent({
  partner,
  product,
  price,
}: NewTransactionConfirmContentProps) {
  return (
    <>
      <StyledH1>New Transaction</StyledH1>
      <Box component="div" fontSize="16px" marginTop="20px" lineHeight="20px" color="#00131A">
        <Box component="div" marginBottom="10px" fontWeight="500">
          PARTNER
        </Box>
        <span>{partner}</span>
      </Box>
      <Box component="div" fontSize="16px" marginTop="20px" lineHeight="20px" color="#00131A">
        <Box component="div" marginBottom="10px" fontWeight="500">
          PRODUCT
        </Box>
        <span>{product}</span>
      </Box>
      {!!price && (
        <Box component="div" fontSize="16px" marginTop="20px" lineHeight="20px" color="#00131A">
          <Box component="div" marginBottom="10px" fontWeight="500">
            PRICE
          </Box>
          <span>{price}</span>
        </Box>
      )}
    </>
  )
}
