import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import Drawer from '../common/Drawer'
import styled from 'styled-components'
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Button,
  SwitchClassKey,
  FormControlLabel,
  Radio,
  RadioGroup,
  ListItemIcon,
  Checkbox,
  ListItemText,
  InputAdornment,
  useTheme,
  ListSubheader,
  Divider,
} from '@material-ui/core'
import { MenuProps as MenuPropsType } from '@material-ui/core/Menu'
import SearchIcon from '@material-ui/icons/Search'
import T from '../T'
import { useResolution } from '../../hooks'
import { TYPOGRAPHY_VARIANTS } from '../../../theme/typography'
import { getGap, REFERRAL_SOURCES, ResolutionProps } from '../../utils'
import { Theme, makeStyles } from '@material-ui/core/styles'
import { Asset, createClient, EntryCollection } from 'contentful'
import { ContentMarketplaceProductsType } from '../../hooks/useContentMarketplaceProducts'

interface AdditionalFilterDrawerProps {
  open: boolean
  handleClose: () => void
  titleId: string
  advanceFilter: FilterData | {}
  onSave: (filterData: FilterData) => void
}
export interface FilterData {
  filterType?: string

  //Used for members
  signUpDate?: string
  signUpStartDate?: string
  signUpEndDate?: string
  signUpStartAndEndDate?: string
  balanceNoMin?: number
  balanceNoMax?: number
  balance?: string
  purchaseNoMin?: number
  purchaseNoMax?: number
  purchase?: string
  ageRange?: string
  referralSource?: string[]

  //Used for transaction
  transactionDate?: string
  transactionStartDate?: string
  transactionEndDate?: string
  transactionStartAndEndDate?: string
  partners?: string[]
  coupon?: string
}

export interface ModelType<T> {
  fields: T
  assets: Array<any> | undefined
  id: string
}
export type MerchantModel = {
  name: string
  image: any
  title: string
  description: string
  linkDescription: string
  desktopBannerImage: any
  mobileBannerImage: any
  productLogo: any
  products: ContentMarketplaceProductsType[]
  active: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: '#f50057',
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps: Partial<MenuPropsType> = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'menu',
}

const options = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
]

const signUpDates = ['Today', 'Past 7 days', 'Past 30 days', 'Past year', 'Custom data range']
const ageRanges = ['18-34', '35-49', '50-64', '65+']
const ALL_REFERRAL_SOURCES = 'All referral sources'
const NO_REFERRAL_SOURCES = 'No referral source'
const allreferralSourcesNoReferenceSources = [ALL_REFERRAL_SOURCES, NO_REFERRAL_SOURCES]
const AdditionalFilterDrawer = ({
  open,
  handleClose,
  titleId,
  advanceFilter,
  onSave,
}: AdditionalFilterDrawerProps) => {
  const { isXs } = useResolution()
  const [filterData, setFilterData] = useState<FilterData>({ filterType: 'Members' })
  const [merchants, setMerchants] = useState<string[]>([])
  const isAllSelected =
    merchants && merchants.length > 0 && filterData?.partners?.length === merchants.length
  const classes = useStyles()
  const theme = useTheme()

  useEffect(() => {
    //needs to use this
    setFilterData(advanceFilter)
    getMerchant()
  }, [advanceFilter])

  const client = createClient({
    // This is the space ID. A space is like a project folder in Contentful terms
    space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID || '',
    // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
    accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN || '',
    // This is the host api url, for the lower env's we use the Preview api and for prod we use the Delivery cdn api.
    host: process.env.NEXT_PUBLIC_CONTENTFUL_HOST || '',
  })

  const getMerchant = useCallback(() => {
    try {
      client
        .getEntries({ content_type: 'merchantIndex' })
        .then((response: EntryCollection<any>) => {
          if (response.items.length > 0)
            generateMerchantObject(response.items, response.includes.Asset)
        })
    } catch (err) {
      console.log(`Error fetching merchants from contentful: ${JSON.stringify(err)}`)
    }
  }, [client])
  const generateMerchantObject = (items: Array<object>, images: Array<Asset>) => {
    const merchantList: string[] = []

    items.forEach((element: any) => {
      let newMerchant: ModelType<MerchantModel> = {
        fields: {
          name: '',
          image: '',
          title: '',
          description: '',
          linkDescription: '',
          desktopBannerImage: '',
          mobileBannerImage: '',
          productLogo: '',
          products: [],
          active: false,
        },
        id: '',
        assets: [],
      }
      element.fields.name = (element.fields.name as string).toLowerCase()
      newMerchant.fields = element.fields
      newMerchant.id = element.sys.id
      merchantList.push(newMerchant.fields.title)
    })
    setMerchants(merchantList)
  }

  const handleChange = (field: string, value: any) => {
    if (field === 'filterType') {
      setFilterData({ filterType: value })
    } else {
      setFilterData({
        ...filterData,
        [field]: value,
      })
    }
  }

  const handleSelectSignupDate = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    if (event.target.value === 'all') {
      setFilterData({
        ...filterData,
        signUpDate: event.target.value,
      })
    } else {
      setFilterData({
        ...filterData,
        signUpDate: event.target.value as string,
      })
    }
  }

  const handleSelectAge = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    setFilterData({
      ...filterData,
      ageRange: event.target.value as string,
    })
  }
  const handleSelectReferenceSource = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    let value = event.target.value as string[]
    if (
      value?.includes(ALL_REFERRAL_SOURCES) &&
      value.indexOf(ALL_REFERRAL_SOURCES) === value.length - 1
    ) {
      value = [ALL_REFERRAL_SOURCES]
    } else if (
      value?.includes(NO_REFERRAL_SOURCES) &&
      value.indexOf(NO_REFERRAL_SOURCES) === value.length - 1
    ) {
      value = [NO_REFERRAL_SOURCES]
    } else {
      value = value.filter(obj => obj !== ALL_REFERRAL_SOURCES && obj !== NO_REFERRAL_SOURCES)
    }

    setFilterData({
      ...filterData,
      referralSource: value,
    })
  }

  const handleSelectTransactionDate = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    if (event.target.value === 'all') {
      setFilterData({
        ...filterData,
        transactionDate: event.target.value,
      })
    } else {
      setFilterData({
        ...filterData,
        transactionDate: event.target.value as string,
      })
    }
  }

  const handleSelectPartner = (event: any) => {
    if (event.target.value[event.target.value.length - 1] === 'all') {
      if (merchants) {
        setFilterData({
          ...filterData,
          partners: filterData?.partners?.length === merchants.length ? [] : merchants,
        })
      }
      return
    }
    setFilterData({
      ...filterData,
      partners: event.target.value,
    })
  }

  const handleSave = () => {
    if (filterData.filterType === 'Members') {
      let balance
      let newFilterData = Object.assign({}, filterData)
      if (filterData.balanceNoMin !== undefined && filterData.balanceNoMax !== undefined) {
        balance = '$' + filterData.balanceNoMin + ' to ' + '$' + filterData.balanceNoMax
      } else if (filterData.balanceNoMin !== undefined) {
        balance = '> $' + filterData.balanceNoMin
      } else if (filterData.balanceNoMax !== undefined) {
        balance = '< $' + filterData.balanceNoMax
      }
      if (balance) {
        newFilterData = { ...newFilterData, balance: balance }
      }

      let purchase
      if (filterData.purchaseNoMin !== undefined && filterData.purchaseNoMax !== undefined) {
        purchase = filterData.purchaseNoMin + ' to ' + filterData.purchaseNoMax
      } else if (filterData.purchaseNoMin !== undefined) {
        purchase = '> ' + filterData.purchaseNoMin
      } else if (filterData.purchaseNoMax !== undefined) {
        purchase = '< ' + filterData.purchaseNoMax
      }
      if (purchase) {
        newFilterData = { ...newFilterData, purchase: purchase }
      }

      let signUpStartAndEndDate
      if (filterData.signUpDate === 'Custom data range') {
        if (filterData.signUpStartDate !== undefined && filterData.signUpEndDate !== undefined) {
          signUpStartAndEndDate = filterData.signUpStartDate + ' to ' + filterData.signUpEndDate
        } else if (filterData.signUpStartDate !== undefined) {
          signUpStartAndEndDate = '> ' + filterData.signUpStartDate
        } else if (filterData.signUpEndDate !== undefined) {
          signUpStartAndEndDate = '< ' + filterData.signUpEndDate
        }
      }
      if (signUpStartAndEndDate) {
        newFilterData = { ...newFilterData, signUpStartAndEndDate: signUpStartAndEndDate }
      }
      setFilterData(newFilterData)
      onSave(newFilterData)
    } else if (filterData.filterType === 'Transactions') {
      let newFilterData = Object.assign({}, filterData)
      let transactionStartAndEndDate
      if (filterData.transactionDate === 'Custom data range') {
        if (
          filterData.transactionStartDate !== undefined &&
          filterData.transactionEndDate !== undefined
        ) {
          transactionStartAndEndDate =
            filterData.transactionStartDate + ' to ' + filterData.transactionEndDate
        } else if (filterData.transactionStartDate !== undefined) {
          transactionStartAndEndDate = '> ' + filterData.transactionStartDate
        } else if (filterData.transactionEndDate !== undefined) {
          transactionStartAndEndDate = '< ' + filterData.transactionEndDate
        }
      }
      if (transactionStartAndEndDate) {
        newFilterData = { ...newFilterData, transactionStartAndEndDate: transactionStartAndEndDate }
      }

      setFilterData(newFilterData)
      onSave(newFilterData)
    } else {
      onSave(filterData)
    }
  }

  return (
    <Drawer open={open} handleClose={handleClose} anchor="right">
      <>
        <TitleContainer direction="column" item container ismobile={String(isXs)}>
          <Grid xs={12}>
            <T id={titleId} variant={TYPOGRAPHY_VARIANTS.H2} />
          </Grid>
        </TitleContainer>
        <BodyContainer ismobile={String(isXs)} container xs={12}>
          <Grid xs={12}>
            <T
              id={'admin.additional.filter.results.by'}
              className="text-lg font-semibold leading-6 tracking-tighter"
              variant={TYPOGRAPHY_VARIANTS.H3}
            />
          </Grid>
          <Grid item xs={12}>
            <FilterResultsBy
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="Members"
              value={filterData?.filterType}
              onChange={e => {
                handleChange('filterType', e.target.value)
              }}
            >
              <FormControlLabel
                control={<Radio />}
                label="Members"
                value="Members"
                name="Members"
              />
              <FormControlLabel
                control={<Radio />}
                label="Transactions"
                value="Transactions"
                name="Transactions"
              />
            </FilterResultsBy>
          </Grid>
          {filterData?.filterType === 'Members' ? (
            <>
              <Grid xs={6}>
                <T
                  id={'admin.additional.filter.members.filters'}
                  variant={TYPOGRAPHY_VARIANTS.H5}
                />
              </Grid>
              <Grid xs={6}>
                <T
                  id={'admin.additional.filter.members.filters.sign.up.dates'}
                  variant={TYPOGRAPHY_VARIANTS.H6}
                />
              </Grid>
              <Grid xs={12}>
                <div style={{ display: 'flex' }}>
                  <Grid xs={6}>
                    <Select
                      fullWidth
                      variant="outlined"
                      onChange={handleSelectSignupDate}
                      value={filterData.signUpDate}
                    >
                      {signUpDates.map(signUpDate => (
                        <MenuItem value={signUpDate} key={signUpDate}>
                          {' '}
                          {signUpDate}{' '}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  {filterData.signUpDate === 'Custom data range' && (
                    <>
                      <input
                        type="date"
                        value={filterData.signUpStartDate}
                        style={{ marginLeft: '10px', borderRadius: '4px', borderWidth: 'thin' }}
                        onChange={event => {
                          handleChange('signUpStartDate', event.target.value)
                        }}
                      />
                      <input
                        type="date"
                        value={filterData.signUpEndDate}
                        style={{ marginLeft: '10px', borderRadius: '4px', borderWidth: 'thin' }}
                        onChange={event => {
                          handleChange('signUpEndDate', event.target.value)
                        }}
                      />
                    </>
                  )}
                </div>
              </Grid>
              <Grid xs={6}>
                <T
                  id={'admin.additional.filter.members.filters.balance'}
                  variant={TYPOGRAPHY_VARIANTS.H6}
                />
              </Grid>
              <div style={{ display: 'flex' }}>
                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <CustomInputAdornment position="start" disableTypography={true}>
                          $
                        </CustomInputAdornment>
                      ),
                    }}
                    label={<T id="admin.additional.filter.members.filters.no.min" />}
                    fullWidth
                    onChange={({ target: { value } }) => {
                      handleChange('balanceNoMin', value)
                    }}
                    value={filterData.balanceNoMin}
                  />
                </Grid>
                <InputLabel style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                  {' '}
                  <T id="to" />{' '}
                </InputLabel>
                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <CustomInputAdornment position="start" disableTypography={true}>
                          $
                        </CustomInputAdornment>
                      ),
                    }}
                    label={<T id="admin.additional.filter.members.filters.no.max" />}
                    fullWidth
                    onChange={({ target: { value } }) => {
                      handleChange('balanceNoMax', value)
                    }}
                    value={filterData.balanceNoMax}
                  />
                </Grid>
              </div>
              <Grid xs={6}>
                <T
                  id={'admin.additional.filter.members.filters.total.purchases'}
                  variant={TYPOGRAPHY_VARIANTS.H6}
                />
              </Grid>
              <Grid xs={12}>
                <div style={{ display: 'flex' }}>
                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      label={<T id="admin.additional.filter.members.filters.no.min" />}
                      fullWidth
                      onChange={({ target: { value } }) => {
                        handleChange('purchaseNoMin', value)
                      }}
                      value={filterData.purchaseNoMin}
                    />
                  </Grid>
                  <InputLabel style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    {' '}
                    <T id="to" />{' '}
                  </InputLabel>
                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      label={<T id="admin.additional.filter.members.filters.no.max" />}
                      fullWidth
                      onChange={({ target: { value } }) => {
                        handleChange('purchaseNoMax', value)
                      }}
                      value={filterData.purchaseNoMax}
                    />
                  </Grid>
                </div>
              </Grid>
              <Grid xs={6}>
                <T
                  id={'admin.additional.filter.members.filters.age.range'}
                  variant={TYPOGRAPHY_VARIANTS.H6}
                />
              </Grid>
              <Grid xs={12}>
                <div style={{ display: 'flex' }}>
                  <Grid xs={6}>
                    <Select
                      fullWidth
                      variant="outlined"
                      onChange={handleSelectAge}
                      value={filterData.ageRange}
                    >
                      {ageRanges.map(ageRange => (
                        <MenuItem value={ageRange} key={ageRange}>
                          {ageRange}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </div>
              </Grid>
              <Grid xs={6}>
                <T
                  id={'admin.additional.filter.members.filters.referral.source'}
                  variant={TYPOGRAPHY_VARIANTS.H6}
                />
              </Grid>
              <Grid xs={12}>
                <div style={{ display: 'flex' }}>
                  <Grid xs={6}>
                    <Select
                      fullWidth
                      variant="outlined"
                      onChange={handleSelectReferenceSource}
                      value={filterData.referralSource || []}
                      multiple
                      renderValue={(selected: any) => selected.join(', ')}
                    >
                      {allreferralSourcesNoReferenceSources.map(referralSource => (
                        <MenuItem value={referralSource} key={referralSource}>
                          <Checkbox
                            checked={
                              filterData.referralSource
                                ? filterData.referralSource.indexOf(referralSource || '') > -1
                                : false
                            }
                          />
                          {referralSource}
                        </MenuItem>
                      ))}
                      {
                        <ListSubheader>
                          <Divider />
                        </ListSubheader>
                      }
                      {REFERRAL_SOURCES.map(referralSource => (
                        <MenuItem value={referralSource} key={referralSource}>
                          <Checkbox
                            checked={
                              filterData.referralSource
                                ? filterData.referralSource.indexOf(referralSource || '') > -1
                                : false
                            }
                          />
                          {referralSource}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </div>
              </Grid>
            </>
          ) : (
            <>
              <Grid xs={6}>
                <T
                  id={'admin.additional.filter.transaction.filters'}
                  variant={TYPOGRAPHY_VARIANTS.H5}
                />
              </Grid>
              <Grid xs={6}>
                <T
                  id={'admin.additional.filter.transaction.filters.transaction.dates'}
                  variant={TYPOGRAPHY_VARIANTS.H6}
                />
              </Grid>
              <Grid xs={12}>
                <div style={{ display: 'flex' }}>
                  <Grid xs={6}>
                    <Select
                      fullWidth
                      variant="outlined"
                      onChange={handleSelectTransactionDate}
                      value={filterData.transactionDate}
                    >
                      {signUpDates.map(signUpDate => (
                        <MenuItem value={signUpDate} key={signUpDate}>
                          {' '}
                          {signUpDate}{' '}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  {filterData.transactionDate === 'Custom data range' && (
                    <>
                      <input
                        type="date"
                        value={filterData.transactionStartDate}
                        style={{ marginLeft: '10px', borderRadius: '4px', borderWidth: 'thin' }}
                        onChange={event => {
                          handleChange('transactionStartDate', event.target.value)
                        }}
                      />
                      <input
                        type="date"
                        value={filterData.transactionEndDate}
                        style={{ marginLeft: '10px', borderRadius: '4px', borderWidth: 'thin' }}
                        onChange={event => {
                          handleChange('transactionEndDate', event.target.value)
                        }}
                      />
                    </>
                  )}
                </div>
              </Grid>

              <Grid xs={6}>
                <T
                  id={'admin.additional.filter.transaction.filters.partners'}
                  variant={TYPOGRAPHY_VARIANTS.H6}
                />
              </Grid>
              <Grid xs={12}>
                <Select
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    startAdornment: (
                      <CustomInputAdornment position="start" disableTypography={true}>
                        <SearchIcon />
                      </CustomInputAdornment>
                    ),
                  }}
                  labelId="mutiple-select-label"
                  multiple
                  value={filterData?.partners || []}
                  onChange={handleSelectPartner}
                  renderValue={(value: any) => value.join(', ')}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                    value="all"
                    classes={{
                      root: isAllSelected ? classes.selectedAll : '',
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: classes.indeterminateColor }}
                        checked={isAllSelected}
                        indeterminate={
                          (merchants &&
                            filterData?.partners?.length &&
                            filterData?.partners?.length > 0 &&
                            filterData?.partners?.length < merchants.length) ||
                          false
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {merchants &&
                    merchants.map(option => (
                      <MenuItem key={option} value={option}>
                        <ListItemIcon>
                          <Checkbox checked={filterData?.partners?.includes(option) || false} />
                        </ListItemIcon>
                        <ListItemText primary={option} />
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
              <Grid xs={6}>
                <T
                  id={'admin.additional.filter.transaction.filters.coupons'}
                  variant={TYPOGRAPHY_VARIANTS.H6}
                />
              </Grid>
              <Grid xs={12}>
                <TextField
                  variant="outlined"
                  label={<T id="admin.additional.filter.transaction.filters.coupon.name" />}
                  fullWidth
                  onChange={({ target: { value } }) => {
                    handleChange('coupon', value)
                  }}
                  value={filterData.coupon}
                />
              </Grid>
            </>
          )}
          <Button color="primary" variant="outlined" size="small" onClick={handleClose}>
            <ButtonT
              id="admin.additional.filter.cancel"
              ismobile={String(isXs)}
              variant={TYPOGRAPHY_VARIANTS.XS_TEXT_2}
            />
          </Button>
          <FillButton
            color="primary"
            variant="contained"
            tColor={theme.palette.text.white}
            onClick={handleSave}
          >
            <ButtonT
              id="admin.additional.filter.filter"
              ismobile={String(isXs)}
              variant={TYPOGRAPHY_VARIANTS.XS_TEXT_2}
            />
          </FillButton>
        </BodyContainer>
      </>
    </Drawer>
  )
}

export default AdditionalFilterDrawer

type TProp = {
  tColor: string
}
const FillButton = styled(Button)<TProp>(({ tColor }) => ({
  height: '4rem',
  '& span svg > * ': {
    fill: tColor,
  },
}))

const TitleContainer = styled(Grid)<ResolutionProps>(({ ismobile }) => ({
  flexDirection: ismobile === 'true' ? 'column' : 'row',
  alignItems: ismobile === 'true' ? 'flex-start' : 'center',
  ...getGap(ismobile === 'true' ? 1.5 : 2),
}))

const BodyContainer = styled(Grid)<ResolutionProps>(({ ismobile }) => ({
  flexDirection: ismobile === 'true' ? 'column' : 'row',
  alignItems: ismobile === 'true' ? 'flex-start' : 'center',
  paddingTop: '2rem',
  ...getGap(ismobile === 'true' ? 1.5 : 2),
}))

const ButtonT = styled(T)<ResolutionProps>(({ ismobile }) => ({
  padding: ismobile === 'true' ? '0 2.7rem ' : '0.6rem 4.7rem',
}))

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string
}

const FilterResultsBy = styled(RadioGroup)({
  width: '100%',
  marginTop: '1.1rem',
  display: 'contents',
})

const CustomInputAdornment = styled(InputAdornment)({
  marginRight: 0,
  marginTop: '0.2rem',
})
