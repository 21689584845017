import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { Dispatchable } from '../utils/helper'
import { createClient, EntryCollection } from 'contentful'

interface IContentGiverProviderProps {
  children: ReactNode
}

type ContentGiverFields = {
  mainDescription: string
}

interface ContentGiverType {
  fields: ContentGiverFields
  assets?: Array<any>
  id?: string
}

type UseContentGiverType = {
  giverContent: ContentGiverType[] | undefined
  setGiverContent: Dispatchable<ContentGiverType[] | undefined>
  getGiverContent: any
  getMainDescription: () => string | undefined
}

const ContentGiverContext = createContext<UseContentGiverType | undefined>(undefined)

export const ContentGiverProvider = ({ children }: IContentGiverProviderProps) => {
  const cntfSpace = process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID || ''
  const cntfToken = process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN || ''
  const cntfHost = process.env.NEXT_PUBLIC_CONTENTFUL_HOST || ''

  const [giverContent, setGiverContent] = useState<ContentGiverType[]>()
  const client = createClient({
    // This is the space ID. A space is like a project folder in Contentful terms
    space: cntfSpace,
    // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
    accessToken: cntfToken,
    // This is the host api url, for the lower env's we use the Preview api and for prod we use the Delivery cdn api.
    host: cntfHost,
  })

  const getGiverContent = useCallback(() => {
    client.getEntries({ content_type: 'giver' }).then((response: EntryCollection<any>) => {
      generateContentGiverObject(response.items)
    })
  }, [client])

  const generateContentGiverObject = (items: Array<object>) => {
    const contentList: ContentGiverType[] = []

    items.forEach((element: any) => {
      let newGiverContent: ContentGiverType = {
        fields: { mainDescription: '' },
      }
      newGiverContent.fields = element.fields

      contentList.push(newGiverContent)
    })

    setGiverContent(contentList)
  }

  useEffect(() => {
    if (!giverContent) getGiverContent()
  }, [giverContent, getGiverContent])

  const getMainDescription = () => {
    if (giverContent) {
      const element = giverContent.find(item => item.fields.mainDescription !== undefined)
      return element?.fields.mainDescription
    }
  }

  const value = {
    giverContent,
    setGiverContent,
    getGiverContent,
    getMainDescription,
  }

  return <ContentGiverContext.Provider value={value}>{children}</ContentGiverContext.Provider>
}

export const useContentGiver = () => {
  const ctx = useContext(ContentGiverContext)
  if (!ctx) {
    throw new Error('ContentGiver out of context')
  }
  return ctx
}
