import { AppBar, Toolbar } from '@material-ui/core'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { getNavLinks } from '~/cms/contentful-client'
import { useUser } from '~/hooks'
import { ROUTES } from '~/utils'
import LinkLogo from '../LinkLogo/LinkLogo'
import { DesktopLinksPublic } from './DesktopLinksPublic'
import { MobileMenuPublic } from './MobileMenuPublic'

export type HeaderLink = {
  fields: {
    href: string
    label: string
    name: string
  }
}

const Header = () => {
  const router = useRouter()
  const { id } = useUser()
  const { data: session } = useSession()
  const [navLinks, setNavLinks] = useState<HeaderLink[] | null>(null)
  const isAuthenticated = !!session && !!id
  const homeRoute = isAuthenticated ? ROUTES.MEMBER : ROUTES.HOME

  useEffect(() => {
    const fetchNavLinks = async () => {
      if (!isAuthenticated) {
        setNavLinks([])
        return
      }

      try {
        const entry = await getNavLinks()
        setNavLinks(entry?.fields?.navLinks.slice(0, -1))
      } catch (error) {
        setNavLinks([])
      }
    }

    fetchNavLinks()
  }, [isAuthenticated])

  if (!navLinks) {
    return null
  }

  return (
    <AppBar color="transparent" position="sticky" elevation={0}>
      <Toolbar className="justify-between bg-white px-5 sm:px-16">
        <LinkLogo href={homeRoute} />
      </Toolbar>
    </AppBar>
  )
}

const LoginButtons = () => (
  <>
    <MobileMenuPublic className="md:hidden" />
    <DesktopLinksPublic className="hidden md:inline-block" />
  </>
)

export default Header
